import { render, staticRenderFns } from "./DialogCheckTerms.vue?vue&type=template&id=3868b019&scoped=true"
import script from "./DialogCheckTerms.vue?vue&type=script&lang=js"
export * from "./DialogCheckTerms.vue?vue&type=script&lang=js"
import style0 from "./DialogCheckTerms.vue?vue&type=style&index=0&id=3868b019&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3868b019",
  null
  
)

export default component.exports