<template>
  <div class="qj-nps">
    <v-dialog v-model="show" scrollable width="30em" persistent>
      <v-card v-show="!sent" height="100%">
        <v-card-title>
          <v-col cols="12 pt-0" style="position: relative">
            <v-btn icon class="qj-btn-close-dialog" @click="close()">
              <v-icon>far fa-times-circle</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" class="pt-0 pb-0 text-center">
            <div class="qj-p ma-auto">
              <img src="@/assets/img/svg/nps.svg" alt width="90" />
            </div>
          </v-col>
        </v-card-title>
        <NPSRate :text="npsText?.question" />
        <NPSConmment />
      </v-card>
      <NPSSent v-show="sent" @click="close()" />
    </v-dialog>
  </div>
</template>

<script>
import NPSRate from "./screens/NPSRate";
import NPSConmment from "./screens/NPSComment";
import NPSSent from "./screens/NPSSent";
import { createTagManagerEvent } from '@/utils'
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["show"],
  components: {
    NPSRate,
    NPSConmment,
    NPSSent,
  },
  computed: {
    ...mapGetters('nps', {
      npsText: 'npsText',
      value: 'npsValue',
      sent: 'npsHasSent',
    }),
    isConmment() {
      if (this.value || this.value == 0) return true;
      return false;
    },
  },
  methods: {
    ...mapActions("nps", ["setNpsValue"]),
    close() {
      this.value ?
        createTagManagerEvent({
          event: 'npsClosedWithNote'
        }) :
        createTagManagerEvent({
          event: 'npsClosed'
        })
      this.$emit("close");
      this.setNpsValue(null);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__text {
  padding: 0px 14px 24px 14px !important;
}
</style>
