<template>
  <div v-if="deal" class="active-agreement">
    <v-col class="pa-0">
      <!-- Title -->
      <v-row class="py-0 flex-column">
        <p
          class="welcome-text"
          v-if="deal.active_proposal && getDealPaymentStatus()"
        >
          <span class="strong-text">
            {{ dealPaymentStatus[getDealPaymentStatus()]?.title }}
          </span>
          <br />
          <span>
            {{ dealPaymentStatus[getDealPaymentStatus()]?.subtitle }}
          </span>
        </p>
        <p class="welcome-text" v-else>
          <span class="strong-text"
            >Não foi possível verificar o status do seu acordo no momento.
          </span>
          <span>Tente novamente mais tarde</span>
        </p>
      </v-row>
      <v-row class="status-agreement">
        <v-col class="pa-0 acordo" :class="{'justify-center': !deal.external_ref}">
          <div v-if="getDealPaymentStatus()" class="mr-2">
            <v-chip
              :ripple="false"
              :color="dealPaymentStatus[getDealPaymentStatus()].color"
              class="status-chip"
            >
              <v-icon
                :class="dealPaymentStatus[getDealPaymentStatus()].textColor"
                style="margin-right: 5px"
                small
                >{{ dealPaymentStatus[getDealPaymentStatus()].icon }}</v-icon
              >
              <span
                :class="dealPaymentStatus[getDealPaymentStatus()].textColor"
                class="deal-status"
                >{{ proposal.agreement_type == 'REGULARIZE' ? 'Negociação' : 'Acordo' }}
                <b v-html="dealPaymentStatus[getDealPaymentStatus()].text"
                  :class="dealPaymentStatus[getDealPaymentStatus()].textColor"
                  ></b
                ></span
              >
            </v-chip>
          </div>
          <div class="text-right grow" v-if="proposal.agreement_type != 'REGULARIZE'&& deal.external_ref">
            <p class="qj-p mb-0 external-ref">
              <b>{{ deal.external_ref }}</b>
            </p>
            <span class="acordo__label">Número do acordo</span>
          </div>
        </v-col>
        <v-row v-if="showIncentive()">
          <v-col
          class="proposal-row pa-0 d-flex justify-center text-center mb-2"
          cols="12"
          >
            <div style="width: 320px" class="text-center">
              <span><b>{{ deal.active_proposal.incentive }} no
                  {{
                    deal.active_proposal.payment_method | proposalPaymentMethod
                  }}
                </b>
              </span>
              <span v-if="proposal.agreement_type == 'REGULARIZE'" class="qj-proposal-alias">
                {{ proposal.alias }}
              </span>
              <div v-if="deal.active_proposal.payment_type == 'IN_CASH'" >
                <span>
                  <span>à vista no valor de </span>
                  <b>{{ proposal.down_payment | money }}
                  </b>
                </span>
              </div>
              <div v-else-if="proposal.down_payment > 0">
                <span>
                  <span>Entrada de</span>
                  <b>
                    {{ proposal.down_payment | money }}
                  </b>
                  <p class="ma-0 qj-p" v-if="isPaymentMethodVisible()">no boleto</p>
                </span>
                <div>
                  + <b>{{ proposal.installments_count_1 }}</b>
                  x de
                  <b>{{ proposal.installment_1 | money }}</b>
                </div>
              </div>
              <div v-else>
                <span v-if="deal.active_proposal.payment_type == 'INSTALLMENT'">
                  em <b>{{ deal.active_proposal.installments_count_1 }}x</b>
                </span>
                <span>
                  de
                  <b>{{
                    proposal.down_payment || proposal.installment_1 | money
                  }}</b>
                </span>
              </div>
            </div>
          </v-col>
        </v-row>

        <div class="d-flex flex-row align-center">
          <v-col
            class="action-button"
          >
            <div>
              <a
                href="javascript:void(0);"
                @click="openNegotiationDetails()"
                class="qj-link qj-color-secondary"
                >Ver detalhes</a
              >
            </div>
          </v-col>
          <v-col
            class="action-button"
            v-if="deal.contract_id != null"
          >
            <v-btn class="qj-btn contract-button" @click="downloadContract()"
              >Baixar contrato</v-btn
            >
          </v-col>
        </div>
      </v-row>
      <v-row v-if="ticketExpirationAlert(deal)">
        <div
          class="expirationAlert"
          v-html="ticketExpirationAlertContent(deal)"
        ></div>
      </v-row>
      <v-row v-if="detailsTextByAgreementType()">
        <v-col>
          <div>{{ detailsTextByAgreementType() }}</div>
        </v-col>
      </v-row>
      <qj-alert-info
        v-else
        name="proposal-agreed-after-payments"
        :creditorId="deal.creditor_id"
        :deal="deal"
      />
      <v-row
        class="text-center"
        v-if="
          showPayments()
        "
      >
        <CardAgreementExpansion
          :deal="deal"
          :maxDebit="maxDebit"
          :isPaymentMethodVisible="isPaymentMethodVisible"
          :isOperationsValueVisible="isOperationsValueVisible"
          @downloadBoleto="downloadBoleto"
          @openBillAlert="openBillAlert"
        />
      </v-row>

      <v-row
        class="qj-alert-info mx-auto"
        justify="center"
        v-if="
          !(
            deal.creditor.config_prefix == 'itau.' &&
            deal.active_proposal.payment_type == 'IN_CASH'
          )
        "
      >
      </v-row>
    </v-col>
    <!-- Footer -->
    <v-spacer></v-spacer>
    <qj-footer
      :type="hasOtherDebts.length > 1 ? 'other' : 'help'"
      @send="home()"
      :hasCustomerServiceBtn="true"
    />
    <!-- Dialog help -->
    <DialogNegotiationDetails
      :deal="deal"
      :dialog="dialogNegotiationDetails"
      @close="dialogNegotiationDetails = false"
    />
    <!-- Dialog -->
    <DialogDownloadContract
      :id="deal.id"
      :dialog="dialogDownloadContract"
      @close="dialogDownloadContract = false"
    />

    <!-- <DialogPayments
      :dialog="dialogPayments"
      :type="deal.active_proposal.payment_method"
      :payments="deal.payments.filter((i) => i.status != 'DISCARTED')"
      @close="dialogPayments = false"
    /> -->
    <!-- Dialog meus dados -->
    <DialogMyData
      :dialog="dialogMyData"
      :deal="deal"
      @close="dialogMyData = false"
    />
    <DialogBillAlert
      ref="DialogBillAlert"
      :dialog="dialogBillAlert"
      @downloadBoleto="downloadBoleto"
      @close="dialogBillAlert = false"
    />

    <DialogNPS :show="newDeal" @close="newDeal = false" />
    <!-- -->
    <QjAlert
      :alert="alert"
      :id="alertId"
      :index="alertIndex"
      :creditor="alertCreditor"
      @close="alert = false"
    />
  </div>
</template>

<script>
import * as Sentry from "@sentry/browser";
import { LoadDealError } from "@/errors";

import store from "@/store";
import Deal from "@/modules/deal";
import util from "@/assets/js/util";
import serviceDeal from "@/services/deal";
import { V2 as serviceCustomerV2 } from "@/services/customer";
import serviceEvent from "@/services/event";
import servicePayment from "@/services/payment";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { createTagManagerEvent } from "@/utils";
import Proposal from "@/modules/proposal";

import CardAgreementExpansion from "../components/CardAgreementExpansion.vue";
import ProposalCard from "@/views/negotiation/components/proposalCard.vue";
import DialogMyData from "../components/DialogMyData";
import DialogBillAlert from "../components/DialogBillAlert";
import DialogDownloadContract from "../components/DialogDownloadContract";
import DialogNegotiationDetails from "../components/DialogNegotiationDetails";
import DialogNPS from "@/components/nps/DialogNPS";
import QjAlert from "../components/QjAlert.vue";

export default {
  components: {
    CardAgreementExpansion,
    DialogMyData,
    DialogBillAlert,
    DialogDownloadContract,
    DialogNegotiationDetails,
    DialogNPS,
    QjAlert,
    // ProposalCard
  },
  data: () => ({
    alert: false,
    dialog: false,
    newDeal: false,
    proposal: {},
    deal: null,
    activePayment: false,
    dialogMyData: false,
    dialogBillAlert: false,
    dialogPayments: false,
    dialogDownloadContract: false,
    dialogNegotiationDetails: false,
    dontShowPaymentMethodTags: false,
    alertId: 0,
    alertIndex: 0,
    alertCreditor: "",
    hasOtherDebts: [],
    dealPaymentStatus: {
      APPROVED: {
        text: "<strong>aprovado</strong>",
        color: "#0A79EB",
        textColor: "white--text",
        icon: "mdi-check-circle-outline",
        title: "Seu acordo foi aprovado com sucesso, parabéns!",
        subtitle:
          "Não esqueça de realizar o pagamento dentro da data de vencimento.",
      },
      APPROVED_REGULARIZE: {
        text: "aprovada",
        color: "#0A79EB",
        textColor: "white--text",
        icon: "mdi-check-circle-outline",
        title: "Sua negociação foi aprovada com sucesso, parabéns!",
        subtitle:
          "Não esqueça de realizar o pagamento dentro da data de vencimento.",
      },
      OK: {
        text: "<strong>em dia</strong>",
        color: "#0A79EB",
        textColor: "white--text",
        icon: "mdi-check-circle-outline",
        title: "Seu acordo está com os pagamentos em dia, parabéns!",
        subtitle: "Continue assim para quitar sua dívida.",
      },
      OK_REGULARIZE: {
        text: "em dia",
        color: "#0A79EB",
        textColor: "white--text",
        icon: "mdi-check-circle-outline",
        title: "Sua negociação está com os pagamentos em dia, parabéns!",
        subtitle: "Continue assim para quitar sua dívida.",
      },
      DUE_TODAY: {
        text: "<strong>vence hoje</strong>",
        color: "#FF8400",
        textColor: "white--text",
        icon: "mdi-alert-circle-outline",
        title: "Seu boleto vence hoje,",
        subtitle:
          "pague até o final do dia para não correr o risco de perder seu acordo.",
      },
      IN_DEBIT: {
        text: "<strong>em atraso</strong>",
        color: "#C10903",
        textColor: "white--text",
        icon: "mdi-clock-time-three-outline",
        title: "Seu boleto está atrasado,",
        subtitle: "efetue o pagamento para não perder o acordo realizado.",
      },
      WAITING_BOLETO_PAYMENT: {
        text: "<strong>Em análise</strong>",
        color: "#E5E5E5",
        textColor: "black--text",
        icon: "mdi-clock-time-three-outline",
        title: "Seu pagamento está sendo processado no cartão de crédito.",
        subtitle:
          "Em até 5 dias úteis seu nome será retirado dos órgãos de proteção ao crédito.",
      },
      BOLETO_PAYED: {
        text: "<strong>Em análise</strong>",
        color: "#E5E5E5",
        textColor: "black--text",
        icon: "mdi-clock-time-three-outline",
        title: "Seu pagamento está sendo processado no cartão de crédito.",
        subtitle:
          "Em até 5 dias úteis seu nome será retirado dos órgãos de proteção ao crédito.",
      },
      BOLETO_PAYMENT_ERROR: {
        text: "com falha no pagamento",
        color: "orange darken-1",
        textColor: "black--text",
        icon: "mdi-alert-circle-outline",
        title: "Seu pagamento no cartão de crédito não foi aprovado.",
        subtitle:
          "Mas calma, você ainda pode quitar sua dívida pagando o boleto a baixo.",
      },
    },
  }),
  computed: {
    ...mapGetters("alert", ["getAlert"]),
    ...mapGetters("negotiation", {
      getDealByProposalId: "getDealByProposalId",
      getConfigurationByName: "getConfigurationByName"
    }),
    /** Indica se serão exibidas as informações de C.E.T e I.O.F. */
    isTaxesVisibles() {
      if (!this.deal || !this.deal?.active_proposal) {
        return false;
      }

      return (
        this.deal.active_proposal?.iof || this.deal.active_proposal?.cet_month
      );
    },
  },
  beforeCreate() {
    this.$emit("loading", true);
  },
  async created() {
    const proposalId = util.base64Decode(this.$route.query.id);
    let configurations = store.getters["negotiation/getConfiguration"].objects;
    this.partialIof = Deal.getPartialIof(this.deal, configurations);
    if (!this.deal) {
      this.deal = this.getDealByProposalId(proposalId);
    }
    if (!this.$route.query.id) {
      this.$emit("loading", false);
      this.$router.push({ name: "Home" }).catch((failure) => {
        this.skipIfNavigationFailureIsGeneric(failure);
      });
      return;
    }

    let deal = store.getters["negotiation/getDeals"].find((deal) => {

      return (
        deal.active_proposal_id == proposalId ||
        deal.active_proposal?.parcele_ja_proposal_id == proposalId
      );
    });

    if (!deal) {
      this.$emit("loading", false);
      this.$router.push({ name: "Home" }).catch((failure) => {
        this.skipIfNavigationFailureIsGeneric(failure);
      });
      return;
    }

    try {
      const { data } = await serviceCustomerV2.agreement(deal.id);
      this.deal = {
        ...data.deals?.[0],
        prePayment: this.$route.params?.prePayment || deal.prePayment,
      };
      this.updateDeal(this.deal)
    } catch (error) {
      console.error("Não foi possível buscar a negociação.", error);

      this.$emit("loading", false);

      let eventId = Sentry.captureException(
        new LoadDealError({
          axiosError: error,
        })
      );

      this.$router
        .push({ name: "Error", query: { id: eventId } })
        .catch((failure) => {
          this.skipIfNavigationFailureIsGeneric(failure);
        });

      return;
    }

    if (
      deal.payments?.length <= 0 ||
      !deal.payments?.filter(
        (i) => i.status == "PENDING" || i.status == "REMINDER"
      ).length
    ) {
      this.$emit("loading", false);
    } else {
      let otherDebts = store.getters["negotiation/getDeals"];

      if (util.isEmpty(otherDebts)) {
        otherDebts = [];
      } else {
        otherDebts = otherDebts.filter((deal) => {
          if (deal.active_proposal) {
            return false;
          } else if (deal.status == "CREATED" || deal.status == "STARTED") {
            return true;
          }
          return false;
        });
      }

      if (otherDebts.length == 1) {
        this.alertId = otherDebts?.[0]?.id;
        this.alertCreditor =
          (otherDebts?.[0]?.creditor.profile.gender == "MALE" ? "o " : "a ") +
          otherDebts?.[0]?.creditor.name;
        setTimeout(() => (this.alert = true), 2000);
      } else if (otherDebts.length > 1) {
        this.alertIndex = 1;
        setTimeout(() => (this.alert = true), 2000);
      }
    }

    let othetDebits = store.getters["negotiation/getDeals"];
    if (othetDebits?.length > 0) {
      this.hasOtherDebts = othetDebits.filter((i) => i.status != "ABORTED");
    }

    if (this.$route.query.new != null) {
      this.getNpsText().then(() => {
        this.newDeal = this.$route.query.new == true ? true : false;
        let query = Object.assign({}, this.$route.query);
        delete query.new;
        this.$router.replace({ query }).catch((failure) => {
          this.skipIfNavigationFailureIsGeneric(failure);
        });
      });
    }

    let proposal = deal?.active_proposal;

    this.proposal = proposal || deal.active_proposal;
    this.activePayment = this.deal.payments?.find(
      (payment) => payment.status == "PENDING"
    );

    this.dontShowPaymentMethodTags = util.getConfigValue(
      deal.creditor.config_prefix + "dont_show_payment_method_tag"
    );
    let configPrefix = util.getPrefix(this.deal.id);
    this.tolerances = {
      DOWN_PAYMENT:
        util.getConfig()[configPrefix + "down_payment_tolerance_days"] ?? null,
      INSTALLMENT:
        util.getConfig()[configPrefix + "installment_tolerance_days"] ?? null,
    };
    setTimeout(() => this.$emit("loading", false), 300);
  },
  methods: {
    ...mapActions("nps", ["getNpsText"]),
    ...mapMutations("negotiation", ['updateDeal']),
    ticketExpirationAlert(deal) {
      let alert = this.getAlert("have-expiration-policy", deal.creditor_id);
      let notPaymentSlip = deal.active_proposal?.payment_method != "BOLETO";
      let hasntBarCode =
        deal.payments?.find((payment) => payment.status == "PENDING")
          ?.barcode ?? false;

      if (!alert || notPaymentSlip || hasntBarCode) return false;
      return alert;
    },
    ticketExpirationAlertContent(deal) {
      return this.ticketExpirationAlert(deal)?.content;
    },
    showIncentive() {
      let atualization = this.deal?.tags_v2?.some(tag => (tag.key == 'atualizacao'));
      if (!atualization) atualization = this.deal?.tags?.split(",").includes("atualizacao");

      const isParcial = this.deal?.active_proposal?.agreement_type != 'PAY_OFF'
      let isAble = (isParcial || atualization) && this.proposal.incentive;
      return isAble;
    },
    showPayments() {
      const parceleJaWithPaymentError = this.deal.active_proposal_id && this.deal?.prePayment?.quiteja_status == 'BOLETO_PAYMENT_ERROR'
      const normalProposal = this.deal?.active_proposal && !this.deal?.active_proposal?.parcele_ja_proposal_id
      return normalProposal || parceleJaWithPaymentError
    },
    home() {
      this.$router
        .push({
          name: "Deals",
        })
        .catch((failure) => {
          this.skipIfNavigationFailureIsGeneric(failure);
        });
    },
    openNegotiationDetails() {
      this.dialogNegotiationDetails = true;
      createTagManagerEvent({
        event: "dealClickedDetails",
      });
    },
    downloadContract() {
      this.dialogDownloadContract = true;
      createTagManagerEvent({
        event: "dealClickedContract",
      });
    },
    toPercentage(value) {
      return (value * 100).toFixed(2);
    },
    pendingPayments(deal) {
      return deal.payments.filter((i) => i.status == "PENDING");
    },
    getExpireDay(date) {
      let dueDate = new Date(date);
      let difference = dueDate.getTime() - new Date().getTime();
      return Math.ceil(difference / (1000 * 3600 * 24));
    },
    paymentLabel() {
      const prefix =
        this.deal.active_proposal.payment_type == "IN_CASH" ? "" : "em";
      return prefix;
    },
    getDealPaymentStatus() {
      if (this.deal?.prePayment?.quiteja_status) {
        return this.deal?.prePayment?.quiteja_status;
      }
      if (!this.activePayment) return false;

      const firstPayment = this.deal.payments?.[0];
      let { due_date } = this.activePayment;
      let days = this.getExpireDay(due_date);

      if (firstPayment?.status === "PENDING") {
        return this.proposal.agreement_type == 'REGULARIZE' ? "APPROVED_REGULARIZE" : "APPROVED";
      } else if (this.activePayment && days > 0) {
        return this.proposal.agreement_type == 'REGULARIZE' ? "OK_REGULARIZE" : "OK";
      } else if (this.activePayment && days == 0) {
        return `DUE_TODAY`;
      } else if (this.activePayment && days < 0) {
        return `IN_DEBIT`;
      }
      return false;
    },
    isOperationsValueVisible(proposal) {
      return Proposal.isOperationsValueVisible(proposal);
    },
    isPaymentMethodVisible(proposal) {
      return Proposal.isPaymentMethodVisible(proposal);
    },
    maxDebit(operations) {
      let max = 0;
      let array = operations.filter((item) => {
        if (!max) max = item.debit_value;
        return max <= item.debit_value;
      });
      return array?.[0] || 0;
    },
    returnProposalBoxClass(proposal) {
      if (!proposal || !proposal.incentive) return "";
      if (proposal?.incentive == "Máximo parcelamento") {
        return "incentive-color-purple";
      }
      if (proposal?.incentive == "Parcelamento máximo") {
        return "incentive-color-purple";
      }
      if (proposal?.incentive == "Oferta sem juros") {
        return "incentive-color-gray";
      } else {
        return null;
      }
    },
    generateProposalBoxStyle(proposal) {
      if (this.returnProposalBoxClass(proposal)) return null;
      return {
        borderColor: util.stringToColor(proposal?.incentive ?? " "),
        border: "1px solid",
      };
    },
    downloadBoleto(paymentId) {
      createTagManagerEvent({
        event: "dealDownloadedBoleto",
      });
      serviceEvent
      .create({
        deal_id: this.deal?.id,
        detail: "Pagamento: " + paymentId,
        message: "Boleto baixado",
        type: "BOLETO_DOWNLOADED",
      })
      .catch((error) => {
        console.error("Não foi possível criar o evento.", error);
      });
      servicePayment
      .sendBoleto(paymentId)
      .catch((error) => console.error(error));
    },
    openBillAlert(payment) {
      this.$refs.DialogBillAlert?.openDialog(payment);
    },
    detailsTextByAgreementType(){
      const config = 'proposal-agreed-before-payments-text'
      const agreementType = this.deal.agreement_type.toLowerCase()
      const configName = `${this.deal.creditor.config_prefix}${agreementType}.${config}`
      return this.getConfigurationByName(configName)
    }
  },
};
</script>

<style scoped lang="scss">
.row {
  margin: 0 !important;
}

.qj-h2 {
  font-size: 18px;
}

.status-agreement {
  background-color: #ffffff;
  margin: 15px 0px 9px !important;
  padding: 11px 13.5px 7.5px;
  flex: unset;
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  border: 1px solid #f0f0f0;
}

.qj-alert-info {
  padding: 12px;
  margin: auto !important;
}

// C.E.T. e I.O.F.
.qj-taxes {
  display: flex;
  flex-direction: row;
  margin: 32px 8px 0;
  width: 100%;

  >div {
    flex: 1;
    text-align: center;
  }

  .heading {
    font-weight: 400;
    font-size: 14px;
  }

  .value {
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;

    &.cet-year {
      font-size: 13px;
    }
  }
}

.expirationAlert {
  font-size: 13px;
  margin-bottom: -5px;
}

.qj-tag {
  padding: 0px;
  font-size: 10px;
}

.contract-button {
  height: 30px !important;
  padding: 6.5px 0px !important;
  font-size: 14px;
  font-weight: 800 !important;
  border: none;
  box-shadow: none !important;
  text-decoration: underline;
  background-color: #ffffff !important;
  color: var(--secondary) !important;
}

.qj-card-outline {
  border: 1px solid #f0f0f0;
  border-radius: 7px;
  padding: 0 12px;
  margin: 10px 0;
}

.qj-proposal-alias {
  font-size: 14px;
  padding: 0 !important;
  text-align: center;
}

.acordo {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  line-height: 0px;
  margin-bottom: 9.5px;

  &__label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: rgb(0, 0, 0);
  }

  .v-chip {
    height: unset;
    line-height: 15px;
  }

  .deal-status {
    line-height: 15px;
    font-weight: 500;
    font-size: 12px;
  }
}

.action-button {
  padding: 0px;
  flex: unset;
  font-size: 12px;
  font-weight: 800;
  text-align: center;
}

.external-ref {
  margin-bottom: 0;
  word-break: break-all;
}

.welcome-text {
  font-size: 14px;
  line-height: 115.9%;
  margin-bottom: 0px;

  .strong-text {
    font-weight: 700;
  }
}
</style>
