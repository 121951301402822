<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="310">
      <v-card class="text-center">
        <v-card-title class="justify-center">
          <h3 class="qj-h3">Não sei o meu e-mail</h3>
          <v-btn
            icon
            class="qj-btn-close-dialog"
            style="top: 9px; right: 2px"
            @click="close"
          >
            <v-icon>far fa-times-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-2">
          <p class="qj-p mb-7">
            Nos envie um e-mail clicando no botão abaixo! Isso ajudará a gente à descobrir
            qual é o seu e-mail.
          </p>
          <v-btn id="send__mail__btn__not__know" class="rounded-lg qj-btn qj-bg-primary" @click="supportEmail">
            Enviar e-mail
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import store from "@/store";
import { createTagManagerEvent } from '@/utils';

export default {
  props: ["dialog"],
  data: () => ({
    user: store.getters["negotiation/getMe"],
  }),
  methods: {
    close() {
      this.$emit("close");
    },
    supportEmail() {
      createTagManagerEvent({
        event: 'myInfoClickedSendEmailToSupport'
      })
      let CHANGE_EMAIL_SUBJECT = "Alteração de e-mail";
      let SUPPORT_EMAIL = "atendimento@quiteja.com.br";

      let documentName = this.user.type == "PERSONAL" ? "CPF" : "CNPJ";
      let body =
        "Olá, gostaria de alterar o meu e-mail.%0D%0A%0D%0A" +
        "Nome: " +
        this.user.name +
        "%0D%0A" +
        documentName +
        ": " +
        this.user.document;

      let parameters = ["subject=" + CHANGE_EMAIL_SUBJECT, "body=" + body];

      window.open("mailto:" + SUPPORT_EMAIL + "?" + parameters.join("&"), "_blank");
      this.close();
    },
  },
};
</script>

<style></style>
