<template>
  <v-card-text>
    <h2 class="qj-h4 text-center">
      Antes de prosseguir, responda nossa pesquisa! É rapidinho.
    </h2>

    <div class="qj-p ma-auto content-container">
      <p v-if="text" class="qj-card-text mt-2" v-html="text"></p>
      <p v-else class="qj-card-text mt-2">
        De 0 a 10, qual nota você dá pra sua <b>experiência de negociação</b>
        aqui no site da <b>QuiteJá</b>?
      </p>
      <v-row class="qj-nps-options">
        <v-btn v-for="number in options" :key="number" @click="selectRating(number)" :color="setColor(number)"
          class="qj-card-text rounded-lg qj-btn-nps"
          :class="(value == number ? 'font-weight-bold lighten-3' : 'lighten-4')">
          {{ number }}
        </v-btn>
      </v-row>
    </div>
  </v-card-text>
</template>

<script>
import {
  mapGetters,
  mapActions,
} from "vuex";
import { createTagManagerEvent } from "@/utils";

export default {
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    options: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  }),
  computed: {
    ...mapGetters('nps', ['npsValue']),
    value: {
      get: function () { return this.npsValue },
      set: function (value) { this.setNpsValue(value) }
    },
  },
  methods: {
    ...mapActions("nps", ["setNpsValue"]),
    setColor(num) {
      if (num < 7) {
        return "red";
      } else if (num < 9) {
        return "yellow";
      }
      return "green";
    },
    selectRating(value) {
      createTagManagerEvent({ event: "npsSelectedRating" });
      this.value = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.qj-nps-options {
  display: block !important;
  max-width: 270px;
  align-items: center;
  text-align: center;
  padding-top: 2px;

  .qj-btn-nps {
    width: 30px;
    max-height: 34px;
    min-width: 30px !important;
    padding: 0 0;
    box-shadow: none;
    margin: 5px 5px;
    font-weight: 400;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 20%) !important;
  }

  .qj-btn-nps:active {
    box-shadow: none !important;
  }

  .qj-btn-nps.red:before {
    background-color: #e57373 !important;
  }

  .qj-btn-nps.yellow:before {
    background-color: #ffeb3b !important;
  }

  .qj-btn-nps.green:before {
    background-color: #81c784 !important;
  }

  .qj-active {
    font-weight: 800 !important;
    background-color: var(--primary) !important;
  }

  .qj-btn-nps:hover::before {
    opacity: 1;
  }
}
</style>
