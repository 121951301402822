<template>
  <div class="assignor-line" :class="{ inList: inList }">
    <div class="assignor-text">
      <span> Origem </span>
    </div>
    <span class="assignor-name">
      <span class="bold-text">{{ operation.assignor }}</span>
      <v-icon
        v-if="isCreditorSecuritizier"
        @click="
          $emit('openDialog')
        "
        >mdi mdi-information-outline</v-icon
      >
    </span>
  </div>
</template>

<script>
export default {
  props: ["inList", "operation", "isCreditorSecuritizier"],
};
</script>

<style lang="scss" scoped>
.assignor-line {
  justify-content: space-between;
  background-color: #f6f6f6;
  padding: 0px;
  border-radius: 7px;
}

.assignor-text {
  font-size: 12px;
  font-weight: 400;
}

.assignor-line,
.assignor-name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.assignor-name {
  font-size: 12px;
  max-width: 65%;
  overflow-wrap: break-word;
  text-align: end;

  .bold-text {
    font-weight: 800;
    color: #000000;
  }

  .v-icon {
    color: #000000;
    font-size: 14px;
    margin: auto 0px auto 8px;

    &:focus::after {
      opacity: 0;
    }
  }

  @media (max-width: 320px) {
    font-size: 10px;
  }
}

.inList {
  padding: 7.5px 0px 8px;
  margin-bottom: -10px;

  span {
    font-size: 10px;
  }

  .bold-text {
    font-weight: 600;
  }
}
</style>