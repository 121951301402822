import moment from "moment";

/** Status válidos para um contato. */
const VALID_STATUS = [
  "CONFIRMED",
  "CREATED",
];

/**
 * Retorna uma lista de contatos de acordo com o tipo informado.
 * 
 * @param {Array} contactContacts lista dos contatos do cliente.
 * @param {String} contactType o tipo dos contatos retornados.
 * @returns uma lista de contatos.
 */
function getContacts(contactContacts, contactType) {
  if (!contactContacts) {
    return [];
  }

  // Ordena os contatos pela data de atualização mais recente
  contactContacts.sort((contactA, contactB) => {
    return moment(contactB.updated_at).toDate() -
      moment(contactA.updated_at).toDate();
  });

  // Filtra os contatos pelo tipo
  contactContacts = contactContacts.filter(contact => contact.type == contactType);

  // Filtra os contatos pelo status válido
  contactContacts = contactContacts.filter(contact => VALID_STATUS.indexOf(contact.status) > -1);

  // Verifica se existem contatos confirmados pelo próprio cliente
  let contacts = contactContacts.filter(contact => {
    return contact.status === "CONFIRMED" && contact.source === "CUSTOMER";
  });

  if (contacts.length > 0) {
    return contacts;
  }

  // Verifica se existem contatos confirmados
  contacts = contactContacts.filter(contact => contact.status === "CONFIRMED");

  if (contacts.length > 0) {
    return contacts;
  }

  // Verifica se existem contatos informados pelo cliente, mesmo que não
  // estejam confirmados
  contacts = contactContacts.filter(contact => contact.source === "CUSTOMER");

  if (contacts.length > 0) {
    return contacts;
  }

  // Verifica se existem contatos criados, mesmo que não sejam confirmados
  contacts = contactContacts.filter(contact => contact.status === "CREATED");

  return contacts;
}

export default {
  getContacts,
}
