export default {
  'ITAU_CAMPANHA_REORGANIZA': {
    seal: require('@/assets/img/svg/Renegocia_itau_selo.svg'),
    banner: {
      text: "Parcele o valor a vista sem juros",
      textStyle:  `
      width: 156px; 
      left: 27px;
      top: 631px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;`,
      style: "background-color: #FFE7D8; border-radius: 7px",
      class: "elevation-3"
    },
  }
}