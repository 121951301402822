<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="310">
      <v-card class="text-center">
        <v-card-title class="justify-center">
          <h3 class="qj-h3 mr-2">Solicitar cancelamento</h3>
          <v-btn
            icon
            class="qj-btn-close-dialog"
            style="top: 9px; right: 2px"
            @click="close()">
            <v-icon>far fa-times-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-3">
          <p class="qj-card-text">
            Tem certeza que deseja cancelar?
            <br /><br />
            {{ cardText }}
          </p>
          <div class="d-flex">
            <v-btn
            class="rounded-lg qj-btn qj-bg-danger qj-p-btn"
            style="width: calc(50% - 10px); margin-right: 10px"
            :loading="loading"
            @click="send()">
            Solicitar
          </v-btn>
          <v-btn
            class="rounded-lg qj-btn qj-bg-primary qj-p-btn"
            style="width: calc(50% - 10px); margin-left: 10px"
            @click="close()">
            Não solicitar
          </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["dialog", "loading", "cancellationText"],
  computed: {
    cardText() {
      return this.cancellationText ?? "Em caso de cancelamento, sua pendência permanecerá em aberto e os encargos permanecerão correndo diariamente";
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    send() {
      this.$emit("send");
    },
  },
};
</script>
