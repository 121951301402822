6<template>
  <div>
    <span class="qj-p"
      >Escolha em quantas parcelas no <b>cartão de crédito</b> você deseja
      quitar a sua dívida
    </span>
    <v-row class="shrink">
      <v-col>
        <v-select
          v-model="selectedProposal"
          :items="proposals"
          dense
          outlined
          :hide-details="true"
          color="#00e58d"
          return-object
          item-value="id"
        >
          <template v-slot:selection="{ item }">
            <span class="font-weight-medium"
              >{{ item.installments_count_1 }}x de
              {{ item.installment_1 | money }} ({{
                item.total_amount | money
              }})</span
            >
          </template>
          <template v-slot:item="{ item }">
            <span class="font-weight-medium"
              >{{ item.installments_count_1 }}x de
              {{ item.installment_1 | money }} ({{
                item.total_amount | money
              }})</span
            >
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="qj-card-outline" v-if="selectedProposal">
          <ProposalCard
            :proposal="selectedProposal"
            :doShowNextButton="false"
            :showDiscountTag="false"
            :showInterestRate="true"
            :deal="deal"
          />
        </div>
      </v-col>
    </v-row>
    <v-spacer></v-spacer>
    <qj-footer
      :type="proposals || items ? 'next' : 'help'"
      view="negociacao/credit-card"
      @send="next"
      :loading="loadingNext"
    />
    <ReloadProposalsDialog
      :dialog="dialogReloadProposals"
      @closeReloadProposalDialog="closeReloadProposalDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ProposalCard from "@/views/negotiation/components/proposalCard.vue";
import ReloadProposalsDialog from "../components/DialogReloadProposal";

export default {
  components: {
    ProposalCard,
    ReloadProposalsDialog,
  },
  data() {
    return {
      parentProposalId: null,
      items: null,
      items2: ["Foo", "Bar", "Fizz", "Buzz"],
      selectedProposal: null,
      loadingNext: false,
      dialogReloadProposals: false
    };
  },
  async created() {
    this.parentProposalId = window.atob(this.$route.query.id);
    await this.fetchProposals(this.parentProposalId);
  },
  mounted() {
    if (!this.deal) this.setSelectedDealByProposalId(this.parentProposalId);
    this.$emit("step", 2);
  },
  methods: {
    ...mapActions("parceleJa", {
      fetchParceleJaProposals: "getProposals",
    }),
    ...mapActions("negotiation", {
      validateProposal: "validateProposal",
      updateProposal: "updateProposal",
    }),
    ...mapMutations("negotiation", [
      "setNegotiation",
      "setSelectedDealByProposalId",
    ]),
    openReloadProposalsDIalog() {
      this.dialogReloadProposals = true;
    },
    closeReloadProposalDialog(){
      this.dialogReloadProposals = false
      this.$router.replace({name: 'NegotiationDeal', query: {id: window.btoa(this.deal.id)}}).catch((failure) => {
            this.skipIfNavigationFailureIsGeneric(failure)
          });
    },
    async next() {
      let dueDate = this.selectedProposal.down_payment_due_date;

      if (dueDate) {
        console.debug("Data de vencimento da proposta do Parcele Já:", dueDate);
      } else {
        console.debug("Proposta do Parcele Já sem data de vencimento");

        let dueDates = this.selectedProposal._down_payment_dates || [];
        dueDate = dueDates[dueDates.length - 1] || this.$moment(this.date).date();
      }

      this.setNegotiation({
        date: dueDate,
        ...this.selectedProposal,
      });
      this.loadingNext = true;

      const isValidProposal = await this.validateProposal({
        proposalId: this.selectedProposal.id,
      });
      if (!isValidProposal) {
        this.openReloadProposalsDIalog();
        return;
      }
      try {
        await this.updateProposal({
          proposal: this.selectedProposal,
          status: "SELECTED",
        });
      } catch {
        return null;
      } finally {
        this.loadingNext = false;
      }
      try {
        await this.$router["push"]({
          name: "CreditCardPayment",
          query: {
            id: window.btoa(this.selectedProposal.id),
          },
          params: {
            date: dueDate,
          },
        });
        this.$emit("step", 3);
      } catch (failure) {
        this.skipIfNavigationFailureIsGeneric(failure)
      }
    },
    async fetchProposals(parentProposalId) {
      this.$emit("loading", true);
      await this.fetchParceleJaProposals(parentProposalId);
      this.$emit("loading", false);
      this.items = this.proposals;
      if (!this.proposals) {
        this.$router.push({ name: "Error" });
        return;
      }
      this.selectedProposal = this.proposals[0];
    },
  },
  computed: {
    ...mapGetters("parceleJa", {
      proposals: "proposals",
    }),
    ...mapGetters("negotiation", {
      deal: "getSelectedDeal",
    }),
  },
};
</script>

<style>
</style>