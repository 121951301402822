import { render, staticRenderFns } from "./QjAlert.vue?vue&type=template&id=700dd508&scoped=true"
import script from "./QjAlert.vue?vue&type=script&lang=js"
export * from "./QjAlert.vue?vue&type=script&lang=js"
import style0 from "./QjAlert.vue?vue&type=style&index=0&id=700dd508&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "700dd508",
  null
  
)

export default component.exports