<template>
  <div v-if="proposal">
    <v-row class="qj-row-main">
      <v-col cols="12" class="pt-0 pb-2">
        <span class="header-span"> A <b>oferta</b> escolhida por você foi</span>
      </v-col>
      <v-col cols="12" class="pt-0">
        <div
          class="qj-card-outline"
          :style="generateProposalBoxStyle(proposal)"
        >
          <ProposalCard
            :proposal="proposal"
            :proposals="proposals"
            :doShowNextButton="false"
            :showPaymentMethod="true"
            :deal="deal"
            :showAlias="true"
            :loading="loadingSummary"
          />
        </div>
      </v-col>
      <template v-if="proposal.payment_methods">
        <v-col class="py-0 mb-3" cols="12">
          <span class="qj-p"
            >Escolha o <strong>método</strong> de pagamento
            {{ paymentTypeText(proposal) }}</span
          >
        </v-col>
        <v-col cols="12" class="pt-0 mb-2 d-flex align-center justify center">
          <v-text-field
            class="qj-simulation-param-value__v-select mr-5 elevation-0 font-weight-bold"
            dense
            outlined
            hide-details
            readonly
            :value="
              paymentMethods.find(
                (method) => method.value == proposal.payment_method
              )?.text
            "
            @click.stop="openChangePaymentMethod()"
          >
          </v-text-field>
          <div class="d-flex align-center">
            <v-btn
              @click="openChangePaymentMethod()"
              block
              class="rounded-lg qj-btn qj-bg-secondary-light"
            >
              Alterar
            </v-btn>
          </div>
        </v-col>
      </template>
      <v-col cols="12" class="py-0">
        <p class="qj-p">
          <span v-if="proposal.payment_type == 'IN_CASH' && hidePaymentType">
            A data do
            <b>pagamento</b>
            será
          </span>
          <span v-else-if="proposal.payment_type == 'IN_CASH'">
            A data do
            <b>{{
              proposal.payment_method == "DEBIT_CARD"
                ? "débito"
                : "pagamento do boleto"
            }}</b>
            será
          </span>
          <span
            v-else-if="
              proposal.payment_type == 'INSTALLMENT' &&
              downPaymentDates.length >= 1 &&
              !isOnlyDownPayment()
            "
          >
            <span>
              Escolha o dia para o
              <b>vencimento {{ paymentTypeText(proposal) }}</b></span
            >
          </span>
          <span
            v-else-if="downPaymentDates?.length <= 1 || isOnlyDownPayment()"
          >
            <div class="mb-2">
              <span>
                A data para o
                <b>pagamento {{ paymentTypeText(proposal) }}</b> será</span
              >
            </div>
          </span>
          <span
            v-else-if="
              proposal.payment_type == 'INSTALLMENT' &&
              downPaymentDates.length <= 1
            "
          >
            <span> O dia para o <b>vencimento das parcelas</b> será</span>
          </span>
          <span v-else>
            <span> Escolha o dia para o <b>pagamento</b></span>
          </span>
        </p>
      </v-col>
      <v-col cols="12" class="pt-0" style="line-height: 14px">
        <DialogDatePicker
          v-if="downPaymentDates.length > 5"
          v-model="date"
          :downPaymentDates="downPaymentDates"
          class="mt-2"
        >
          <template v-slot:activator="{ on }">
            <div class="d-flex" v-on="hasMoreThenOneDate() ? on : null">
              <v-text-field
                dense
                readonly
                outlined
                required
                single-line
                hide-details
                color="#00E58D"
                prepend-inner-icon="mdi-calendar"
                class="qj-date-input mr-5"
                :value="date | brazilianDate"
                data-hj-allow
              >
              </v-text-field>
              <div class="d-flex align-center" v-if="hasMoreThenOneDate()">
                <v-btn block class="rounded-lg qj-btn qj-bg-secondary-light">
                  Alterar
                </v-btn>
              </div>
            </div>
          </template>
        </DialogDatePicker>
        <v-text-field
          v-else-if="downPaymentDates?.length <= 1"
          dense
          readonly
          outlined
          disabled
          single-line
          hide-details
          color="#00E58D"
          prepend-inner-icon="mdi-calendar"
          class="qj-date-input disabled-input"
          :value="date | brazilianDate"
          data-hj-allow
        >
        </v-text-field>
        <v-select
          v-else
          class="qj-simulation-param-value__v-select mt-2 elevation-0"
          dense
          outlined
          v-model="date"
          color="#00e58d"
          :hide-details="true"
          :items="downPaymentDates"
          :disabled="downPaymentDates?.length < 1"
          :menu-props="{
            closeOnContentClick: true,
          }"
        >
          <template v-slot:selection="{ item }">
            <span class="date-list">{{ item | brazilianDate }}</span>
          </template>
          <template v-slot:item="{ item }">
            <div
              class="qj-simulation-param-value__v-select"
              style="width: 100%"
            >
              <span class="date-list">{{ item | brazilianDate }}</span>
            </div>
          </template>
        </v-select>
        <div>
          <span>
            <b
              class="mb-2"
              v-if="
                !showInstallmentsDueDates &&
                proposal.payment_type == 'INSTALLMENT'
              "
              style="
                font-style: normal;
                font-weight: 800;
                font-size: 12px;
                margin-bottom: 10px;
              "
            >
              Pagamento das demais parcelas ocorrerá sempre no dia
              {{ date ? date.split("-")[2] : "" }} de cada mês.
              <br />
            </b>
          </span>
        </div>
      </v-col>
      <v-col
        cols="12"
        class="pt-0 mt-2 pb-5 text-center"
        v-if="showInstallmentsDueDates()"
      >
        <div>
          <div class="mb-0 qj-p text-left best-expire-day">
            Agora escolha qual é o melhor dia para o
            <b>vencimento das parcelas</b>
          </div>
          <v-select
            ref="installentsSelect"
            class="qj-simulation-param-value__v-select mt-2 elevation-0"
            v-model="installmentDueDate"
            :items="filteredInstallmentsDueDates"
            dense
            outlined
            :hide-details="true"
            color="#00e58d"
            :menu-props="{
              closeOnContentClick: true,
            }"
          >
            <template v-slot:selection="{ item }">
              <span
                v-if="item == 'Mesmo dia da entrada'"
                class="font-weight-bold"
                >Mesmo dia da entrada</span
              >
              <span v-else class="font-weight-bold">Todo dia {{ item }}</span>
            </template>
            <template v-slot:item="{ item }">
              <div class="qj-simulation-param-value__v-select">
                <span
                  v-if="item == 'Mesmo dia da entrada'"
                  class="font-weight-bold"
                  >Mesmo dia da entrada</span
                >
                <span v-else class="font-weight-bold">Todo dia {{ item }}</span>
              </div>
            </template>
          </v-select>
        </div>
      </v-col>
      <v-col
        class="pt-0 col-12 pb-3"
        v-if="otherTickets() && doShowBankSlip() && !hideInstallmentDueDateDesc"
      >
        <p v-if="date" class="other-tickets mb-0">
          As demais parcelas
          <strong>
            vencerão
            {{
              remainingInstallmentsText()
                ? remainingInstallmentsText()
                : proposalDueDay()
            }}
          </strong>
        </p>
      </v-col>
      <qj-alert-info
        v-if="this.proposal.payment_type != 'IN_CASH'"
        content-class="pa-3 remember-this"
        name="proposal-due-date-disclaimer"
        :creditorId="deal.creditor_id"
        :deal="deal"
      />
      <v-col class="pt-0 col-12">
        <span v-if="!isParcialPayment()">
          <p class="remember-this">
            Lembre-se que após a confirmação do pagamento, se houver negativação
            referente a esta dívida, seu nome sairá dos órgãos de proteção ao
            crédito em até 5 dias úteis
          </p>
        </span>
      </v-col>
    </v-row>
    <!-- Footer -->
    <qj-footer
      type="next"
      :fixed="false"
      @send="next"
      view="negociacao/pagamento"
    />

    <DialogChangeDate
      :date="date"
      :dialog="dialogChangeDate"
      :days="downPaymentDates"
      @setDate="setDate($event)"
      @close="dialogChangeDate = false"
    />
    <DialogChangePaymentMethod
      :paymentMethods="proposal.payment_methods"
      ref="DialogChangePaymentMethod"
      @updatePaymentMethod="updatePaymentMethod"
    />
  </div>
</template>

<script>
import moment from "moment";
import store from "@/store";
import Deal from "@/modules/deal";
import { mapMutations, mapGetters } from "vuex";
import util from "@/assets/js/util";
import { createTagManagerEvent } from "@/utils";
import DialogChangeDate from "../components/DialogChangeDate";
import DialogDatePicker from "@/views/negotiation/components/DialogDatePicker.vue";
import ProposalCard from "@/views/negotiation/components/proposalCard.vue";
import DialogChangePaymentMethod from "../components/DialogChangePaymentMethod";
import serviceUtil from "@/services/util";

export default {
  components: {
    DialogChangeDate,
    DialogDatePicker,
    ProposalCard,
    DialogChangePaymentMethod,
  },
  data: () => ({
    dialogChangeDate: false,
    date: "",
    // Dias disponíveis para o vencimento da entrada
    downPaymentDates: [],
    proposal: {},
    proposals: [],
    hideInstallmentDueDateDesc: false,
    hidePaymentType: false,
    // Dia de vencimento das parcelas
    installmentDueDate: "Mesmo dia da entrada",
    // Dias disponíveis para o vencimento das parcelas
    installmentsDueDates: [],
    filteredInstallmentsDueDates: null,
    paymentMethods: [
      { value: "BOLETO", text: "Boleto" },
      { value: "FIRST_PIX_OTHERS_BOLETO", text: "Pix" },
      { value: "FIRST_PIX_OTHERS_DEBIT", text: "Pix" },
      { value: "FIRST_BOLETO_OTHERS_DEBIT", text: "Boleto" },
      { value: "PIX", text: "Pix" },
    ],
    deal: null,
    loadingSummary: false,
  }),
  async created() {
    if (!this.$route.query.id) {
      this.$router.push({ name: "Deals" }).catch((failure) => {
        this.skipIfNavigationFailureIsGeneric(failure);
      });

      return;
    }

    let proposalId = util.base64Decode(this.$route.query.id);
    this.proposal = util.getProposal(proposalId);
    this.$emit("loading", false);
    if (util.isEmpty(this.proposal)) {
      this.$router.push({ name: "Deals" }).catch((failure) => {
        this.skipIfNavigationFailureIsGeneric(failure);
      });

      return;
    }
    this.downPaymentDates =
      this.proposal.down_payment_dates ||
      this.proposal._down_payment_dates ||
      [];

    let deal = store.getters["negotiation/getDeal"](this.proposal.deal_id);
    this.deal = store.getters["negotiation/getDeal"](this.proposal.deal_id);
    this.proposals = deal?.proposals;
    this.hidePaymentType = util.getConfigValue(
      this.deal?.creditor?.config_prefix + "hide_payment_method"
    );
    if (!util.isEmpty(this.proposal.down_payment_due_date)) {
      this.date = this.proposal.down_payment_due_date;
    } else if (!util.isEmpty(this.downPaymentDates)) {
      this.date = this.downPaymentDates?.[0];
    }

    let configInstallmentBirthdays = util.getConfigValue(
      deal.creditor.config_prefix + "installment_birthdays"
    );

    this.hideInstallmentDueDateDesc =
      util.getConfigValue(
        deal.creditor.config_prefix + "hide_installment_due_date_desc"
      ) >= 1;

    if (!util.isEmpty(configInstallmentBirthdays)) {
      this.installmentsDueDates = Deal.createInstallmentDueDates(
        this.date,
        configInstallmentBirthdays.split(",")
      );
      this.installmentsDueDates.unshift("Mesmo dia da entrada");
      // this.installmentDueDate = negotiation.installmentDueDate;
    }

    this.filterInstallmentsDueDates();

    if (util.isEmpty(this.downPaymentDates) && !this.date) {
      this.$router.push({ name: "Deals" }).catch((failure) => {
        this.skipIfNavigationFailureIsGeneric(failure);
      });
    }
    this.$emit("step", 2);
  },
  methods: {
    ...mapMutations("negotiation", ["setNegotiation"]),
    doShowBankSlip() {
      return util.doShowBankSlip(this.proposal.deal_id);
    },
    async recalculateProposal() {
      const configPrefix = util.getPrefixByProposal(this.proposal.id);
      const url =
        util.getConfig()[configPrefix + "proposal_recalculate_url"] ??
        util.getConfig()[configPrefix + "proposal_summary_url"];
      const shouldCallRecalculate =
        util.getConfig()[
          configPrefix + "call_recalculate_on_proposal_date_change"
        ];
      if (!shouldCallRecalculate && !url) return;
      const payload = {
        proposal_id: this.proposal.id,
        payment_method: this.proposal.payment_method,
        due_date: this.date,
        metadata: this.metadata,
        email: this.email || null,
      };
      const options = {
        headers: {
          "Authentication-Token": store.getters["authentication/getToken"],
        },
      };
      try {
        this.$emit("loading", true);
        const { data } = await serviceUtil.generic(
          url,
          "post",
          payload,
          options
        );
        if (data?.proposal) {
          this.proposal = Object.assign(this.proposal, data?.proposal);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.$emit("loading", false);
      }
    },
    openDialogChangeData() {
      if (!this.hasMoreThenOneDate()) return false;
      this.dialogChangeDate = true;
      createTagManagerEvent({
        event: "dealClickedChangePaymentDueDate",
      });
    },
    paymentTypeText(proposal) {
      if (proposal.down_payment > 0) return "da entrada";
      else if (
        proposal.payment_type == "INSTALLMENT" &&
        proposal.down_payment <= 0
      )
        return "da 1ª parcela";
      return "";
    },
    async updatePaymentMethod($event) {
      this.proposal.payment_method = $event;
      this.$emit("loading", true);
      try {
        await this.recalculateProposal();
      } finally {
        this.$emit("loading", false);
      }
    },
    openChangePaymentMethod() {
      this.$refs.DialogChangePaymentMethod.open(this.proposal.payment_method);
    },
    transformPaymentMethod() {
      console.log(arguments);
    },
    isParcialPayment() {
      return this.proposal?.agreement_type == "REGULARIZE";
    },
    next(routeMethod) {
      if (this.proposal.debit_suggestion_id) this.$emit("step", 4);
      else this.$emit("step", 3);
      createTagManagerEvent({
        event: "dealSubmittedPaymentDueDate",
      });
      this.setNegotiation({
        date: this.date,
        installmentDueDate: this.showInstallmentsDueDates
          ? this.getInstallmentDueDate()
          : null,
      });
      this.$router[routeMethod || "push"]({
        name: "NegotiationContactInfo",
        query: {
          id: window.btoa(this.proposal.id),
        },
        params: {
          date: this.date,
          skipPaymentInfo: routeMethod == "replace" ? true : false,
        },
      }).catch((failure) => {
        this.skipIfNavigationFailureIsGeneric(failure);
      });
    },
    getInstallmentDueDate() {
      if (this.installmentDueDate == "Mesmo dia da entrada") {
        return moment(this.date).date();
      }
      return this.installmentDueDate;
    },
    setDate(date) {
      this.date = date;
      this.dialogChangeDate = false;
    },
    showInstallmentsDueDates() {
      if (!this.proposal) {
        return false;
      }

      if (this.proposal.payment_type != "INSTALLMENT") {
        return false;
      }

      if (this.installmentsDueDates.length == 0) {
        return false;
      }

      if (!this.proposal.down_payment) {
        return false;
      }
      //  this.installmentDueDate = String(new Date(this.date.replace('-', ' ')).getDate())
      return true;
    },
    returnProposalBoxClass(proposal) {
      if (!proposal) return "";
      if (proposal?.incentive == "Máximo parcelamento") {
        return "incentive-color-purple";
      } else if (proposal?.incentive == "Parcelamento máximo") {
        return "incentive-color-purple";
      } else if (proposal?.incentive == "Oferta sem juros") {
        return "incentive-color-gray";
      } else if (
        proposal.payment_type == "IN_CASH" &&
        proposal?.discount_rate > 0.1 &&
        !this.proposalOfferType &&
        proposal.id == this.proposals?.[0]?.id &&
        !proposal.incentive
      ) {
        return "incentive-color-pink";
      } else {
        return null;
      }
    },
    generateProposalBoxStyle(proposal) {
      if (this.returnProposalBoxClass(proposal) || !proposal?.incentive)
        return null;
      return {
        borderColor: util.stringToColor(proposal.incentive) ?? "#853500",
      };
    },
    filterInstallmentsDueDates() {
      let dueDate = new Date(this.date).getDate() + 1;
      this.filteredInstallmentsDueDates = this.installmentsDueDates.filter(
        (installmentDueDate) => {
          return installmentDueDate != dueDate;
        }
      );
    },
    otherTickets() {
      if (
        !this.showInstallmentsDueDates() &&
        this.proposal.payment_type != "IN_CASH"
      ) {
        return true;
      }
      return false;
    },
    hasMoreThenOneDate() {
      if (
        this.installmentsDueDates?.length > 1 ||
        this.downPaymentDates?.length > 1
      ) {
        return true;
      }
      return false;
    },
    remainingInstallmentsText() {
      let configPrefix = util.getPrefixByProposal(this.proposal.id);
      if (!configPrefix) return false;

      let config =
        util.getConfig()[configPrefix + "remaining_installments_text"];
      if (!config) return false;

      return config.charAt(0).toLowerCase() + config.slice(1);
    },
    proposalDueDay() {
      let day = this.date.split("-")[2];

      if (this.proposal.installment_due_date)
        day = this.proposal.installment_due_date;

      return `todo dia ${day}`;
    },
    isOnlyDownPayment() {
      let configPrefix = util.getPrefixByProposal(this.proposal.id);
      if (!configPrefix) return false;

      let config = util.getConfigValue(configPrefix + "is_only_down_payment");
      if (!config) return false;
      return config.toLowerCase();
    },
  },
  watch: {
    async date(newValue, oldValue) {
      if (oldValue) {
        await this.recalculateProposal();
      }
      let dueDate = new Date(this.date).getDate() + 1;
      if (dueDate == this.installmentDueDate) {
        this.installmentDueDate = "Mesmo dia da entrada";
      }
      this.filterInstallmentsDueDates();
    },
  },
};
</script>

<style lang="scss">
.header-span {
  font-size: 14px;
}
.qj-date-input {
  .v-input__slot {
    align-items: center;
    justify-content: center;
  }
  .v-input__prepend-inner {
    margin-right: 0 !important;
    i {
      color: rgb(17, 17, 17) !important;
    }
  }
  .v-text-field__slot {
    flex-grow: 0 !important;
    flex-shrink: 1 !important;
  }
  input {
    text-align: center;
    font-weight: 800;
    font-size: 14px;
    width: 100px;
  }
}
.qj-card-outline {
  margin-top: 2px;
  margin-bottom: 15px;
}
.qj-installments-due-dates {
  margin-top: 32px;
  padding: 0 28px;

  .question {
    font-weight: 800;
  }

  .v-input--radio-group__input {
    justify-content: space-between;

    .v-input--selection-controls__input {
      margin-right: 8px !important;
    }
  }
}
.other-tickets {
  line-height: 14px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.qj-alert-info {
  width: 315px;
  margin: 0 auto;
}

.qj-p {
  margin-bottom: 10px !important;
}
.qj-p,
.header-span {
  b {
    font-weight: 700;
  }
}
.date-list {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}
.best-expire-day {
  font-size: 14px;
  line-height: 115.9%;
}
.remember-this {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 115.9%;
  color: #616161;
}
.qj-simulation-param-value__v-select {
  font-size: 14px;
  input {
    text-align: center;
  }
}
.disabled-input {
  input {
    color: #000000 !important;
  }
  .theme--light.v-icon.v-icon.v-icon--disabled {
    color: #000000 !important;
  }
}
</style>
