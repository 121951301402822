<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h3 class="qj-h3 text-center">Qual é o seu e-mail?</h3>
      </v-col>
      <v-col cols="12">
        <p class="qj-p mb-0 text-center">
          Nos informe o seu e-mail para receber informações importantes sobre o seu
          acordo, como contratos e boletos para pagamento.
        </p>
      </v-col>
      <v-col cols="12" class="pb-0" v-for="email in emails" :key="email.id">
        <div class="d-flex align-center">
          <v-btn
            v-if="emails.length > 1"
            icon
            @click="
              setEmail = email;
              dialogDeleteEmail = true;
            "
          >
            <v-icon style="font-size: 16px; color: #212121">far fa-times-circle</v-icon>
          </v-btn>
          <div
            class="d-flex align-center justify-space-between"
            style="
              background: #f6f6f6;
              border-radius: 7px;
              padding: 11px 10px;
              height: 38px;
              width: 100%;
            "
          >
            <p class="qj-p mb-0">{{ email.value }}</p>
            <v-btn
              class="edit__email__btn"
              icon
              style="margin-right: -9px"
              @click="openDialogEditEmail(email)"
            >
              <v-icon style="font-size: 16px; color: #212121">fas fa-pen</v-icon>
            </v-btn>
          </div>
        </div>
        <span
          v-if="email.status != 'CONFIRMED'"
          class="qj-span qj-color-danger"
          :style="emails.length > 1 ? 'margin-left: 36px;' : ''"
          >Verificação pendente.&nbsp;
          <a
            v-if="email.created_at"
            class="qj-color-danger"
            href="javascript:void(0);"
            @click="
              setEmail = email;
              dialogResendEmail = true;
            "
            >Clique aqui para reenviar.</a
          >
        </span>
      </v-col>

      <v-col v-if="emails.length == 0" cols="12" class="text-center mt-3">
        <v-btn
          class="rounded-lg qj-btn qj-bg-secondary-light"
          @click="addEmail"
        >
          Adicionar e-mail
        </v-btn>
      </v-col>

      <v-col cols="12" class="text-center mt-3">
        <v-btn
          class="rounded-lg qj-btn qj-bg-secondary-light"
          @click="openDialogDontKnow()"
          id="btn__dont__know__my__email"
        >
          Não sei o meu e-mail
        </v-btn>
      </v-col>
    </v-row>
    <!-- Footer -->
    <qj-footer type="other" :hasCustomerServiceBtn="true" />
    <!-- dialogNotKnow  -->
    <DialogNotKnow :dialog="dialogNotKnow" @close="dialogNotKnow = false" />
    <!-- dialogEditEmail  -->
    <DialogEditEmail :email="setEmail" :dialog="dialogEditEmail" @close="closeModal()" />
    <!-- dialogResendEmail  -->
    <DialogResendEmail
      :email="setEmail"
      :dialog="dialogResendEmail"
      @close="dialogResendEmail = false"
    />
    <!-- dialogDeleteEmail  -->
    <DialogDeleteEmail
      :email="setEmail"
      :dialog="dialogDeleteEmail"
      @close="closeModal()"
    />
  </div>
</template>

<script>
import store from "@/store";
import { mapActions } from "vuex";
import Contact from "@/modules/contact";
import DialogNotKnow from "../components/NotKnow";
import DialogEditEmail from "../components/EditEmail";
import DialogResendEmail from "../components/ResendEmail";
import DialogDeleteEmail from "../components/DeleteEmail";
import { createTagManagerEvent } from '@/utils';

export default {
  components: {
    DialogNotKnow,
    DialogEditEmail,
    DialogResendEmail,
    DialogDeleteEmail,
  },
  data: () => ({
    user: store.getters["negotiation/getMe"],
    dialogNotKnow: false,
    dialogEditEmail: false,
    dialogResendEmail: false,
    dialogDeleteEmail: false,
    emails: [],
    setEmail: null,
  }),
  beforeCreate() {
    this.$emit("loading", true);
  },
  created() {
    this.loadContacts();
  },
  methods: {
    ...mapActions("negotiation", ["ActionsMe"]),
    openDialogEditEmail(email) {
      createTagManagerEvent({
        event: 'myInfoClickedEditEmail'
      })
      this.setEmail = email;
      this.dialogEditEmail = true;
    },
    openDialogDontKnow() {
      createTagManagerEvent({
        event: 'myInfoClickedDontKnowEmail'
      })
      this.dialogNotKnow = true;
    },
    loadContacts() {
      console.info('Carregando os contatos.');

      this.ActionsMe().then(() => {
        this.user = store.getters["negotiation/getMe"];
        let emails = Contact.getContacts(this.user?.contacts, "EMAIL");

        console.info('E-mails carregados:', this.emails.length);

        // Se não encontrou nenhum e-mail válido então exibe todos os e-mails com status inválido
        // para que o cliente possa editar e corrigir o e-mail
        if (emails.length == 0) {
          emails = this.user.contacts.filter(contact => contact.type == "EMAIL");
          emails = this.emails.filter(contact => contact.status == "INVALID");
        }

        this.emails = emails;
      })
      .catch((error) => {
        console.error("Não foi possível carregar os contatos.", error);
      })
      .finally(() => {
        this.$emit("loading", false);
      });
    },
    closeModal() {
      this.dialogEditEmail = false;
      this.dialogDeleteEmail = false;
      this.loadContacts();
    },
    addEmail() {
      this.setEmail = {};
      this.dialogEditEmail = true;
    }
  },
};
</script>

<style></style>
