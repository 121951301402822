<template>
  <v-dialog v-model="dialog" persistent max-width="310">
    <v-card class="text-center">
      <v-card-title class="justify-center">
        <img src="@/assets/img/svg/aviso.svg" alt="image aguarde" />
      </v-card-title>
      <v-card-text>
        <h3 class="qj-h3 mb-3">Aviso importante</h3>
        <p class="mb-5 qj-p">
          Você tem <b>apenas mais uma tentativa </b> para validar o seu PIN.
          Lembrando que você poderá encontra-lo na fatura do seu cartão de
          crédito, como na imagem de exemplo abaixo.
        </p>
        <v-row
          background="black"
          class="shrink bank-notification white--text mx-auto mb-4"
          width="385"
        >
          <v-col cols="2" background="black">
            <img src="@/assets/img/svg/creditor.svg" />
          </v-col>
          <v-col cols="10" class="text-left">
            <h5 class="mb-2">
              Seu Banco <span class="text-caption">09:57</span>
            </h5>
            <h4>Compra no crédito aprovada</h4>
            <span class="text-caption white--text">
              <span>Compra de R$ 11,12 APROVADA em PJA*</span>
              <span class="highlight">277</span>QUITEJA
              <span>para o cartão com final ****.</span>
            </span>
          </v-col>
        </v-row>
        <v-btn
          block
          class="rounded-lg qj-btn qj-bg-primary qj-p-btn"
          @click="$emit('close')"
          >Entendi</v-btn
        >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["msg", "title", "dialog"],
  watch: {
    dialog() {
      if (this.dialog && !this.msg) this.next();
    },
  },
  methods: {},
  computed: {},
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__text {
  padding: 0 19px 20px;
}
.v-dialog__content .v-dialog:not(.v-dialog--fullscreen) {
  max-height: initial !important;
}
</style>
