import http from "@/plugins/axios";

export default {
  /**
   * Cria um evento.
   *
   * @param {Object} event o evento.
   * @return o evento.
   */
  create(event) {
    return http.api.post("/v1/event", event);
  },
}
