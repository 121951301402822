<template>
  <div class="deals-container">
    <v-row class="px-4 qj-body grow">
      <v-slide-x-transition v-if="panel == 1" hide-on-leave>
        <v-col class="px-0 pt-0" key="1">
          <v-row v-if="!util.isEmpty(agreements()) || loadingDeals">
            <v-col class="pt-0">
              <p v-if="loadingDeals" class="qj-card-text mb-0">
                <b>Aguarde um instante...</b>
                <br />enquanto buscamos seus débitos.
              </p>
              <p v-else-if="!util.isEmpty(agreements())" class="qj-p mb-0">
                {{
                  hasManyAgreements
                    ? "Estes são os acordos feitos"
                    : "Este é o acordo feito"
                }}
                anteriormente conosco<br />
              </p>
            </v-col>
          </v-row>
          <div>
            <v-scroll-y-transition hide-on-leave>
              <div
                key="1"
                v-if="util.isEmpty(agreements()) && !loadingDeals"
                class="qj-card-msg mt-2"
              >
                <p class="qj-p mb-0 text-center">
                  Você não tem nenhum acordo em andamento no momento.
                </p>
              </div>

              <div key="2" v-else>
                <div
                  v-for="(deal, index) in agreements(showAllAgreements)"
                  :key="index"
                >
                  <CardAgreement
                    :isOperationsValueVisible="isOperationsValueVisible"
                    :msgBold="msgBold"
                    :agreementDetails="agreementDetails"
                    :typeTega="typeTega"
                    :maxDebit="maxDebit"
                    :deal="deal"
                    :msg="msg"
                    :isPaymentMethodVisible="isPaymentMethodVisible"
                    @downloadBoleto="downloadBoleto"
                    @openBillAlert="openBillAlert"
                  />
                </div>
              </div>
            </v-scroll-y-transition>
          </div>
          <div class="d-flex justify-center">
            <a
              v-if="otherAgreements()"
              class="text-body-2 grey--text text-decoration-underline mx-auto my-4"
              @click="showAllAgreements = !showAllAgreements"
            >
              {{ showAllAgreements ? "Esconder" : "Ver" }} acordos antigos
            </a>
          </div>
        </v-col>
      </v-slide-x-transition>
      <v-slide-x-reverse-transition hide-on-leave v-else>
        <div key="2">
          <v-row v-if="true">
            <v-col class="pt-0">
              <p v-if="loadingDeals" class="qj-card-text mb-0">
                <b>Aguarde um instante... </b>enquanto buscamos seus débitos.
              </p>
              <p
                v-else-if="!util.isEmpty(debits)"
                class="qj-p mb-0"
                v-html="getDebitsText"
              ></p>
            </v-col>
          </v-row>
          <div>
            <div
              v-if="util.isEmpty(debits) && !loadingDeals"
              class="qj-card-msg"
            >
              <p class="qj-p mb-0 text-center">
                Você não tem nenhuma dívida para negociar no momento.
              </p>
            </div>

            <div v-else>
              <div v-for="(items, index) in debits" :key="index">
                <CardDebit
                  :calculateDiscountRate="calculateDiscountRate"
                  :dontShowDiscount="dontShowDiscount"
                  :incashProposal="incashProposal"
                  :maxDebit="maxDebit"
                  :getAssignor="getAssignor"
                  :doShowAssignorBand="doShowAssignorBand"
                  :next="next"
                  :deal="items"
                  :isDebitValueVisible="isDebitValueVisible"
                  :totalDebit="totalDebit"
                  :getSelectDealButtonText="getSelectDealButtonText"
                  :getAssignorLogo="getAssignorLogo"
                />
              </div>
            </div>
          </div>
          <v-col
            v-if="
              util.isEmpty(debits) &&
              util.isEmpty(agreements()) &&
              !loadingDeals
            "
          >
          </v-col>
          <ProposalLoading
            :loadingDeals="loadingDeals"
            :loadingTasks="loadingDeals"
          />
        </div>
      </v-slide-x-reverse-transition>
    </v-row>
    <!-- Footer -->
    <qj-footer class="" />
    <!-- Dialog acordo quitado -->
    <DialogDetailsAgreement
      v-if="deal"
      :deal="deal"
      :dialog="dialogDetailsAgreement"
      @close="dialogDetailsAgreement = false"
    />
    <DialogBillAlert
      ref="DialogBillAlert"
      :dialog="dialogBillAlert"
      @downloadBoleto="downloadBoleto"
      @close="dialogBillAlert = false"
    />
    <ParceleJaAgreementsDetails
      v-if="deal"
      :deal="deal"
      :dialog="dialogParceleJaAgreementDetails"
      @close="dialogParceleJaAgreementDetails = false"
    />
    <v-bottom-navigation
      v-if="$vuetify.breakpoint.width <= 599"
      v-model="panel"
      background-color=""
      color="#00606A"
      app
    >
      <v-btn class="qj-bottom-nav">
        <span>Minhas dívidas</span>
        <v-icon>mdi-cash-multiple</v-icon>
      </v-btn>
      <v-btn class="qj-bottom-nav">
        <span>Meus acordos</span>
        <v-icon>mdi-handshake</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <DIalogCreditorsOff></DIalogCreditorsOff>
  </div>
</template>

<script>
import * as Sentry from "@sentry/browser";

import moment from "moment";
import store from "@/store";
import { mapActions, mapMutations, mapGetters } from "vuex";
import Deal from "@/modules/deal";
import Proposal from "@/modules/proposal";
import util from "@/assets/js/util";
import DialogDetailsAgreement from "../components/DialogDetailsAgreement";
import ProposalLoading from "../components/ProposalLoading.vue";
import { CreditorId } from "@/constants";
import CardAgreement from "../components/CardAgreement.vue";
import CardDebit from "../components/CardDebit.vue";
import ParceleJaAgreementsDetails from "../components/ParceleJaAgreementsDetails.vue";
import serviceEvent from "@/services/event";
import servicePayment from "@/services/payment";
import { createTagManagerEvent } from "@/utils";
import DialogBillAlert from "../components/DialogBillAlert";
import DIalogCreditorsOff from "../components/DIalogCreditorsOff.vue";

export default {
  components: {
    DialogDetailsAgreement,
    ProposalLoading,
    CardAgreement,
    CardDebit,
    ParceleJaAgreementsDetails,
    DialogBillAlert,
    DIalogCreditorsOff,
  },
  data: () => ({
    navigation: 0,
    util: util,
    client: store.getters["negotiation/getMe"],
    dialogParceleJaAgreementDetails: false,
    deal: null,
    dialogDetailsAgreement: false,
    showAllAgreements: false,
    tab: 0,
    items: [
      {
        id: 1,
        text: "Minhas dívidas",
        active: true,
      },
      {
        id: 2,
        text: "Meus acordos",
        active: false,
      },
    ],
    dialogBillAlert: false,
  }),
  mounted() {
    setTimeout(() => {
      this.$refs?.tabs?.callSlider();
      if (this.$route.query.view) this.setPanel(this.$route.query.view);
    }, 300);
    if (!this.loadingDeals || this.isDealToContinueSynced()) {
      this.resolveRouteContinue();
      return;
    }
  },
  beforeCreate() {
    this.$emit("loading", true);
  },
  async created() {
    this.setFooter(false);

    if (store.getters["authentication/getFomentation"]) {
      var fomentos = [];
      this.deals.forEach((d) => {
        if (
          d.type == "FOMENTATION" &&
          (d.status == "CREATED" || d.status == "STARTED")
        ) {
          fomentos.push(d);
        }
      });
      if (fomentos.length == 1) {
        this.next(fomentos?.[0]?.id);
      }
    }
    if (util.isEmpty(this.debits)) {
      this.items[0].active = false;
      this.items[1].active = true;
      if (this.agreements().length == 1) {
        this.$emit("loading", false);
      }
    }
    if (!store.getters["negotiation/getConfiguration"].objects) {
      try {
        await this.ActionsConfiguration();
      } catch (error) {
        console.error("Não foi possível buscar as configurações.", error);

        let eventId = Sentry.captureException(error);
        this.$router
          .push({ name: "Error", query: { id: eventId } })
          .catch((failure) => {
            this.skipIfNavigationFailureIsGeneric(failure);
          });

        return;
      }
    }
    setTimeout(() => {
      this.$emit("loading", false);
      this.$vuetify.goTo(0, { duration: 0 });
    }, 300);
    // this.resolveRouteContinue()
  },
  methods: {
    ...mapActions("pluralCheck", ["setDealsLength"]),
    ...mapActions("negotiation", ["ActionsConfiguration", "getPrePayments"]),
    ...mapMutations("negotiation", {
      setSelectedDeal: "setSelectedDeal",
      setLoadingDeals: "setLoadingDeals",
    }),
    ...mapMutations("authentication", ["setFooter"]),
    ...mapMutations("home", ["setPannel"]),
    isOperationsValueVisible(proposal) {
      return Proposal.isOperationsValueVisible(proposal);
    },
    isPaymentMethodVisible(proposal) {
      if (!this.showPaymentMethod) return false;
      return Proposal.isPaymentMethodVisible(proposal);
    },
    dontShowDiscount(deal) {
      let configPrefix = util.getPrefix(deal.id);
      if (!configPrefix) return false;
      let config =
        util.getConfig()[configPrefix + "dont_show_discount_on_deals"] ?? "0";
      return config == 1;
    },
    openTab(id) {
      this.items.forEach((item) => {
        if (item.id == id) item.active = true;
        else item.active = false;
      });
    },
    next(dealId, params = {}) {
      const selectedDeal = this.deals.find((deal) => deal.id == dealId);
      if (selectedDeal.abortedCause || !Deal.isNegotiable(selectedDeal)) {
        this.agreementDetails(selectedDeal);
        return;
      }
      const redirects = {
        STARTED: {
          route: "ProposalStatus",
          id: selectedDeal.active_proposal?.id ?? dealId,
        },
        AGREED: {
          route: "HomeActiveAgreement",
          id: selectedDeal.active_proposal?.id,
        },
      };
      const redirectTo =
        redirects[selectedDeal.active_proposal?.status]?.route ??
        "NegotiationDeal";
      this.$router
        .replace({
          name: redirectTo,
          query: {
            id: window.btoa(redirects[selectedDeal.status]?.id ?? dealId),
          },
          params: params,
        })
        .catch((failure) => {
          this.skipIfNavigationFailureIsGeneric(failure);
        });
    },
    async agreementDetails(deal) {
      this.setSelectedDeal(deal);
      if (deal) {
        const proposalId = () => {
          return (
            deal.proposals?.find(
              (proposal) =>
                proposal.payment_method == "PARCELE_JA" &&
                proposal.status == "CONFIRMED"
            )?.id ||
            deal.active_proposal_id ||
            undefined
          );
        };
        if (["STARTED", "WAITING"].indexOf(deal.status) != -1) {
          if (deal.active_proposal.parcele_ja_proposal) {
            this.dialogParceleJaAgreementDetails = true;
            this.deal = deal;
            await this.getPrePayments(
              deal.active_proposal.parcele_ja_proposal_id
            );
            this.pannel = 1;
            return;
          }
          if (deal.waiting_cause == "EXISTING_PROMISE") {
            this.deal = deal;
            this.dialogDetailsAgreement = true;
          } else {
            if (!deal.active_proposal_id) return;
            this.$router
              .push({
                name: "ProposalStatus",
                query: { id: window.btoa(proposalId()) },
              })
              .catch((failure) => {
                this.skipIfNavigationFailureIsGeneric(failure);
              });
          }
        } else if (["AGREED"].indexOf(deal.status) != -1) {
          if (
            deal.active_proposal.parcele_ja_proposal &&
            ![2, 5].includes(deal.prePayment.prepayment_status_code)
          ) {
            this.dialogParceleJaAgreementDetails = true;
            this.deal = deal;
            await this.getPrePayments(
              deal.active_proposal.parcele_ja_proposal_id
            );
            return;
          } else if (!proposalId()) return;
          this.$router
            .push({
              name: "HomeActiveAgreement",
              query: { id: window.btoa(proposalId()) },
            })
            .catch((failure) => {
              this.skipIfNavigationFailureIsGeneric(failure);
            });
        } else if (["RESOLVED"].indexOf(deal.status) != -1) {
          this.deal = deal;
          this.dialogDetailsAgreement = true;
        } else if (
          ["ABORTED"].includes(deal.status) &&
          [
            "ACTIVE_AGREEMENT",
            "PAYMENT_EXPIRED",
            "DOWN_PAYMENT_EXPIRED",
            "INTERRUPTED",
          ].includes(deal.aborted_cause)
        ) {
          this.deal = deal;
          this.dialogDetailsAgreement = true;
        }
      }
    },
    maxDebit(operations) {
      let max = 0;
      let array = operations.filter((item) => {
        if (!max) max = item.debit_value;
        return max <= item.debit_value;
      });
      return array?.[0] || 0;
    },
    /** Retorna o valor total da dívida baseado nas operações ativas da negociação. */
    totalDebit(deal) {
      if (!deal) {
        return 0;
      }

      return Deal.calculateDebts(deal.operations);
    },
    // Retorna uma proposta à vista
    incashProposal(deal) {
      return Deal.createIncashProposal(deal);
    },
    typeTega(deal) {
      if (deal.status == "RESOLVED") {
        return "green";
      } else if (deal.status == "ABORTED") {
        if (
          deal.aborted_cause == "PAYMENT_EXPIRED" ||
          deal.aborted_cause == "DOWN_PAYMENT_EXPIRED"
        ) {
          return "grey";
        } else if (deal.aborted_cause == "ACTIVE_AGREEMENT") {
          return "grey";
        } else if (deal.aborted_cause == "INTERRUPTED") {
          return "brown";
        }
      } else if (
        deal.active_proposal &&
        deal.active_proposal.status == "WAITING"
      ) {
        if (deal.waiting_cause == "EXISTING_PROMISE") {
          return "grey";
        } else {
          return "grey";
        }
      } else if (["STARTED", "WAITING"].indexOf(deal.status) != -1) {
        if (deal.waiting_cause != "EXISTING_PROMISE") return "grey";
      }

      let payments = this.pendingPayments(deal);

      if (payments.length == 0) {
        return "";
      }

      let dueDate = new Date(payments?.[0]?.due_date);
      let difference = dueDate.getTime() - new Date().getTime();
      let days = Math.ceil(difference / (1000 * 3600 * 24));

      if (days < 0) {
        return "red";
      } else if (days <= 10) {
        return "orange";
      } else if (days > 10 && payments.length > 1) {
        return "yellow";
      } else {
        return "blue";
      }
    },
    msgBold(deal) {
      let atualization = deal?.tags_v2?.some((tag) => tag.key == "atualizacao");
      if (!atualization)
        atualization = deal?.tags?.split(",").includes("atualizacao");

      if (
        deal.status == "RESOLVED" &&
        (atualization || deal.agreement_type == "REFINANCE")
      ) {
        return "<b>Boleto pago</b>";
      } else if (deal.status == "RESOLVED") {
        if (deal.agreement_type == "PAY_OFF" || deal.agreement_type == null) {
          return "Acordo";
        } else if (deal.agreement_type == "REGULARIZE") {
          return "Parcela";
        }
      } else if (deal.status == "ABORTED") {
        if (
          deal.aborted_cause == "PAYMENT_EXPIRED" ||
          deal.aborted_cause == "DOWN_PAYMENT_EXPIRED"
        ) {
          return "<b>Descumprimento</b>";
        } else if (deal.aborted_cause == "ACTIVE_AGREEMENT") {
          return "Outro";
        } else if (deal.aborted_cause == "INTERRUPTED") {
          if (deal.agreement_type == "PAY_OFF") {
            return "Acordo";
          } else if (deal.agreement_type == "REGULARIZE") {
            return "Parcela";
          }
        }
      } else if (
        deal.active_proposal &&
        deal.active_proposal.status == "WAITING"
      ) {
        if (deal.waiting_cause == "EXISTING_PROMISE") {
          return "Outro";
        } else {
          return "<b>Em análise</b>";
        }
      } else if (
        ["STARTED", "WAITING"].indexOf(deal.status) != -1 &&
        deal.waiting_cause != "EXISTING_PROMISE"
      ) {
        return "<b>Em análise</b>";
      }

      let payments = this.pendingPayments(deal);

      if (payments.length == 0) {
        return "";
      }

      let dueDate = new Date(payments?.[0]?.due_date);
      let difference = dueDate.getTime() - new Date().getTime();
      let days = Math.ceil(difference / (1000 * 3600 * 24));

      if (days < 0) {
        return "<b>Em atraso</b>";
      } else if (days <= 10) {
        return "<b>Pagamento pendente</b>";
      } else if (days > 10 && payments.length > 1) {
        return "<b>Está em dia</b>";
      } else {
        return "<b>Últimas parcelas</b>";
      }
    },
    msg(deal) {
      let atualization = deal?.tags_v2?.some((tag) => tag.key == "atualizacao");
      if (!atualization)
        atualization = deal?.tags?.split(",").includes("atualizacao");

      if (deal.status == "RESOLVED" && atualization) {
        return "";
      } else if (deal.status == "RESOLVED") {
        if (deal.agreement_type == "PAY_OFF" || deal.agreement_type == null) {
          return "<b> quitado</b>";
        } else if (deal.agreement_type == "REGULARIZE") {
          return "<b> quitada</b>";
        }
      } else if (deal.status == "ABORTED") {
        if (
          deal.aborted_cause == "PAYMENT_EXPIRED" ||
          deal.aborted_cause == "DOWN_PAYMENT_EXPIRED"
        ) {
          return " de acordo";
        } else if (deal.aborted_cause == "ACTIVE_AGREEMENT") {
          return "<b> acordo ativo</b>";
        } else if (deal.aborted_cause == "INTERRUPTED") {
          if (deal.agreement_type == "PAY_OFF") {
            return "<b> interrompido</b>";
          } else if (deal.agreement_type == "REGULARIZE") {
            return "<b> interrompida</b>";
          }
        }
      } else if (
        deal.active_proposal &&
        deal.active_proposal.status == "WAITING"
      ) {
        if (deal.waiting_cause == "EXISTING_PROMISE") {
          return " acordo ativo";
        } else {
          return "";
        }
      } else if (
        ["STARTED", "WAITING"].indexOf(deal.status) != -1 &&
        deal.waiting_cause != "EXISTING_PROMISE"
      ) {
        return "";
      }

      let payments = this.pendingPayments(deal);

      if (payments.length == 0) {
        return "";
      }

      let dueDate = new Date(payments?.[0]?.due_date);
      let difference = dueDate.getTime() - new Date().getTime();
      let days = Math.ceil(difference / (1000 * 3600 * 24));

      if (days < 0) {
        return "Seu boleto está esperando";
      } else if (days <= 10) {
        return "Fique atento!";
      } else if (days > 10 && payments.length > 1) {
        return "Continue assim!";
      } else {
        return "Está quase lá!";
      }
    },
    pendingPayments(deal) {
      return deal.payments.filter((i) => i.status == "PENDING");
    },

    verifyExclusiveCreditor(deal) {
      if (!deal || !this.exclusiveCreditor) return true;
      return deal.creditor_id == this.exclusiveCreditor;
    },
    agreements(showAllAgrements) {
      let deals = this.deals.filter((deal) => {
        return !Deal.isNegotiable(deal);
      });
      let filteredDeals = [];
      this.sortDeals(deals);
      if (!showAllAgrements) {
        filteredDeals = deals.filter(
          (deal) => !["ABORTED", "RENEGOTIATED"].includes(deal.status)
        );
      }
      if (this.showAllAgreements) return deals;
      return filteredDeals;
    },
    oldAgreements(showAllAgrements) {
      let deals = this.deals.filter((deal) => {
        return !Deal.isNegotiable(deal);
      });
      let filteredDeals = [];
      this.sortDeals(deals);
      if (!showAllAgrements) {
        filteredDeals = deals.filter((deal) =>
          ["ABORTED", "RENEGOTIATED"].includes(deal.status)
        );
      }
      return filteredDeals;
    },
    otherAgreements() {
      return this.oldAgreements().length;
    },
    /** Retorna o cedente da negociação. */
    getAssignor(deal) {
      return Deal.getAssignor(deal);
    },
    /** Retorna o texto do botão para selecionar a dívida. */
    getSelectDealButtonText(deal) {
      if (!deal) {
        return;
      }

      if (deal.type == "FOMENTATION") {
        return "Acessar";
      }

      // Exibimos apenas a opção de desconto para o acordo à vista
      // Por isso o texto "Mais opções de pagamento" não se aplica para esse caso

      return "Negociar";
    },
    /**
     * Indica se o valor total da dívida será exibido.
     * O valor total da dívida só deve ser exibido quando existir desconto para a dívida.
     */
    isDebitValueVisible(deal) {
      let debts = Deal.calculateDebts(deal.operations);
      let proposal = this.incashProposal(deal);
      if (!proposal) {
        return true;
      }
      return debts != proposal.down_payment;
    },
    sortDeals(deals) {
      deals.sort((dealA, dealB) => {
        if (dealA.status == "AGREED") return -1;
        if (dealA.status !== "AGREED") return 1;
        return (
          moment(dealB.updated_at).toDate() - moment(dealA.updated_at).toDate()
        );
      });
    },
    calculateDiscountRate(deal) {
      let discount = this.incashProposal(deal).discount_rate * 100;
      return discount > 99 ? Math.floor(discount) : Math.round(discount);
    },
    doShowAssignorBand(deal) {
      let configPrefix = util.getPrefix(deal.id);

      if (!configPrefix) return false;

      let showAssignor = configPrefix
        ? util.getConfig()[`${configPrefix}show_assignor_logo`]
        : 0;

      if (showAssignor == 1 && this.getAssignor(deal)) return true;

      return false;
    },
    normalizeText(textToFormat) {
      if (!textToFormat) return false;
      return textToFormat
        .normalize("NFD")
        .replace(/[^a-zA-Z\s]/g, "")
        .split(" ")
        .join("")
        .toLowerCase();
    },
    getAssignorLogo(deal) {
      let assignorName = this.normalizeText(
        this.getAssignor(deal).toLowerCase()
      );
      if (!deal.operations || !assignorName || !this.getCreditorProfiles)
        return deal.creditor.profile.logo_url;

      let urlLogo = "";

      let assignorProfile = this.getCreditorProfiles.find((profile) => {
        let profileName = this.normalizeText(profile.long_name);
        if (assignorName.includes(profileName)) {
          urlLogo = profile.logo_url;
          return profile;
        }
      });

      deal.operations[0].assignorProfile = assignorProfile;
      return urlLogo;
    },
    setView(panel) {
      const continueTo = this.$route.query.continue ?? undefined;
      const views = {
        0: "dividas",
        1: "acordos",
      };
      this.$router
        .replace({
          query: {
            ...this.$route.query,
            view: views[panel],
            continue: continueTo,
          },
        })
        .catch(() => {});
    },
    setPanel(panel) {
      const views = {
        dividas: 0,
        acordos: 1,
      };
      this.panel = views[panel];
    },
    isDealToContinueSynced() {
      if (!this.$route.query.continue) return;
      let continueTo = new URLSearchParams(
        this.$route.query.continue?.split("?")[1]
      );
      let dealId = window.atob(continueTo.get("id")) || undefined;
      if (dealId) {
        return this.deals.some((deal) => deal.id == dealId);
      }
    },
    resolveRouteContinue() {
      let continueTo = null;
      let deals = Deal.filterValidDeals(this.deals);
      if (this.$route.query.continue) {
        continueTo = decodeURIComponent(this.$route.query.continue);
        continueTo = continueTo.replace(window.location.origin, "");
      }

      if (continueTo) {
        this.$router.replace(continueTo).catch((e) => {});
        return;
      }
      if (this.$route.query.view) {
        this.setPanel(this.$route.query.view);
      }
      if (deals.length === 1) {
        this.debits.length > 0 ? (this.panel = 0) : (this.panel = 1);
        this.next(deals?.[0].id);
      }
      if (deals?.length > 1) {
        this.debits.length > 0 ? (this.panel = 0) : (this.panel = 1);
      }
      if (this.$route.query.deal_id) {
        let dealReferentToQueryParam = this.deals.find(
          (deal) => deal.id == window.atob(this.$route.query.deal_id)
        );
        this.agreementDetails(dealReferentToQueryParam);
      }
    },
    downloadBoleto({ paymentId, dealId = null }) {
      createTagManagerEvent({
        event: "dealDownloadedBoleto",
      });
      serviceEvent
        .create({
          deal_id: dealId ?? this.deal?.id,
          detail: "Pagamento: " + paymentId,
          message: "Boleto baixado",
          type: "BOLETO_DOWNLOADED",
        })
        .catch((error) => {
          console.error("Não foi possível criar o evento.", error);
        });
      servicePayment
        .sendBoleto(paymentId)
        .catch((error) => console.error(error));
    },
    openBillAlert(payment) {
      this.$refs.DialogBillAlert?.openDialog(payment);
    },
  },
  computed: {
    ...mapGetters("brokenDeal", {
      exclusiveCreditor: "getExclusiveCreditor",
    }),
    ...mapGetters("home", {
      pannelGetter: "pannel",
    }),
    ...mapGetters("negotiation", {
      loadingPriorityTask: "getLoadingPriorityTask",
      loadingTasks: "getLoadingTasks",
      deals: "getDeals",
      creditorsToSync: "getCreditorsToSync",
      getLoadingDeals: "getLoadingDeals",
    }),
    ...mapGetters("creditor", ["getCreditorProfiles"]),
    ...mapGetters("pluralCheck", {
      hasManyDeals: "hasManyDeals",
      hasManyAgreements: "hasManyAgreements",
    }),
    panel: {
      get: function () {
        return this.pannelGetter;
      },
      set: function (value) {
        this.setPannel(value);
      },
    },
    debits() {
      return this.deals.filter((deal) => {
        return Deal.isNegotiable(deal) && this.verifyExclusiveCreditor(deal);
      });
    },
    loadingDeals: {
      get() {
        return this.getLoadingDeals;
      },
      set(value) {
        this.setLoadingDeals(value);
      },
    },
    getDebitsText() {
      let uniqueCreditorDeals = [];
      let debits = this.debits;
      debits.forEach((debit) => {
        if (
          !uniqueCreditorDeals.some(
            (deal) => deal.creditor.profile.key == debit.creditor.profile.key
          )
        ) {
          uniqueCreditorDeals.push(debit);
        }
      });
      let creditorsFound = uniqueCreditorDeals.length;
      let debitsFound = debits.length;
      let singleDebitCreditorProfile = debits?.[0].creditor.profile;
      let noOrNa = singleDebitCreditorProfile.gender == "MALE" ? "no" : "na";
      if (debits.length > 1 && creditorsFound == 1) {
        1;
        return `Localizamos ${debitsFound} dívidas ${noOrNa} <b>${singleDebitCreditorProfile.long_name}</b>, confira mais detalhes a seguir`;
      }
      if (debits.length == 1) {
        return `Localizamos a seguinte dívida ${noOrNa} <b>${singleDebitCreditorProfile.long_name}</b>, confira mais detalhes a seguir.`;
      }
      return `Localizamos dívidas com <b> ${creditorsFound} credores</b>, confira mais detalhes a seguir.`;
    },
  },
  watch: {
    panel: {
      handler(newValue, oldValue) {
        this.setView(newValue);
        if (typeof newValue != "number") {
          this.panel = oldValue == 1 ? 1 : 0;
        }
      },
    },
    deals: {
      deep: true,
      handler() {
        this.setDealsLength(this.deals);
        if (this.isDealToContinueSynced()) {
          this.resolveRouteContinue();
        }
      },
    },
    "this.$route.query.view": {
      deep: true,
      handler(newValue) {
        this.setView(newValue);
      },
    },
    loadingDeals(newValue) {
      if (newValue === false) {
        this.resolveRouteContinue();
      }
    },
  },
};
</script>
<style lang="scss">
.deals-container {
  .v-btn:before {
    background-color: unset !important;
  }
}
</style>

<style scoped lang="scss">
.qj-bottom-nav {
  span {
    font-size: 14px;
    margin-top: 4px;
  }
}
.qj-body {
  justify-content: center;
  > div {
    flex-grow: 1;
  }
}
.deals-debts-pannels {
  width: calc(100% + 32px) !important;

  &__pannel {
    button {
      justify-content: center;
      font-weight: 600;
      font-size: 14px;
      color: #00e58d;
      background-color: white;
      .v-expansion-panel-header__icon {
        margin-left: 10px !important;
      }
    }
    &::before {
      box-shadow: none;
      // border: none;
    }
  }
}
.tab {
  overflow: hidden;
  background-color: #e5e5e5;
  border-radius: 100px;
  margin-top: 7px;
}

.tab button {
  width: 50%;
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 13px 0;
  /* transition: 0.3s; */
  font-size: 14px;
  line-height: 17px;
}

.tab button.active {
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  margin: 5px;
  padding: 8px 0;
  width: calc(50% - 10px);
  font-weight: bold;
}

.tabcontent {
  margin-top: 10px;
  display: none;
}

.card-logo {
  padding: 12px 12px 7px 12px !important;
}

.card-title-deal {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  @media (max-width: 320px) {
    font-size: 11px;
  }
}

.card-p {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
}

.card-a {
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  color: #00606a;
}

.card-details {
  text-align: right;
}

.card-value-deal {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: right;
  color: #212121;
  @media (max-width: 320px) {
    font-size: 12px;
  }
}

.card-value-deal-more-discount {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;
  text-align: right;
  color: #212121;
  @media (max-width: 320px) {
    font-size: 14px;
  }
}

.card-description-discount {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: right;
  color: #212121;
}

.card-btn div {
  margin-right: auto;
}

.card-tag-state-wake-up {
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  padding: 7px 13px;
  border-radius: 100px;
  width: max-content;
  margin-left: auto;
}
.card-tag-state-wake-up b {
  color: inherit;
}
.card-tag-state-wake-up.tag-green {
  background: #cafce9;
  color: #000000;
}
.card-tag-state-wake-up.tag-yellow {
  background: #f9fcca;
  color: #b87f0e;
}
.card-tag-state-wake-up.tag-blue {
  background: #d1e6fc;
  color: #3876b5;
}
.card-tag-state-wake-up.tag-orange {
  background: #ffe7d8;
  color: #ff8533;
}
.card-tag-state-wake-up.tag-red {
  background: #ffeaea;
  color: #b83636;
}
.card-tag-state-wake-up.tag-grey {
  background: #e5e5e5;
  color: #000000;
}
.card-tag-state-wake-up.tag-brown {
  background: #efebe9;
  color: #3e2723;
}

.qj-card-msg {
  padding: 10px;
  background: #f6f6f6;
  border-radius: 7px;
}

.qj-operation-assignor {
  font-size: 16px;
  font-weight: 500;
}

.assignor-band {
  background-color: #f6f6f6;
  position: absolute;
  width: 94%;
  border-radius: 7px;
  padding: 7px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span:last-child {
    display: flex;
    align-items: center;
  }
  img {
    min-height: 16px;
    max-height: 20px;
  }
  @media (max-width: 320px) {
    width: 90%;
    line-height: 1.2;
    align-items: center;
    span:last-child {
      max-width: 75%;
      text-align: end;
      justify-content: end;
    }
  }
}

.no-bg {
  &::before {
    background-color: transparent;
  }
}
.qj-tab {
  font-size: 14px;
  font-weight: 600 !important;
}
.qj-tabs {
  @media (min-width: 600px) {
    width: 520px !important;
    margin-left: -12px;
  }
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}
.qj-tag-discount {
  margin-top: 2.5px;
}
</style>
