<template>
  <div>
    <div v-if="loadingPriorityTask" class="qj-card-outline">
      <v-row>
        <v-col><v-skeleton-loader type="chip"></v-skeleton-loader></v-col>
        <v-spacer></v-spacer>
        <v-col class="mt-2" cols="auto"
          ><v-skeleton-loader
            height="20"
            width="150"
            type="text"
          ></v-skeleton-loader
        ></v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-skeleton-loader
            height="30"
            width="100"
            type="image"
            class="mb-2 border-4"
          ></v-skeleton-loader>
          <v-skeleton-loader
            height="20"
            width="100"
            type="text"
          ></v-skeleton-loader>
          <v-skeleton-loader
            height="20"
            width="110"
            type="text"
          ></v-skeleton-loader>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex align-end flex-column">
          <v-skeleton-loader
            height="20"
            width="80"
            type="text"
          ></v-skeleton-loader>
          <v-skeleton-loader
            height="20"
            width="100"
            type="image"
            class="mb-2 border-4"
          ></v-skeleton-loader>
          <v-skeleton-loader
            height="20"
            width="60"
            type="text"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="auto">
          <v-skeleton-loader
            height="36"
            width="220"
            type="image"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div v-if="loadingTasks" class="qj-card-outline">
      <v-row>
        <v-col><v-skeleton-loader type="chip"></v-skeleton-loader></v-col>
        <v-spacer></v-spacer>
        <v-col class="mt-2" cols="auto"
          ><v-skeleton-loader
            height="20"
            width="150"
            type="text"
          ></v-skeleton-loader
        ></v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-skeleton-loader
            height="30"
            width="100"
            type="image"
            class="mb-2 border-4"
          ></v-skeleton-loader>
          <v-skeleton-loader
            height="20"
            width="100"
            type="text"
          ></v-skeleton-loader>
          <v-skeleton-loader
            height="20"
            width="110"
            type="text"
          ></v-skeleton-loader>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex align-end flex-column">
          <v-skeleton-loader
            height="20"
            width="80"
            type="text"
          ></v-skeleton-loader>
          <v-skeleton-loader
            height="20"
            width="100"
            type="image"
            class="mb-2 border-4"
          ></v-skeleton-loader>
          <v-skeleton-loader
            height="20"
            width="60"
            type="text"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="auto">
          <v-skeleton-loader
            height="36"
            width="220"
            type="image"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loadingTasks: {
      type: Boolean,
      default: false,
    },
    loadingPriorityTask: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>