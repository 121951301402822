<template>
  <div class="text-center">
    <v-dialog v-model="dialogProposalConditions" scrollable persistent
      width="500">
      <v-card>
        <v-card-text>
          <div v-if="!conditions" class="text-center">
            <em>Condições não encontradas</em>
          </div>

          <div v-else v-html="conditions"></div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="rounded-lg qj-btn qj-bg-primary"
            @click="close">
            Entendi
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "@/store";

export default {
  props: [
    "dialogProposalConditions",
    "proposalId",
  ],
  computed: {
    conditions() {
      if (!this.proposalId) {
        return null;
      }

      let conditions = store.getters["proposal/getConditions"](this.proposalId);
      return conditions ? conditions.conditions : null;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
