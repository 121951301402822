<template>
  <div class="simulation-container" v-if="dialog">
    <v-fade-transition>
      <div v-show="dialog" class="qj-bg-dialog" @click="close"></div>
    </v-fade-transition>
    <v-dialog
      fullscreen
      scrollable
      persistent
      transition="dialog-bottom-transition"
      v-model="dialog"
    >
      <v-card id="dialog-simulation">
        <v-card-title>
          <v-col cols="12 pt-0" style="position: relative">
            <h2 class="qj-h3 text-center">Simular outros valores</h2>
            <v-btn icon class="qj-btn-close-dialog" @click="close">
              <v-icon>far fa-times-circle</v-icon>
            </v-btn>
          </v-col>
        </v-card-title>
        <v-card-text>
          <div class="debts-info d-flex flex-column">
            <div class="debts-info__debt d-flex justify-space-between mb-4">
              <span>Saldo devedor:</span>
              <span>
                <b>{{ totalDebits | money }}</b>
              </span>
            </div>
            <div class="debts-info__proposal d-flex justify-space-between mb-2">
              <!-- <span>Proposta à vista:</span>
              <div class="text-right">
                <span>
                  <b>{{ incashProposal.down_payment | money }}</b>
                </span>
                <div
                  v-if="incashProposal.discount_rate > 0.01"
                  class="qj-tag-discount mt-1 mr-auto"
                >
                  <img src="@/assets/img/icons/sale.svg" alt="sale icon" /><span
                    >{{ (incashProposal.discount_rate * 100).toFixed(0) }}% de
                    desconto
                  </span>
                </div>
              </div> -->
            </div>
            <v-divider></v-divider>
          </div>
          <v-form v-model="validForm" @submit="preventSubmit($event)">
            <v-row v-if="simulatorIs('INSTALLMENT_VALUE')">
              <v-col cols="12" class="pb-0">
                <p class="mb-2 font-weight-bold black--text">
                  Quanto você pode pagar por mês?
                </p>
                <v-text-field
                  dense
                  outlined
                  required
                  :rules="rules.installment"
                  single-line
                  data-hj-allow
                  type="search"
                  inputmode="numeric"
                  ref="inputDownPayment"
                  autocomplete="off"
                  class="qj-input qj-input-down-payment"
                  v-money="money"
                  v-model="installmentValue"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              v-else-if="simulatorIs('DOWN_PAYMENT_AND_INSTALLMENT_COUNT')"
            >
              <v-col cols="12" class="pb-0">
                <p class="mb-2 font-weight-bold black--text">
                  Quanto você pode pagar de entrada?
                </p>
                <v-text-field
                  dense
                  outlined
                  required
                  :rules="rules.downPayment"
                  single-line
                  data-hj-allow
                  type="search"
                  inputmode="numeric"
                  ref="inputDownPayment"
                  autocomplete="off"
                  class="qj-input qj-input-down-payment installment-count mb-4"
                  v-money="money"
                  v-model="downPaymentValue"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <p class="mb-2 font-weight-bold black--text">
                  Em quantas parcelas você pode pagar o restante?
                </p>
                <v-select
                  dense
                  outlined
                  required
                  single-line
                  data-hj-allow
                  type="search"
                  inputmode="numeric"
                  ref="inputDownPayment"
                  autocomplete="off"
                  class="qj-input qj-input-down-payment qj-simulation-param-value__v-select mb-4"
                  :items="installmentsCount"
                  v-model="installmentCount"
                  hide-details=""
                >
                  <template v-slot:item="{ item }">
                    <span
                      class="installment-count qj-simulation-param-value__v-select"
                      >{{ item }}x</span
                    >
                  </template>
                  <template v-slot:selection="{ item }">
                    <span
                      class="installment-count qj-simulation-param-value__v-select"
                      >{{ item }}x</span
                    >
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row
              v-else-if="simulatorIs('DOWN_PAYMENT_AND_INSTALLMENT_VALUE')"
            >
              <v-col cols="12" class="pb-0">
                <p class="mb-2 font-weight-bold black--text">
                  Quanto você pode pagar de entrada?
                </p>
                <v-text-field
                  dense
                  outlined
                  required
                  :rules="rules.downPayment"
                  single-line
                  data-hj-allow
                  type="search"
                  inputmode="numeric"
                  ref="inputDownPayment"
                  autocomplete="off"
                  class="qj-input qj-input-down-payment"
                  v-money="money"
                  v-model="downPaymentValue"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <p class="mb-2 font-weight-bold black--text">
                  Quanto você pode pagar mensalmente?
                </p>
                <div class="d-flex">
                  <v-text-field
                    dense
                    :rules="rules.installment"
                    outlined
                    required
                    single-line
                    data-hj-allow
                    type="search"
                    inputmode="numeric"
                    ref="inputDownPayment"
                    autocomplete="off"
                    class="qj-input qj-input-down-payment"
                    v-model="installmentValue"
                    v-money="money"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
            <v-row v-else-if="simulatorIs('SIMULATIONS_LIST')"></v-row>
            <v-row justify="center"
              ><v-btn
                :disabled="!validForm || loadingSimulation"
                @click="simulateProposal()"
                height="40px"
                class="qj-btn rounded-lg qj-bg-primary ml-2"
              >
                Simular
              </v-btn></v-row
            >
          </v-form>
          <v-row
            align="center"
            justify="center"
            v-if="simulatedProposal || loadingSimulation"
            class="mt-4 flex-column"
          >
            <v-alert
              type="warning"
              icon=" "
              rounded
              v-if="simulatedProposal?.status == 'REJECTED'"
            >
              <div class="qj-proposal-status-rejected">
                <div v-if="simulatedProposal?.rejected_cause == 'EXCEEDS'">
                  Sua proposta excede o número máximo de parcelas.<br />
                  Evite juros melhorando sua entrada ou aumente sua parcela!
                </div>

                <div
                  v-if="simulatedProposal?.rejected_cause == 'MAX_DOWN_PAYMENT'"
                >
                  Você informou um valor de entrada muito alto!
                </div>

                <div
                  v-if="simulatedProposal?.rejected_cause == 'MIN_DOWN_PAYMENT'"
                >
                  Para esse valor de parcela, sua proposta precisa ter uma
                  entrada maior.
                </div>

                <div
                  v-if="simulatedProposal?.rejected_cause == 'MIN_INSTALLMENT'"
                >
                  Sua proposta ficou com uma parcela muito baixa.<br />
                  Se sua entrada está alta tente diminuir um pouco, se não,
                  aumente o valor da sua parcela.
                </div>

                <div
                  v-if="simulatedProposal?.rejected_cause == 'NO_DOWN_PAYMENT'"
                >
                  Sua proposta precisa ter uma entrada mínima.<br />
                  Ofereça uma entrada e parcele o restante do saldo!
                </div>

                <div
                  v-if="simulatedProposal?.rejected_cause == 'NO_INSTALLMENT'"
                >
                  Você não informou um valor para as parcelas.
                </div>

                <div v-if="simulatedProposal?.rejected_cause == 'UNVIABLE'">
                  Sua proposta não foi aceita.<br />
                  Evite juros melhorando sua entrada ou aumente sua parcela!
                </div>

                <div v-if="simulatedProposal?.rejected_cause == null">
                  Não foi possível simular sua proposta.
                </div>
              </div>
            </v-alert>
            <v-col v-else class="py-2 qj-card-outline primaryLight">
              <ProposalCard
                v-if="simulatedProposal || loadingSimulation"
                :deal="deal"
                :proposal="simulatedProposal"
                :doShowNextButton="true"
                :showAlias="true"
                @next="next(simulatedProposal)"
                :loading="loadingSimulation"
                :showExpiresIn="false"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" v-if="deal.proposals_history?.length >= 1">
              <p class="qj-p mb-0 text-center font-weight-medium">
                Histórico de simulações
              </p>
              <v-radio-group
                hide-details
                class="ma-0 pa-0"
                :key="proposal.id"
                v-model="idHistory"
                v-for="proposal in deal.proposals_history"
              >
                <v-radio class="qj-radio" :value="proposal.id">
                  <template v-slot:label>
                    <div
                      class="qj-radio-item"
                      :style="proposal.id == idHistory ? 'height: 75px;' : ''"
                    >
                      <div class="d-flex">
                        <div>
                          <span v-if="proposal.down_payment > 0">
                            <b>{{ proposal.down_payment | currency }}</b>
                            +
                          </span>
                          <b>{{ proposal.installment_1 | money }}</b>
                          mensais em
                          <b>{{ proposal.installments_count_1 }}</b>
                          vezes
                        </div>
                        <div
                          v-if="proposal?.discount_rate > 0.01"
                          class="qj-tag-discount d-flex align-center flex-nowrap flex-shrink-0"
                        >
                          <img
                            src="@/assets/img/icons/sale.svg"
                            alt="sale icon"
                          /><span
                            >{{ (proposal?.discount_rate * 100).toFixed(0) }}%
                          </span>
                        </div>
                      </div>
                      <div
                        v-show="proposal.id == idHistory"
                        class="qj-btn-radio mt-2"
                      >
                        <v-btn
                          block
                          class="rounded-lg qj-btn qj-bg-primary"
                          @click="next(proposal)"
                        >
                          <div>Prosseguir</div>
                        </v-btn>
                      </div>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import util from "@/assets/js/util";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Deal from "@/modules/deal";
import ProposalCard from "../components/proposalCard.vue";
import { createTagManagerEvent } from "@/utils";
import { cloneDeep } from "lodash";

export default {
  props: ["dialog", "simulationParams"],
  components: {
    ProposalCard,
  },
  async mounted() {
    this.deal = this.getSelectedDeal;
    if (!this.deal) {
      this.$router.push({ name: "Deals" });
      return;
    }
    if (!this.deal?.proposals_history) this.deal.proposals_history = [];
    try {
      this.mountFieldsValues();
      this.mountFieldsRules();
    } catch (e) {
      console.error(e);
    }
    this.totalDebits = this.totalDebit();
    this.incashProposal = Deal.getIncashProposal(this.deal?.proposals);
  },
  data: () => ({
    deal: null,
    validForm: false,
    loadingProposal: false,
    loadingSimulation: false,
    installmentValue: 0,
    installmentsCount: undefined,
    installmentCount: undefined,
    downPaymentValue: 0,
    selectedProposal: null,
    proposalLoading: false,
    simulatedProposal: undefined,
    idHistory: null,
    rules: {
      downPayment: [],
      installment: [],
    },
    totalDebits: 0,
    incashProposal: {
      down_payment: 0,
    },
    money: {
      prefix: "R$ ",
      decimal: ",",
      thousands: ".",
      precision: 2,
    },
    history: [],
  }),
  computed: {
    ...mapGetters("negotiation", ["getSelectedDeal"]),
  },
  watch: {
    dialog(newValue) {
      if (newValue && !this.simulatedProposal) {
        this.simulateProposal();
      }
    },
  },
  methods: {
    ...mapActions("simulator", ["getSimulationParams", "simulate"]),
    async simulateProposal() {
      const { typeFromCustomerParams, typeFromConfig } = this.simulationType();
      const payload = this.transformPayloadBySimulatorType({
        deal_id: this.deal.id,
        down_payment: this.transformVmoney(this.downPaymentValue) ?? undefined,
        installment_1: this.transformVmoney(this.installmentValue) ?? undefined,
        installment_count_1: this.installmentCount || undefined,
        simulation_type: typeFromCustomerParams ?? typeFromConfig,
      });
      const lastSimulatedProposal = cloneDeep(this.simulatedProposal);
      if (lastSimulatedProposal)
        this.pushProposalToHistory(lastSimulatedProposal);
      this.loadingSimulation = true;
      const simulatedProposal = await this.simulate(payload);
      this.setStimulatedProposal(simulatedProposal);
      this.loadingSimulation = false;
    },
    transformPayloadBySimulatorType(payload) {
      const fieldToExclude = {
        INSTALLMENT_VALUE: ["down_payment", "installment_count_1"],
        DOWN_PAYMENT_AND_INSTALLMENT_VALUE: ["installment_count_1"],
        DOWN_PAYMENT_AND_INSTALLMENT_COUNT: ["installment_1"],
      };
      fieldToExclude[payload.simulation_type].forEach((field) => {
        delete payload[field];
      });
      return payload;
    },
    next(proposal) {
      this.deal.proposals.push(proposal);
      this.$emit("next", proposal);
    },
    pushProposalToHistory(proposal) {
      const proposalAlreadyInHistory = this.deal?.proposals_history?.find(
        (proposalFromHistory) => {
          return (
            proposalFromHistory.discount == proposal.discount &&
            proposalFromHistory.installments_count_1 ==
              proposal.installments_count_1 &&
            proposalFromHistory.installment_1 == proposal.installment_1
          );
        }
      );
      if (proposalAlreadyInHistory || proposal.status != "CREATED") return;
      this.deal?.proposals_history?.unshift(proposal);
    },
    setStimulatedProposal(proposal) {
      this.simulatedProposal = proposal;
    },
    showRejectedMessage(proposal) {
      alert(proposal.rejected_cause);
    },
    mountFieldsRules() {
      this.rules.downPayment = [
        (v) => {
          return (
            this.transformVmoney(v) >= this.simulationParams.min_down_payment.toFixed(2) ||
            "Valor minimo deve ser " +
              this.$options.filters.money(
                this.simulationParams.min_down_payment
              )
          );
        },
        (v) => {
          if (!this.simulationParams.max_down_payment) return true
          return (
            this.transformVmoney(v) <= this.simulationParams.max_down_payment.toFixed(2) ||
            "Valor máximo deve ser " +
              this.$options.filters.money(
                this.simulationParams.max_down_payment
              )
          );
        },
      ];
      this.rules.installment = [
        (v) => {
          return (
            this.transformVmoney(v) >= this.simulationParams.min_installment.toFixed(2) ||
            "Valor minimo deve ser " +
              this.$options.filters.money(this.simulationParams.min_installment)
          );
        },
        (v) => {
          if (!this.simulationParams.max_installment) return true
          return (
            this.transformVmoney(v) <= this.simulationParams.max_installment.toFixed(2) ||
            "Valor máximo deve ser " +
              this.$options.filters.money(this.simulationParams.max_installment)
          );
        },
      ];
    },
    mountFieldsValues() {
      this.installmentValue =
        parseFloat(this.simulationParams.default_installment)
          .toFixed(2)
          .replace(".", ",") ?? 0;
      this.downPaymentValue =
        parseFloat(
          this.simulationParams.default_down_payment ||
           (this.simulationParams.max_down_payment -
              this.simulationParams.min_installment)
        )
          .toFixed(2)
          .replace(".", ",") ?? 0;
      this.installmentsCount = this.createInstallmentCount();
    },
    createInstallmentCount() {
      let installments = [];
      for (let i = 1; i <= this.simulationParams.max_installment_count; i++) {
        installments.push(i);
      }
      this.installmentCount = this.simulationParams.max_installment_count - 1;
      return installments;
    },
    close() {
      createTagManagerEvent({
        event: "simulationClosed",
      });
      this.$emit("close");
    },
    totalDebit() {
      return Deal.calculateDebts(this.deal?.operations);
    },
    simulationType() {
      const configPrefix = util.getPrefix(this.deal?.id);
      const typeFromConfig =
        util.getConfig()[configPrefix + "simulation.type"] ?? null;
      const typeFromCustomerParams =
        this.deal?.customer_params?.simulation_type ?? null;
      return { typeFromCustomerParams, typeFromConfig };
    },
    simulatorIs(type) {
      const { typeFromCustomerParams, typeFromConfig } = this.simulationType();
      return type == typeFromCustomerParams || type == typeFromConfig;
    },
    transformVmoney(value) {
      if (value === undefined) {
        value = this.downPayment;
      }
      if (Number.isInteger(value) && value > 0) {
        value = value.toLocaleString("pt-BR");
      }

      try {
        return parseFloat(
          value.replace("R$ ", "").replace(".", "").replace(",", ".")
        );
      } catch (error) {
        return undefined;
      }
    },
    preventSubmit(event){
      event.preventDefault();
      if (!this.validForm || this.loadingSimulation) return;
      this.simulateProposal();
    }
  },
};
</script>
<style lang="scss">
.qj-radio {
  background: #f6f6f6;
  border-radius: 7px;
  padding: 10px 12px;
  margin-top: 10px;
  align-items: initial;
}
.qj-radio-item {
  width: 100%;
  margin-left: 5px;
  font-style: normal;
  font-size: 14px;
  line-height: 115.9%;
  position: relative;
}
.qj-btn-radio {
  position: absolute;
  left: -30px;
  right: 0px;
}
.v-list-item--active {
  .qj-simulation-param-value__v-select,
  .qj-param-value__v-select span {
    span {
      color: #00e58d !important;
    }
  }
}

.qj-simulation-param-value__v-select,
.qj-param-value__v-select {
  .theme--light.v-icon {
    color: unset !important;
  }
  .v-select__selections {
    align-items: center;
    justify-content: center;
    input {
      display: none !important;
    }
  }
}
.installment-skeleton {
  height: 60px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px 12px;
  div:nth-child(2) {
    flex-grow: 1;
    margin: 0px 8px;
    height: 40px;
    border-radius: 7px;
  }
  div:not(:nth-child(2)) {
    height: 40px;
    width: 40px;
  }
}
.installments-select__menu {
  overflow: auto !important;
  .v-list-item--active {
    background-color: hsl(157, 100%, 45%, 0.13);
  }
}
</style>

<style lang="scss">
.installment-count {
  font-size: 18px !important;
  font-weight: 800 !important;
  text-align: center !important;
  align-self: center !important;
  margin: unset 50%;
  input,
  span {
    text-align: center !important;
  }
}
</style>
