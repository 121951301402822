<template>
  <v-row v-if="dialog" justify="center">
    <v-dialog v-model="dialog" persistent max-width="310">
      <v-card class="text-center">
        <v-card-title class="justify-center pt-3 pb-4">
          <h3 class="qj-h3">Alterar data</h3>
          <v-btn
            icon
            class="qj-btn-close-dialog"
            style="top: 9px; right: 2px"
            @click="close"
          >
            <v-icon>far fa-times-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pl-3 pr-3 pb-3">
          <ul class="qj-list">
            <li
              v-for="day in days"
              :key="day"
              :class="'qj-iten-list' + (day == date ? ' active' : '')"
              @click="setDate(day)"
            >
              {{ day | data }}
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["dialog", "days", "date"],
  methods: {
    close() {
      this.$emit("close");
    },
    setDate(day) {
      this.$emit("setDate", day);
    },
  },
};
</script>

<style scoped>
.qj-list {
  list-style-type: none;
  padding: 0 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  text-align: left;
}
.qj-iten-list {
  padding: 12px 9px;
  border-radius: 7px;
  line-height: initial;
}
.qj-iten-list.active {
  background: #f6f6f6;
  font-weight: 800;
}
</style>
