<template>
  <div>
    <v-fade-transition>
      <div v-show="dialog" class="qj-bg-dialog" @click="close"></div>
    </v-fade-transition>
    <v-dialog
      fullscreen
      scrollable
      persistent
      transition="dialog-bottom-transition"
      v-model="dialog"
    >
      <v-card id="dialog-simulation">
        <v-card-title>
          <v-col cols="12 pt-0" style="position: relative">
            <h2 class="qj-h3 text-center">Simular outros valores</h2>
            <v-btn icon class="qj-btn-close-dialog" @click="close">
              <v-icon>far fa-times-circle</v-icon>
            </v-btn>
          </v-col>
        </v-card-title>
        <v-card-text>
          <div class="debts-info d-flex flex-column">
            <div class="debts-info__debt d-flex justify-space-between mb-4">
              <span>Saldo devedor:</span>
              <span>
                <b>{{ totalDebits | money }}</b>
              </span>
            </div>
            <div class="debts-info__proposal d-flex justify-space-between mb-2">
              <span>Proposta à vista:</span>
              <div class="text-right">
                <span>
                  <b>{{ incashProposal.down_payment | money }}</b>
                </span>
                <div
                  v-if="incashProposal.discount_rate > 0.01"
                  class="qj-tag-discount mt-1 mr-auto"
                >
                  <img src="@/assets/img/icons/sale.svg" alt="sale icon" /><span
                    ><b>{{ (incashProposal.discount_rate * 100).toFixed(0) }}%</b> de
                    desconto
                  </span>
                </div>
              </div>
            </div>
            <v-divider></v-divider>
          </div>

          <div
            v-if="false && simulationParams"
            class="pa-2 my-4"
            style="background: #eee; border: 1px solid #ccc"
          >
            <div>
              Entrada mínima
              <strong>{{ simulationParams.min_down_payment | money }}</strong>
            </div>
            <div>
              Entrada padrão
              <strong>{{
                simulationParams.default_down_payment | money
              }}</strong>
            </div>
            <br />
            <div>
              Parcelas
              <strong>{{ simulationParams.installments_v2?.[0] | money }}</strong>
              até
              <strong>{{
                simulationParams.installments_v2[
                  simulationParams.installments_v2.length - 1
                ] | money
              }}</strong>
            </div>
            <div>
              Parcela mínima
              <strong>{{ simulationParams.min_installment | money }}</strong>
            </div>
            <div>
              Parcela padrão
              <strong>{{
                simulationParams.default_installment | money
              }}</strong>
            </div>
          </div>

          <div v-if="creatingSimulationParams" class="mt-3">
            <v-skeleton-loader
              class="mt-2"
              type="text"
              height="16"
              width="150"
            ></v-skeleton-loader>
            <div class="d-flex align-center mt-2">
              <v-skeleton-loader
                class=""
                type="image"
                width="100%"
                height="40px"
              ></v-skeleton-loader>
              <v-skeleton-loader
                class="ml-4"
                type="image"
                height="36"
                width="100px"
              ></v-skeleton-loader>
            </div>
            <v-skeleton-loader
              class="mt-7"
              type="text"
              height="16"
              width="150"
            ></v-skeleton-loader>
            <div class="d-flex align-center mt-2">
              <v-skeleton-loader
                class=""
                type="image"
                width="100%"
                height="40px"
              ></v-skeleton-loader>
            </div>
            <v-skeleton-loader
              class="mt-7"
              type="image"
              width="100%"
              height="175px"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="mt-8"
              type="image"
              width="100%"
              height="44"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="mt-4"
              type="image"
              width="100%"
              height="44"
            ></v-skeleton-loader>
          </div>

          <div
            v-if="creatingSimulationParamsError"
            class="mt-8 qj-loading-simulation-params-error"
          >
            <div class="qj-p error-message">
              Não foi possível carregar os parâmetros de simulação.
            </div>

            <div class="text-center mt-4">
              <v-btn color="qj-bg-primary" @click="createSimulationParams">
                Tentar novamente
              </v-btn>
            </div>
          </div>

          <v-row
            v-if="simulationParams"
            class="text-center mt-6"
            style="margin-bottom: 18px"
          >
            <!-- Texto referenciando a entrada -->
            <v-col cols="12" class="py-0 text-left" v-if="allowsDownPayment">
              <p
                class="mb-0 qj-p"
                v-if="allowsDownPayment && !doWithoutDownPayment"
                v-show="!forceWithoutDownPayment"
              >
                Valor da entrada
              </p>
            </v-col>
            <!-- Valor da entrada -->
            <v-col
              cols="12"
              class="pb-0 justify-center qj-down-payment"
              v-if="allowsDownPayment && !doWithoutDownPayment"
              v-show="!forceWithoutDownPayment"
            >
              <div class="d-flex qj-down-payment-wrapper align-items-baseline">
                <div class="flex-grow-1">
                  <v-text-field
                    dense
                    outlined
                    required
                    single-line
                    data-hj-allow
                    type="search"
                    inputmode="numeric"
                    ref="inputDownPayment"
                    autocomplete="off"
                    class="qj-input qj-input-down-payment"
                    v-money="money"
                    v-model="downPayment"
                    @keydown="downPaymentChanged"
                    :rules="[rules.downPaymentMin, rules.downPaymentMax]"
                    :disabled="creatingSimulationParams"
                  ></v-text-field>
                </div>

                <div>
                  <v-btn
                    block
                    class="rounded-lg qj-btn qj-bg-secondary-light"
                    @click="startSimulation"
                    :disabled="!isSimulateButtonEnabled"
                  >
                    Calcular
                  </v-btn>
                </div>
              </div>
            </v-col>
            <!-- <v-col 
            cols="12"
              class="pb-0 justify-center qj-down-payment "
            v-else>
            <p
                class="mb-2 qj-p text-left">
              
                Valor da entrada
              </p>
              <div class="d-flex qj-down-payment-wrapper align-items-baseline">
                 
                <div class="flex-grow-1">
                  <v-text-field
                    dense
                    outlined
                    required
                    single-line
                    data-hj-allow
                    type="search"
                    inputmode="numeric"
                    ref="inputDownPayment"
                    autocomplete="off"
                    class=" qj-input-down-payment"
                    v-money="money"
                    :value="simulationParams.min_down_payment || money"
                    @keydown="downPaymentChanged"
                    :rules="[rules.downPaymentMin, rules.downPaymentMax]"
                    :disabled="true"
                  ></v-text-field>
                </div>
              </div>
            </v-col> -->

            <v-col
              cols="12"
              class="pt-2 qj-checkbox-without-payment"
              v-if="allowsDownPayment && askWithoutDownPayment"
            >
              <v-checkbox
                hide-details
                class="qj-checkbox pt-0 mt-0"
                v-model="doWithoutDownPayment"
                @click="simulate()"
              >
                <template v-slot:label>
                  <div class="qj-checkbox-label">Quero fazer sem entrada</div>
                </template>
              </v-checkbox>
            </v-col>

            <!-- Quantidade de parcelas -->
            <v-col cols="12" class="pb-0" v-if="allowsInstallmentsCount">
              <p class="mb-0 qj-p">Quantidade de parcelas</p>
            </v-col>

            <v-col
              cols="12"
              class="pt-2 d-flex justify-space-between"
              v-if="allowsInstallmentsCount"
            >
              <v-btn
                fab
                small
                class="qj-btn qj-bg-secondary-light"
                @click="decreaseInstallmentsCount()"
              >
                <v-icon dark style="color: #000"
                  >mdi-minus-circle-outline
                </v-icon>
              </v-btn>

              <div class="qj-simulation-param-value">
                {{ installmentsCount }}
              </div>

              <v-btn
                fab
                small
                class="qj-btn qj-bg-secondary-light"
                @click="increaseInstallmentsCount()"
              >
                <v-icon dark style="color: #000"
                  >mdi-plus-circle-outline
                </v-icon>
              </v-btn>
            </v-col>

            <!-- Texto referenciando as parcelas -->
            <v-col cols="12" class="py-0 text-left">
              <p class="mb-0 py-0 qj-p">Valor aproximado da parcela</p>
            </v-col>
            <!-- Valor das parcelas -->
            <v-skeleton-loader
              v-if="updatingSimulationParams"
              class="d-flex flex-row installment-skeleton"
              type="heading"
            ></v-skeleton-loader>
            <v-col
              v-else
              cols="12"
              class="pt-2 pb-0 d-flex justify-space-between"
              style="position: relative"
            >
              <v-select
                ref="installentsSelect"
                class="qj-simulation-param-value__v-select my-0 elevation-0"
                v-model="installment"
                :items="simulationParams.installments_v2"
                dense
                outlined
                color="#00e58d"
                :menu-props="{
                  closeOnContentClick: true,
                  contentClass: 'installments-select__menu',
                }"
              >
                <template v-slot:selection="{ item }">
                  <span style="color: rgba(0, 0, 0, 0.87)">{{
                    item | money
                  }}</span>
                </template>
                <template v-slot:item="{ item }">
                  <div class="qj-simulation-param-value__v-select">
                    <span>{{ item | money }}</span>
                  </div>
                </template>
              </v-select>
            </v-col>

            <!-- Mensagem de erro -->
            <v-col v-show="error" cols="12">
              <v-alert type="error" class="qj-alert-error">
                <div>Não foi possível calcular.</div>
                <div>Tente novamente.</div>
              </v-alert>
            </v-col>

            <v-col id="simulations" cols="12" class="pt-0">
              <div v-show="simulating" class="qj-simulating">
                <p class="qj-p">
                  <b>Aguarde um instante...</b><br />
                  estamos calculando o valor.
                </p>
                <v-progress-circular
                  :size="50"
                  class="qj-color-primary"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div
                v-if="
                  !simulating &&
                  resultProposals.length > 0 &&
                  resultProposals?.[0]?.status == 'REJECTED'
                "
                class="qj-proposal-status-rejected"
              >
                <div v-if="resultProposals?.[0]?.rejected_cause == 'EXCEEDS'">
                  Sua proposta excede o número máximo de parcelas.<br />
                  Evite juros melhorando sua entrada ou aumente sua parcela!
                </div>

                <div
                  v-if="
                    resultProposals?.[0]?.rejected_cause == 'MAX_DOWN_PAYMENT'
                  "
                >
                  Você informou um valor de entrada muito alto!
                </div>

                <div
                  v-if="
                    resultProposals?.[0]?.rejected_cause == 'MIN_DOWN_PAYMENT'
                  "
                >
                  Para esse valor de parcela, sua proposta precisa ter uma
                  entrada maior.
                </div>

                <div
                  v-if="resultProposals?.[0]?.rejected_cause == 'MIN_INSTALLMENT'"
                >
                  Sua proposta ficou com uma parcela muito baixa.<br />
                  Se sua entrada está alta tente diminuir um pouco, se não,
                  aumente o valor da sua parcela.
                </div>

                <div
                  v-if="resultProposals?.[0]?.rejected_cause == 'NO_DOWN_PAYMENT'"
                >
                  Sua proposta precisa ter uma entrada mínima.<br />
                  Ofereça uma entrada e parcele o restante do saldo!
                </div>

                <div
                  v-if="resultProposals?.[0]?.rejected_cause == 'NO_INSTALLMENT'"
                >
                  Você não informou um valor para as parcelas.
                </div>

                <div v-if="resultProposals?.[0]?.rejected_cause == 'UNVIABLE'">
                  Sua proposta não foi aceita.<br />
                  Evite juros melhorando sua entrada ou aumente sua parcela!
                </div>

                <div v-if="resultProposals?.[0]?.rejected_cause == null">
                  Não foi possível simular sua proposta.
                </div>
              </div>
              <div
                v-for="simulation in resultProposals"
                :key="simulation.id"
                v-show="
                  !simulating &&
                  resultProposals.length > 0 &&
                  resultProposals?.[0]?.status != 'REJECTED'
                "
                class="qj-proposal"
              >
                <div class="d-flex flex-row justify-center">
                  <div class="d-flex">
                    <p class="qj-p">Você irá pagar...</p>
                  </div>
                </div>
                <div class="qj-p qj-proposal-values">
                  <span v-if="simulation.down_payment > 0">
                    <span style="font-size: 14px" class="qj-p">Entrada de </span
                    ><b>{{ simulation.down_payment | currency }}</b>
                    <br />
                    <span>+ {{ simulation.installments_count_1 }}x de </span
                    ><b class="qj-h3">{{ simulation.installment_1 | money }}</b>
                  </span>
                  <span v-else>
                    <b class="qj-h3">{{ simulation.installment_1 | money }}</b>
                    mensais<br />
                    em
                    <b class="qj-h3">{{ simulation.installments_count_1 }}</b>
                    {{
                      simulation.installments_count_1 == 1 ? " vez" : " vezes"
                    }}!
                  </span>
                </div>

                <div v-if="simulation.incentive" class="qj-proposal-incentive">
                  {{ simulation.incentive }}
                </div>

                <div
                  v-if="resultProposals?.[0]?.discount_rate > 0.01"
                  class="qj-tag-discount mb-2 mr-auto"
                >
                  <img src="@/assets/img/icons/sale.svg" alt="sale icon" /><span
                    ><b>{{ (resultProposals?.[0]?.discount_rate * 100).toFixed(0) }}%</b>
                    de desconto
                  </span>
                </div>

                <v-btn
                  class="rounded-lg qj-btn qj-bg-primary text-center mt-3"
                  @click="next(simulation, false)"
                >
                  <div style="">Quero esta opção</div>
                </v-btn>
              </div>
            </v-col>

            <v-col v-if="isEditDownPaymentButtonVisible" cols="12">
              <div
                @click="editDownPayment"
                class="qj-link qj-color-secondary qj-edit-down-payment"
              >
                Aumentar a entrada
              </div>
            </v-col>

            <!-- -->
            <v-col cols="12" v-if="history.length > 1">
              <p class="qj-p mb-0 text-center font-weight-medium">
                Histórico de simulações
              </p>
              <v-radio-group
                hide-details
                class="ma-0 pa-0"
                :key="proposal.id"
                v-model="idHistory"
                v-for="(proposal, index) in history"
                @change="selectedHistoryProposal(proposal)"
              >
                <v-radio
                  class="qj-radio"
                  :value="proposal.id"
                  v-if="
                    !(
                      resultProposals.length > 0 &&
                      proposal.id == resultProposals?.[0]?.id &&
                      index == 0
                    )
                  "
                >
                  <template v-slot:label>
                    <div
                      class="qj-radio-item"
                      :style="proposal.id == idHistory ? 'height: 75px;' : ''"
                    >
                      <div class="d-flex">
                        <div>
                          <span v-if="proposal.down_payment > 0">
                            <b>{{ proposal.down_payment | currency }}</b>
                            +
                          </span>
                          <b>{{ proposal.installment_1 | money }}</b>
                          mensais em
                          <b>{{ proposal.installments_count_1 }}</b>
                          vezes
                        </div>
                        <div
                          v-if="proposal?.discount_rate > 0.01"
                          class="
                            qj-tag-discount
                            d-flex
                            align-center
                            flex-nowrap flex-shrink-0
                          "
                        >
                          <img
                            src="@/assets/img/icons/sale.svg"
                            alt="sale icon"
                          /><span class="font-weight-bold"
                            >{{ (proposal?.discount_rate * 100).toFixed(0) }}%
                          </span>
                        </div>
                      </div>
                      <div
                        v-show="proposal.id == idHistory"
                        class="qj-btn-radio"
                      >
                        <v-btn
                          block
                          class="rounded-lg qj-btn qj-bg-primary"
                          @click="next(proposal, true)"
                        >
                          <div class="mx-auto">Prosseguir</div>
                        </v-btn>
                      </div>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import store from "@/store";
import { mapActions, mapMutations } from "vuex";
import util from "@/assets/js/util";
import http from "@/plugins/axios";
import Deal from "@/modules/deal";
import serviceDeal from "@/services/deal";
import serviceProposal from "@/services/proposal";
import { createTagManagerEvent } from "@/utils";
import { debounce } from "lodash";

export default {
  props: ["dialog", "deal", "simulationParams"],
  data: () => ({
    // Indica se é permitido informar a entrada na simulação
    allowsDownPayment: false,
    // Indica se é permitido informar a quantidade de parcelas na simulação
    allowsInstallmentsCount: false,
    // Indica se o cliente pode escolher se quer fazer simulação sem entrada
    askWithoutDownPayment: false,
    doWithoutDownPayment: false,
    forceWithoutDownPayment: false,
    // Indica se o cliente está alterando o valor da entrada
    isChangingDownPayment: false,
    // Indica se o botão para editar o valor da entrada está visível
    isEditDownPaymentButtonVisible: false,
    // Indica se houve um erro ao fazer a simulação
    error: false,
    // Quantidade de parcelas
    installmentsCount: null,
    // Indica se está sendo feita a consulta INICIAL dos parâmetros da simulação (primeira consulta)
    creatingSimulationParams: false,
    // Indica se houve um erro ao carregar pela primeira vez os parâmetros da simulação
    creatingSimulationParamsError: false,
    // Regras para validação dos campos
    rules: {
      downPaymentMin: true,
      downPaymentMax: true,
    },
    maxDownpayment: null,
    // Indica se a simulação está sendo feita
    simulating: false,
    // Parâmetros retornados pela API para serem usados na simulação
    // simulationParams: null,
    // Indica se está sendo feita a consulta para a atualização dos parâmetros da simulação
    updatingSimulationParams: false,
    // "setTimeout" usado para a atualizar os parâmetros da simulação
    // Deve ser cancelado toda vez que o cliente alterar o valor da entrada
    updateSimulationParamsTimeout: null,
    installmentsMessage: false,
    // Proposta simulada
    simulation: {
      status: null,
    },
    // Todas as simulações que foram feitas durante a sessão
    sessionProposals: [],
    // Resultado da simulação
    resultProposals: [],
    // Saldo devedor
    totalDebits: 0,
    // Proposta à vista
    incashProposal: {
      down_payment: 0,
    },
    timeout: null,
    // Tempo em milisegundos que serão aguardados antes de fazer a simulação
    timeoutSimulation: 1000,
    // Tempo em milisegundos que serão aguardados antes de atualizar os parâmetros da simulação
    // após o cliente alterar o valor da entrada
    timeoutUpdateSimulationParams: 1000,
    // Valor da entrada
    downPayment: 0,
    firstDownPaymentGet: false,
    // Valor mínimo da entrada
    downPaymentMin: 0,
    // Valor da parcela
    installment: null,
    // Indíces do arrays dos valores possíveis para a entrada e para as parcelas
    installmentsIndex: 0,
    // Quantidade máxima de simulações que serão exibidas no histórico
    historyMaxProposals: 10,
    // Simulações que serão exibidas no histórico
    history: [],
    idHistory: 0,
    money: {
      prefix: "R$ ",
      decimal: ",",
      thousands: ".",
      precision: 2,
    },
  }),
  computed: {
    /** Retorna true se o botão "Calcular" deve estar habilitado. */
    isSimulateButtonEnabled() {
      if (this.simulating) {
        return false;
      }

      if (this.isChangingDownPayment) {
        return false;
      }

      if (this.updatingSimulationParams) {
        return false;
      }

      if (this.getDownPayment() < this.downPaymentMin) {
        return false;
      }

      if (this.getDownPayment() >= this.incashProposal.down_payment) {
        return false;
      }

      return true;
    },
    /** Retorna true se os botões para alterar o valor da parcela devem estar habilitados. */
    isChangeInstallmentButtonEnabled() {
      if (this.simulating) {
        return false;
      }

      if (this.updatingSimulationParams) {
        return false;
      }

      if (this.getDownPayment() < this.downPaymentMin) {
        return false;
      }

      if (this.getDownPayment() >= this.incashProposal.down_payment) {
        return false;
      }

      return true;
    },
    isMinimunInstallmentValue() {
      if (this.installmentsIndex <= 0) return true;
      return false;
    },
    isMaximunInstallmentValue() {
      if (
        this.installmentsIndex + 1 ===
        this.simulationParams.installments_v2.length
      )
        return true;
      return false;
    },
  },
  watch: {
    dialog() {
      if (!this.dialog || !Deal.allowsSimulation(this.deal)) return;

      if (!this.simulationParams) {
        this.createSimulationParams();
      }

      let configurations =
        store.getters["negotiation/getConfiguration"].objects;

      this.totalDebits = this.totalDebit();
      this.incashProposal = Deal.getIncashProposal(this.deal.proposals);
      this.allowsDownPayment = Deal.allowsSimulationDownPayment(this.deal);
      let firstInstallmentProposal = this.deal.proposals?.find(
        proposal => proposal.payment_type == 'INSTALLMENT'
      );
      this.maxDownpayment =
        this.totalDebits * (1 - firstInstallmentProposal.discount_rate) - 0.01;

      this.allowsInstallmentsCount = Deal.allowsSimulationInstallmentsCount(
        this.deal,
        configurations
      );

      this.askWithoutDownPayment = Deal.askWithoutDownPayment(
        this.deal,
        configurations
      );
      this.forceWithoutDownPayment = Deal.forceWithoutDownPayment(
        this.deal,
        configurations
      );

      console.debug(
        "Permite entrada:",
        this.allowsDownPayment,
        ", parcelas:",
        this.allowsInstallmentsCount
      );

      if (this.allowsInstallmentsCount) {
        this.installmentsCount = 2;
      } else {
        this.installmentsCount = null;
      }

      // Busca as simulações anteriores
      let sessionProposals = this.deal.proposals
        .filter((proposal) => {
          return (
            proposal.type == "SIMULATED" &&
            (proposal.status == "CREATED" || proposal.status == "SELECTED")
          );
        })
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      if (sessionProposals.length) {
        for (let index = 0; index < this.historyMaxProposals; index++) {
          if (sessionProposals[index]) {
            this.history[index] = sessionProposals[index];
          }
        }
      }

      // Validação para o valor de entrada mínimo
      this.rules.downPaymentMin = (value) => {
        let downPayment = this.getDownPayment(value);

        if (downPayment < this.downPaymentMin) {
          let downPaymentMin = Vue.filter("money")(this.downPaymentMin);
          return "Mínimo: " + downPaymentMin;
        }

        return true;
      };

      // Validação para o valor de entrada máximo
      this.rules.downPaymentMax = (value) => {
        let downPayment = this.getDownPayment(value);

        if (downPayment >= this.maxDownpayment) {
          let downPaymentMax = Vue.filter("money")(this.maxDownpayment);
          return "Máximo: " + downPaymentMax;
        }

        return true;
      };

      setTimeout(() => {
        this.installmentsMessage = true;
        setTimeout(() => (this.installmentsMessage = false), 6000);
      }, this.timeoutSimulation);

      createTagManagerEvent({
        event: "simulationStarted",
        allowsDownPayment: this.allowsDownPayment,
        allowsInstallmentsCount: this.allowsInstallmentsCount,
        hasOthersSimulations: this.history.length > 0,
      });
    },
    downPayment(newValue, oldValue) {
      createTagManagerEvent({
        event: "simulationChangedDownPaymentValue",
      });
      if (newValue != oldValue && this.firstDownPaymentGet) {
        this.isChangingDownPayment = true;
        this.cancelUpdateSimulationParamsTimeout();
        this.debounceUpdateSimulationParams();
      }
      if (oldValue != 0) this.firstDownPaymentGet = true;
    },
    installment(newValue, oldValue) {
      if (newValue > oldValue) {
        createTagManagerEvent({
          event: "simulationIncreasedInstallmentValue",
        });
      } else {
        createTagManagerEvent({
          event: "simulationDecreaseadInstallmentValue",
        });
      }
      this.installmentsIndex =
        this.simulationParams.installments_v2.indexOf(newValue);
      this.startSimulation();
    },
  },
  methods: {
    ...mapActions("negotiation", ["ActionsSimulate"]),
    ...mapMutations("negotiation", ["setDeals"]),
    close() {
      createTagManagerEvent({
        event: "simulationClosed",
      });
      this.$emit("close");
    },
    totalDebit() {
      return Deal.calculateDebts(this.deal?.operations);
    },
    generateDownPayment() {
      if (this.doWithoutDownPayment) {
        return 0;
      } else if (!this.getDownPayment()) {
        return this.simulationParams.min_down_payment;
      }
      return this.getDownPayment();
    },
    startSimulation: debounce(function () {
      createTagManagerEvent({
        event: "simulationClickedSimulate",
      });

      // Inicia a simulação
      let simulations = this.getSimulationsFromHistory();

      this.installmentsMessage = false;

      if (simulations.length > 0) {
        // Simulação já foi realizada
        this.resultProposals = simulations;
      } else {
        this.simulate();
      }
    }, 400),
    simulate() {
      if (this.simulating) {
        return;
      }

      if (this.getDownPayment() < this.downPaymentMin) {
        return false;
      }

      if (this.getDownPayment() >= this.incashProposal.down_payment) {
        return false;
      }

      console.debug("Iniciando a simulação");

      let configPrefix = util.getPrefix(this.deal.id);

      this.error = false;
      this.simulating = true;
      this.isChangingDownPayment = false;
      this.updatingSimulationParams = false;

      this.cancelUpdateSimulationParamsTimeout();
      this.scrollToSimulation();

      let payload = {
        deal_id: this.deal.id,
        down_payment: this.generateDownPayment(),
        installment_1: this.installment || 0,
        installments_count_1: this.installmentsCount,
        payment_method: "BOLETO",
      };

      console.debug(
        "Simulando com entrada de",
        this.downPayment || 0,
        "e parcela de",
        this.installment
      );

      let adapterUrl = configPrefix
        ? util.getConfig()[configPrefix + "simulation_url"]
        : false;

      if (adapterUrl) {
        console.debug("Simulando no adapter");

        http.apiAdapter
          .post(adapterUrl + "?suggest_debit=true", payload)
          .then((res) => {
            let suggestions = res.data.suggestions || [];
            this.resultProposals = [res.data];

            suggestions.forEach((suggestion) => {
              if (!suggestion._down_payment_dates) {
                suggestion._down_payment_dates = res.data._down_payment_dates;
              }

              this.resultProposals.push(suggestion);
            });

            this.sessionProposals.push({
              downPayment: this.getDownPayment(),
              installment: this.installment,
              installmentsCount: this.installmentsCount,
              proposals: this.resultProposals,
            });

            let newDeals = store.getters["negotiation/getDeals"];
            newDeals.forEach((deal) => {
              if (deal.id == this.deal.id) {
                this.resultProposals.forEach((proposal) => {
                  deal.proposals.push(proposal);
                });
              }
            });
            this.setDeals(newDeals);

            if (res.data.status == "CREATED") {
              this.setHistory(res.data);
            }

            this.error = false;
            this.simulating = false;
          })
          .catch(() => {
            console.error("Não foi possível simular");

            this.error = true;
            this.simulating = false;
          });
      } else {
        console.debug("Simulando no core");

        this.ActionsSimulate(payload)
          .then((res) => {
            let suggestions = res.data.suggestions || [];
            this.resultProposals = [res.data];

            suggestions.forEach((suggestion) => {
              if (!suggestion._down_payment_dates) {
                suggestion._down_payment_dates = res.data._down_payment_dates;
              }

              this.resultProposals.push(suggestion);
            });

            this.sessionProposals.push({
              downPayment: this.getDownPayment(),
              installment: this.installment,
              installmentsCount: this.installmentsCount,
              proposals: this.resultProposals,
            });

            this.setHistory(res.data);

            this.error = false;
            this.simulating = false;
          })
          .catch(() => {
            console.error("Não foi possível simular");

            this.error = true;
            this.simulating = false;
          });
      }
    },
    increaseInstallment() {
      // Aumenta o valor da parcela
      if (
        this.installmentsIndex >=
        this.simulationParams.installments_v2.length - 1
      ) {
        return;
      }

      this.installmentsIndex++;
      this.installment =
        this.simulationParams.installments_v2[this.installmentsIndex];
      this.startSimulation();
    },
    decreaseInstallment() {
      // Diminui o valor da parcela
      if (this.installmentsIndex < 1) {
        return;
      }

      createTagManagerEvent({
        event: "simulationDecreaseadInstallmentValue",
      });

      this.installmentsIndex--;
      this.installment =
        this.simulationParams.installments_v2[this.installmentsIndex];
      this.startSimulation();
    },
    increaseInstallmentsCount() {
      // Aumenta a quantidade de parcelas
      if (
        this.installmentsCount >= this.simulationParams.max_installments_count
      ) {
        return;
      }

      this.installmentsCount++;
      this.startSimulation();
    },
    decreaseInstallmentsCount() {
      // Diminuiu a quantidade de parcelas
      if (this.installmentsCount <= 1) {
        return;
      }

      this.installmentsCount--;
      this.startSimulation();
    },
    getSimulationsFromHistory() {
      // Retorna as simulações do histórico caso elam existam
      let proposals = [];

      this.sessionProposals.forEach((simulation) => {
        if (simulation.downPayment != this.downPayment) {
          return false;
        }

        if (simulation.installment != this.installment) {
          return false;
        }

        if (simulation.installmentsCount != this.installmentsCount) {
          return false;
        }

        proposals = simulation.proposals;
      });

      return proposals;
    },
    /** Habilita a edição da entrada. */
    editDownPayment() {
      if (this.$refs.inputDownPayment) {
        this.$refs.inputDownPayment?.focus();
      }

      this.isEditDownPaymentButtonVisible = false;
    },
    /** O valor da entrada foi alterado. */
    downPaymentChanged(ev) {
      // Enter
      if (ev.keyCode == 13) {
        this.startSimulation(0);

        // Remove o foco do input para esconder o teclado do celular e
        // sobrar mais espaço na tela para mostrar o resultado da simulação
        if (this.$refs.downPayment && this.$refs.downPayment.blur) {
          this.$refs.downPayment.blur();
        }
      }
    },
    debounceUpdateSimulationParams: debounce(function () {
      this.updateSimulationParams();
    }, 500),
    /**
     * CRIA os parâmetros INICIAIS usados para a simulação:
     * quantidade de parcelas, valor das parcelas.
     */
    createSimulationParams() {
      if (this.creatingSimulationParams) {
        return;
      }

      console.debug("Carregando os parâmetros da simulação.");

      this.creatingSimulationParams = true;
      this.creatingSimulationParamsError = false;

      let payload = {
        down_payment: null,
        simulate: true,
      };

      serviceDeal
        .simulationParams(this.deal.id, payload)
        .then((response) => {
          this.simulationParams = response.data;
          this.updateSimulationParamsInputs();
        })
        .catch((error) => {
          console.error(
            "Não foi possível carregar os parâmetros da simulação.",
            error
          );
          this.creatingSimulationParamsError = true;
        })
        .finally(() => {
          this.creatingSimulationParams = false;
        });
    },
    /**
     * ATUALIZA os parâmetros usados para a simulação:
     * quantidade de parcelas, valor das parcelas.
     */
    updateSimulationParams() {
      if (this.updatingSimulationParams) {
        return;
      }

      let downPayment = this.getDownPayment();
      if (downPayment < this.downPaymentMin) {
        return;
      }

      if (downPayment >= this.incashProposal.down_payment) {
        return;
      }

      console.debug("Atualizando os parâmetros da simulação.");

      this.updatingSimulationParams = true;

      let payload = {
        down_payment: downPayment,
        simulate: true,
      };

      serviceDeal
        .simulationParams(this.deal.id, payload)
        .then((response) => {
          this.simulationParams = response.data;
          this.updateSimulationParamsInputs();
        })
        .catch((error) => {
          console.error(
            "Não foi possível atualizar os parâmetros da simulação.",
            error
          );
        })
        .finally(() => {
          this.isChangingDownPayment = false;
          this.updatingSimulationParams = false;
        });
    },
    /** Atualiza na tela os parâmetros usados para a simulação. */
    updateSimulationParamsInputs() {
      if (this.allowsDownPayment) {
        let downPaymentMin = this.simulationParams.min_down_payment || 0;

        if (this.downPayment == 0)
          this.downPayment = this.simulationParams.default_down_payment * 100;

        this.downPaymentMin = downPaymentMin;

        console.debug("Entrada mínima:", this.downPaymentMin);
      }

      if (this.simulationParams.installments_v2.length) {
        // Valor padrão da parcela
        // this.installment = this.simulationParams.default_installment || 0;
        const previousInstallment = this.installment;
        this.installment == null
          ? (this.installment = this.simulationParams.default_installment || 0)
          : (this.installment =
              this.findClosestInstallment(previousInstallment));
        const installMentIdx = this.simulationParams.installments_v2.indexOf(
          this.installment
        );
        this.installmentsIndex = installMentIdx;
      } else {
        this.installment = 0;
        this.installmentsIndex = 0;
      }
    },
    findClosestInstallment(value) {
      const newInstallments = [...this.simulationParams.installments_v2];
      const closest = newInstallments.sort((a, b) => {
        return Math.abs(value - a) - Math.abs(value - b);
      });
      return closest?.[0];
    },
    /** Faz o scroll até o resultado da simulação. */
    scrollToSimulation() {
      // Altura da DIV do "Carregando..."
      const LOADING_DIV_HEIGHT = 144;

      let dialogContainer = document.querySelector(
        "#dialog-simulation .v-card__text"
      );
      let simulationsContainer = document.querySelector("#simulations");

      if (!dialogContainer || !simulationsContainer) {
        return;
      }

      if (
        dialogContainer.offsetHeight >
        simulationsContainer.offsetTop + LOADING_DIV_HEIGHT
      ) {
        // O resultado da simulação já está visível na tela
        return;
      }

      let offset =
        simulationsContainer.offsetTop -
        dialogContainer.offsetHeight +
        LOADING_DIV_HEIGHT;

      setTimeout(() => {
        this.$vuetify.goTo(offset, {
          container: dialogContainer,
          duration: 1000,
        });
      }, 500);
    },
    next(proposal, selectedFromOthersSimulations) {
      createTagManagerEvent({
        event: "simulationSelectedProposal",
        operationsValue: proposal.operations_value,
        paymentMethod: proposal.payment_method,
        paymentType: proposal.payment_type,
        selectedFromOthersSimulations: selectedFromOthersSimulations,
      });
      serviceProposal
        .update({
          id: proposal.id,
          status: "SELECTED",
        })
        .catch((error) => {
          console.error(
            "Não foi possível atualizar a proposta",
            proposal.id,
            error
          );
        })
        .finally(() => {
          this.$emit("step", 2);
          this.$router
            .push({
              name: "NegotiationPaymentInfo",
              query: { id: window.btoa(proposal.id) },
            })
            .catch((failure) => {
              this.skipIfNavigationFailureIsGeneric(failure)
            });
        });
    },
    setHistory(newHistory) {
      const historyAlreadyExists = this.history.some((history) => {
        let isSameInstallmentValue =
          history.installment_1 == newHistory.installment_1;
        let isSameDownPayment = history.down_payment == newHistory.down_payment;
        return isSameInstallmentValue && isSameDownPayment;
      });
      if (newHistory.status == "REJECTED") {
        return;
      }

      let copy = Vue.util.extend({}, newHistory);
      if (!historyAlreadyExists) this.history.unshift(copy);

      // Se o histórico atingiu o máximo então remove a simulação mais antiga
      if (this.history.length > this.historyMaxProposals + 1) {
        this.history.pop();
      }
    },
    /** Retorna a entrada informada pelo cliente. */
    getDownPayment(value) {
      if (value === undefined) {
        value = this.downPayment;
      }

      try {
        return parseFloat(
          value.replace("R$ ", "").replace(".", "").replace(",", ".")
        );
      } catch (error) {
        return 0;
      }
    },
    selectedHistoryProposal(proposal) {
      createTagManagerEvent({
        event: "selectedHistoryProposal",
        proposal: proposal,
      });
    },
    /** Cancela o timeout que atualiza os parâmetros da simulação. */
    cancelUpdateSimulationParamsTimeout() {
      if (!this.updateSimulationParamsTimeout) {
        return;
      }

      clearTimeout(this.updateSimulationParamsTimeout);
    },
  },
};
</script>
<style lang="scss">
.v-list-item--active {
  .qj-simulation-param-value__v-select, .qj-param-value__v-select span {
    span {
      color: #00e58d !important;
    }
  }

}

.qj-simulation-param-value__v-select,
.qj-param-value__v-select {
  .theme--light.v-icon {
    color: unset !important;
  }
  .v-select__selections {
    align-items: center;
    justify-content: center;
    input {
      display: none !important;
    }
  }
}
.installment-skeleton {
  height: 60px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px 12px;
  div:nth-child(2) {
    flex-grow: 1;
    margin: 0px 8px;
    height: 40px;
    border-radius: 7px;
  }
  div:not(:nth-child(2)) {
    height: 40px;
    width: 40px;
  }
}
.installments-select__menu {
  overflow: auto !important;
  .v-list-item--active {
    background-color: hsl(157, 100%, 45%, 0.13);
  }
}
</style>

<style scoped lang="scss">
.qj-loading-simulation-params-error {
  .error-message {
    color: red !important;
    text-align: center;
  }
}

.qj-radio {
  background: #f6f6f6;
  border-radius: 7px;
  padding: 10px 12px;
  margin-top: 10px;
  align-items: initial;
}
.qj-radio-item {
  width: 100%;
  margin-left: 5px;
  font-style: normal;
  font-size: 14px;
  line-height: 115.9%;
  position: relative;
}
.qj-btn-radio {
  position: absolute;
  left: -30px;
  right: 0px;
  bottom: 0px;
}
.qj-modal-indication {
  position: absolute;
  left: 30px;
  right: 30px;
  height: 24px;
  display: flex;
  justify-content: center;
}
.qj-modal-indication .qj-msg {
  background: rgb(255, 0, 153);
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
  border-radius: 4px;
  padding: 5px 6px;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 115.9%;
  text-align: center;
  color: rgb(255, 255, 255);
  width: calc(100vw - 125px);
}
.qj-modal-indication .qj-img-right {
  margin-bottom: -15px;
  margin-top: 15px;
  margin-left: 2px;
}
.qj-modal-indication .qj-img-left {
  margin-bottom: -15px;
  margin-top: 15px;
  margin-right: 2px;
}

.qj-down-payment {
  padding-top: 8px;

  .qj-down-payment-wrapper {
    margin: auto;

    .qj-input-down-payment {
      margin-right: 12px;
      min-width: 140px;

      ::v-deep input {
        font-size: 18px;
        font-weight: 800;
        text-align: center;
      }

      ::v-deep .v-text-field__details {
        padding: 0;
      }
    }
  }
}

.qj-edit-down-payment {
  cursor: pointer;
  margin-bottom: 16px;
}

.qj-alert-error {
  margin-bottom: 32px;
}

.qj-simulation-param-value {
  background: #f6f6f6;
  border-radius: 7px;
  color: #000000 !important;
  font-size: 18px;
  line-height: 22px;
  font-weight: 800;
  margin: 0 8px;
  padding: 10px 8px 8px;
  width: inherit;
}
.qj-simulation-param-value__v-select,
.qj-param-value__v-select {
  border-radius: 7px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 800;
}

.qj-checkbox-without-payment {
  padding-top: 0 !important;

  .qj-checkbox {
    margin: 0 48px;
  }
}

.qj-proposal {
  background: #cafce9;
  border-radius: 7px;
  margin-bottom: 8px;
  padding: 10px;
}

.qj-proposal-values {
  margin-bottom: 8px;
}

.qj-simulating {
  background: #cafce9;
  border-radius: 7px;
  padding: 10px;
  height: 148px;
  text-align: center;
  padding: 28px 21px 22px 21px;
}

.qj-proposal-incentive {
  color: #000000;
  font-weight: 800;
  margin-bottom: 8px;
}

.qj-proposal-status-rejected {
  background-color: #ffdbdb;
  border-radius: 8px;
  color: #ff5050;
  font-weight: 600;
  padding: 16px;
  text-align: center;
}

.qj-proposal-status-rejected .qj-p {
  color: #ff5050 !important;
}
</style>
