<template>
  <v-slide-y-transition>
    <div v-show="alert" class="continue-div">
      <div class="continue-modal">
        <div class="continue-icon">
          <img src="@/assets/img/icons/icone-negociando.png" />
        </div>
        <div class="continue-content">
          <p class="qj-p modal-title">
            {{ items[index].title }}
          </p>
          <p class="qj-p mb-0">
            {{ items[index].text }}
          </p>
          <div class="continue-actions">
            <a
              href="javascript:void(0);"
              class="qj-link qj-color-secondary font-weight-bold"
              @click="close"
              >{{ items[index].btnNegate }}</a
            >
            <v-btn
              style="width: 121px"
              class="rounded-lg qj-btn qj-bg-secondary font-weight-bold"
              @click="next"
              >{{ items[index].btnAccept }}</v-btn
            >
          </div>
        </div>
      </div>
    </div>
  </v-slide-y-transition>
</template>

<script>
import store from "@/store";
import { mapActions, mapMutations } from "vuex";
export default {
  props: {
    alert: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    id: {
      type: Number,
      default: 0,
    },
    creditor: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    items: [
      {
        title: "Continue negociando!",
        text: "",
        btnNegate: "Agora não",
        btnAccept: "Negociar",
      },
      {
        title: "Continue negociando!",
        text: "Deseja negociar suas outras dívidas aqui na QuiteJá?",
        btnNegate: "Agora não",
        btnAccept: "Aproveitar",
      },
      {
        title: "Notificações.",
        text: "Gostaria de ser avisado sobre o andamento das suas negociações?",
        btnNegate: "Agora não",
        btnAccept: "Ser avisado",
      },
    ],
  }),
  watch: {
    alert() {
      this.items[0].text = 'Deseja negociar sua outra dívida com ' + this.creditor + '?'

    },
  },
  methods: {
    ...mapActions("negotiation", ["ActionsGenerate"]),
    ...mapMutations("home", ["setPannel"]),
    close() {
      this.$emit("close");
    },
    next() {
      switch (this.index) {
        case 0:
          if (
            typeof store.getters["negotiation/getDeals"].filter(
              (i) => i.id == this.id
            )?.[0]?.proposals === "object"
          ) {
            this.$router
              .push({
                name: "NegotiationDeal",
                query: { id: window.btoa(this.id) },
              })
              .catch((failure) => {
                this.skipIfNavigationFailureIsGeneric(failure)
              });
          } else {
            this.ActionsGenerate({
              deal_id: this.id,
            }).then(() => {
              this.$router
                .push({
                  name: "NegotiationDeal",
                  query: { id: window.btoa(this.id) },
                })
                .catch((failure) => {
                  this.skipIfNavigationFailureIsGeneric(failure)
                });
            }); //ERRO 503 aqui
          }
          break;
        case 1:
          this.setPannel(0);
          this.$router
            .push({
              name: "Deals",
            })
            .catch((failure) => {
              this.skipIfNavigationFailureIsGeneric(failure)
            });
          break;
        case 2:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.continue-div {
  position: fixed;
  top: 20px;
  z-index: 50;
  left: 0;
  right: 0;
  @media (min-width: 600px) {
    top: 35px;
  }
}

.continue-modal {
  background: #cafce9;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 16px;
  max-width: 328px;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.modal-title {
  margin-bottom: 6px;
  font-weight: 700;
}

.continue-icon {
  margin-right: 8px;
  img {
    width: 48px;
    height: 48px;
  }
}
.continue-actions {
  margin-top: 12px;
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
