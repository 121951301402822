<template>
  <v-dialog v-model="dialog" max-width="500" persistent>
    <v-card>
      <v-card-title class="text-h5 white lighten-2">
        <h4 class="terms-title mx-auto">Atenção</h4>
      </v-card-title>
      <v-btn
        icon
        class="qj-btn-close-dialog"
        style="top: 2px; right: 2px"
        @click="dialog = false"
      >
        <v-icon>far fa-times-circle</v-icon>
      </v-btn>
      <v-card-text class="pb-0">
        <h4 class="mb-4 black--text">
          É necessário concordar com os termos para prosseguir.
        </h4>
        <v-col
          cols="12 mb-3"
          class="box-conditions"
          v-if="showDebitText && (bankAccounts?.[0] ?? null)"
        >
          <template>
            <div class="qj-checkbox-label">
              <div style="text-align: justify; letter-spacing: -0.16px">
                <span v-html="debitAlertText"></span>
              </div>
            </div>
          </template>
        </v-col>
        <template>
          <div class="qj-checkbox-label">
            <div
              class="box-conditions"
              v-if="hasDifferentTerms"
              style="text-align: justify; letter-spacing: -0.16px"
            >
              Ao confirmar essa proposta, declaro que li e concordo com as
              informações importantes, os detalhes dessa
              {{ hideLinkTerms ? "negociação" : "negociação, e " }}
              <a
                v-if="!hideLinkTerms"
                href="javascript:void(0);"
                class="qj-link qj-color-secondary"
                @click="$emit('dialogTermsOfUse')"
              >
                {{ creditorTermsLabel }}</a
              >.
            </div>
            <div class="box-conditions" v-else>
              Li e concordo com os
              <a
                href="javascript:void(0);"
                class="qj-link qj-color-secondary"
                @click="$emit('openDialogTerms')"
              >
                {{ creditorTermsLabel }}</a
              >.
            </div>
          </div>
        </template>
      </v-card-text>

      <v-card-actions class="">
        <v-row justify="center">
          <v-col cols="6"
            ><v-btn
              class="rounded-lg qj-btn qj-bg-primary qj-p-btn"
              color="#00e58d"
              @click="handleAcceptTerms"
            >
              {{ forceDownload ? "Baixar e aceitar" : "Aceitar" }}
            </v-btn></v-col
          >
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: [
    "valid",
    "terms",
    "bankAccounts",
    "hideLinkTerms",
    "hasDifferentTerms",
    "creditorTermsLabel",
    "debitAlertText",
    "showDebitText",
    "forceDownload",
  ],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    async handleAcceptTerms() {
      this.dialog = false;
      window.requestAnimationFrame(() => {
        window.requestAnimationFrame(() => {
          window.requestAnimationFrame(() => {
            this.$emit("confirmAll");
          });
        });
      });
      return;
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.terms {
  &-title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
  }
}

.qj-btn {
  width: 100%;
}

.accept {
  background-color: var(--primary) !important;
}

.cancel {
  background-color: red !important;
}

.box-conditions {
  background: #f6f6f6;
  padding: 12px;
  border-radius: 7px;
}
</style>
