<template>
  <v-row class="shrink" justify="center" v-if="dialog">
    <v-dialog v-model="dialog" max-width="310">
      <v-card class="text-center">
        <v-card-title class="justify-center">
          Método de pagamento
        </v-card-title>
        <v-card-text>
          <v-radio-group v-model="paymentMethod">
            <v-radio
              class="radio-field"
              v-for="paymentMethod in methods"
              :key="paymentMethod"
              :value="paymentMethod"
              active-class="radio-field__active"
            >
              <template v-slot:label>
                <div
                  class="justify-space-between d-flex flex-grow-1 align-center"
                >
                  <span class="font-weight-bold black--text text-body-2">{{
                    paymentMethod | paymentMethodText
                  }}</span>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
          <v-btn
          autofocus
            width="100%"
            class="rounded-lg qj-btn qj-bg-primary btn__accept__proposal"
            @click="change"
            >Alterar</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
 
<script>
export default {
  props: {
    paymentMethods: {
      type: [Array, String],
      default: null,
    },
    selectedPaymentMethod: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      paymentMethod: null,
      dialog: false,
    };
  },
  computed: {
    methods() {
      if (typeof this.paymentMethods == 'string') {
        return this.paymentMethods.split(',')
      }
      return this.paymentMethods
    }
  },
  filters: {
    paymentMethodText(method){
      const methods = [
        { value: "BOLETO", text: "Boleto" },
        { value: "FIRST_PIX_OTHERS_BOLETO", text: "Pix" },
        { value: "FIRST_PIX_OTHERS_DEBIT", text: "Pix" },
        { value: "FIRST_BOLETO_OTHERS_DEBIT", text: "Boleto" },
        { value: "PIX", text: "Pix" },
      ];
      return methods.find(item => item.value == method)?.text ?? method
    }
  },
  methods: {
    close() {
      this.$emit("closeReloadProposalDialog");
    },
    change() {
      this.$emit("updatePaymentMethod", this.paymentMethod);
      this.dialog = false;
    },
    open(selectedPaymentMethod) {
      this.dialog = true;
      this.paymentMethod = selectedPaymentMethod;
    },
  },
  watch: {
    selectedPaymentMethod(newValue) {
      this.paymentMethod = newValue;
    },
  },
};
</script>


<style lang="scss" scoped>
.radio-field {
  padding: 15px 5px;
  border-radius: 10px;
  &__active {
    outline: 2px solid #00e58d !important;
  }
}
</style>