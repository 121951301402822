<template>
  <v-form
    lazy-validation
    v-model="validForm"
    ref="form"
    @submit.prevent="next()"
    id="contact-form"
  >
    <v-row class="qj-row-main">
      <v-col cols="12" class="pt-0 pb-2">
        <p class="mb-1 qj-p">
          Informe seu e-mail para você
          <b>
            receber
            {{ doShowBankSlip("os boletos", "as informações do acordo") }}
          </b>
        </p>
      </v-col>
      <!-- Input email -->
      <v-col cols="12" class="pt-0 pb-0">
        <v-text-field
          dense
          outlined
          required
          ref="email"
          type="email"
          name="email"
          label="E-mail"
          class="qj-input"
          autocomplete="email"
          :rules="[...rules.email, checkIfEmailsIsNotVald]"
          v-model="contactForm.email"
          data-hj-allow
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="pt-0 pb-2">
        <p class="mb-0 qj-p remember-this">
          Caso não receba nossos e-mails, verifique sua caixa de spam ou lixo
          eletrônico.
        </p>
      </v-col>
      <v-col cols="12">
        <p class="mb-0 qj-p">
          {{ hasManyPhones ? "Telefones" : "Telefone" }} para contato
        </p>
        <div class="d-flex align-center">
          <div v-if="cellphones.length == 1" class="phones">
            <p class="only-one">
              <b>{{ contactForm.phone.value | cellphone }}</b>
            </p>
          </div>
          <v-radio-group
            v-if="cellphones.length > 1"
            v-model="contactForm.phone"
            mandatory
            row
            hide-details
            class="phones mt-0"
          >
            <v-radio
              hide-details
              class="qj-checkbox"
              v-for="phone in cellphones"
              :key="phone.value"
              :value="phone"
              @click="dealSelectedPhone()"
            >
              <template v-slot:label>
                <p class="qj-p mb-0 pl-1">
                  <b>{{ phone.value | cellphone }}</b>
                  <em>(selecionado)</em>
                </p>
              </template>
            </v-radio>
          </v-radio-group>
        </div>
      </v-col>

      <v-col v-if="!hasPhones()" cols="12" class="pt-0">
        <div class="d-flex align-center">
          <v-col cols="12" class="py-0 px-0">
            <v-text-field
              dense
              outlined
              required
              ref="cellphone"
              inputmode="numeric"
              name="cellphone"
              label="Informe seu número de telefone"
              class="qj-input"
              :rules="rules.phone"
              v-model.lazy="contactForm.phone"
              v-mask="'(##) #####-####'"
              data-hj-allow
            ></v-text-field>
          </v-col>
        </div>
      </v-col>
      <v-col cols="12" class="pt-0 first-check" v-if="doShowConfirmInfoCheck()">
        <v-checkbox
          v-model="contactForm.confirm_infos"
          class="qj-checkbox pt-0 mt-0"
          ref="confirm-check"
          :rules="rules.checkbox"
          hide-details
          required
        >
          <template v-slot:label>
            <div class="qj-checkbox-label qj-checkbox-confirm">
              <span>
                Confirmo que as minhas informações de contato estão atualizadas.
              </span>
            </div>
          </template>
        </v-checkbox>
      </v-col>
      <!-- Whatsapp -->
      <v-col cols="12" class="pt-0 second-check">
        <v-checkbox
          hide-details
          class="qj-checkbox pt-0 mt-0"
          v-model="contactForm.whatsapp"
          @click="dealOpenInWhatsapp()"
        >
          <template v-slot:label>
            <div class="qj-checkbox-label qj-checkbox-whatsapp">
              <span style="color: #212121">
                Quero receber
                {{ doShowWhatsBankSlip("meus boletos", "informações") }} via
                WhatsApp
                <v-icon color="#212121" size="14"> fab fa-whatsapp </v-icon>
              </span>
            </div>
          </template>
        </v-checkbox>
      </v-col>
      <!-- Push Notification -->
      <v-col v-if="isNotificationSupported" cols="12" class="pt-0">
        <div class="py-0 d-flex align-center">
          <v-checkbox
            inset
            hide-details
            v-model="notification"
            @change="(e) => changeCheckbox(e)"
            :disabled="savingDeviceToken"
            :loading="savingDeviceToken"
            class="qj-checkbox mt-0"
            id="switch__receive__notifications"
          >
            <template v-slot:label>
              <div class="qj-checkbox-label qj-checkbox-notification">
                <samp
                  style="font-size: 14px; line-height: 14px; color: #212121"
                >
                  Quero receber notificações sobre a negociação
                </samp>
              </div>
            </template>
          </v-checkbox>
        </div>
      </v-col>

      <!-- Endereço -->
      <v-col cols="12" class="pt-0 pb-0" v-if="doShowAdressesFields">
        <v-row>
          <v-col cols="12">
            <p class="mb-0 qj-p">Confira seus dados</p>
          </v-col>
          <v-col cols="7" class="pt-0 pb-0">
            <v-text-field
              dense
              outlined
              required
              ref="cep"
              label="CEP"
              class="qj-input"
              :rules="rules.cep"
              :error-messages="cepError"
              v-mask="'##.###-###'"
              v-model="contactForm.zip_code"
              @change="(e) => changeZipCode(e)"
              @focusout="(e) => changeZipCode(e.target.value)"
            ></v-text-field>
          </v-col>
          <v-col cols="5" class="pt-0 pb-0"
            ><v-select
              dense
              outlined
              required
              label="Estado"
              class="qj-input"
              :disabled="disabledFields"
              :items="states"
              :rules="rules.uf"
              v-model="contactForm.state"
            ></v-select>
          </v-col>
          <v-col cols="7" class="pt-0 pb-0">
            <v-text-field
              dense
              outlined
              required
              label="Cidade"
              class="qj-input"
              :disabled="disabledFields"
              :rules="rules.city"
              v-model="contactForm.city"
            ></v-text-field>
          </v-col>
          <v-col cols="5" class="pt-0 pb-0">
            <v-text-field
              dense
              outlined
              required
              label="Bairro"
              class="qj-input"
              :disabled="disabledFields"
              :rules="rules.district"
              v-model="contactForm.neighborhood"
            ></v-text-field>
          </v-col>
          <v-col cols="8" class="pt-0 pb-0">
            <v-text-field
              dense
              outlined
              required
              label="Rua"
              class="qj-input"
              :disabled="disabledFields"
              :rules="rules.street"
              v-model="contactForm.street_address"
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="pt-0 pb-0">
            <v-text-field
              dense
              outlined
              required
              label="Número"
              class="qj-input"
              inputmode="numeric"
              style="
                appearance: textfield;
                -webkit-appearance: none;
                margin: 0;
                -moz-appearance: textfield;
              "
              :disabled="disabledFields"
              :rules="rules.number"
              v-model="contactForm.number"
              ref="numberInput"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pt-0 pb-0">
            <v-text-field
              dense
              outlined
              required
              label="Complemento"
              class="qj-input"
              :disabled="disabledFields"
              v-model="contactForm.complement"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- Footer -->
    <qj-footer
      type="next"
      :fixed="false"
      :disabled="false"
      :loading="loading"
      @send="next()"
      view="negociacao/contato"
    />
    <!-- Dialog important warning -->
    <DialogImportantWarning
      :query="query"
      :contactParams="contactParams"
      :dialog="dialogNext"
      @step="$emit('step', stap())"
    />
  </v-form>
</template>

<script>
import store from "@/store";
import { mapActions, mapGetters, mapMutations } from "vuex";
import util from "@/assets/js/util";
import Contact from "@/modules/contact";
import rules from "@/assets/js/rules";
import serviceUtil from "@/services/util";
import serviceCustomer from "@/services/customer";
import DialogImportantWarning from "../components/DialogImportantWarning";
import { createTagManagerEvent } from "@/utils";
import { debounce } from "lodash";

export default {
  components: {
    DialogImportantWarning,
  },
  data: () => ({
    validForm: null,
    deal: null,
    hidePaymentType: false,
    cepError: [],
    rules: rules,
    client: store.getters["negotiation/getMe"],
    beforeCep: "",
    states: [
      "AC",
      "AL",
      "AP",
      "AM",
      "BA",
      "CE",
      "DF",
      "ES",
      "GO",
      "MA",
      "MT",
      "MS",
      "MG",
      "PA",
      "PB",
      "PR",
      "PE",
      "PI",
      "RJ",
      "RN",
      "RS",
      "RO",
      "RR",
      "SC",
      "SP",
      "SE",
      "TO",
    ],
    proposal: {
      payment_method: null,
    },
    dialog: false,
    loading: false,
    dialogNext: false,
    showOpenEmail: true,
    disabledEmail: false,
    query: {},
    contactParams: {
      date: "",
    },
    cellphones: [],
    contactForm: {
      confirm_infos: null,
      whatsapp: true,
      email: null,
      phone: null,
      zip_code: "",
      state: "",
      city: "",
      neighborhood: "",
      number: "",
      street_address: "",
      complement: "",
    },
    disabledFields: false,
  }),
  created() {
    this.verifyPushPermission();
    
    if (!this.$route.params.date) {
      this.$router.push({ name: "Deals" }).catch((failure) => {
        this.skipIfNavigationFailureIsGeneric(failure);
      });
      return;
    }
    if (this.$route.params.skipPaymentInfo) {
      window.pageHistory.pages.pop();
    }

    if (this.typeOfTerms() == "itau") this.setAddress();
    this.setEmail();
    this.query = this.$route.query;
    this.cellphones = this.getCellphones();

    let proposalId = util.base64Decode(this.$route.query.id);
    this.proposal = util.getProposal(proposalId);
    if (this.$route.params.date) this.contactParams.date = this.$route.params.date;
    if (this.$route.params.phone) this.contactForm.phone = this.$route.params.phone;
    if (this.$route.params.whatsapp)
      this.contactForm.whatsapp = this.$route.params.whatsapp;

    let negotiation = store.getters["negotiation/getNegotiation"];

    if (negotiation.whatsapp) this.contactForm.whatsapp = negotiation.whatsapp;

    if (!util.isEmpty(this.contactForm.email)) this.disabledEmail = true;
    else this.showOpenEmail = false;
    this.deal = this.getDeal(this.proposal.deal_id);
    this.hidePaymentType = util.getConfigValue(
      this.deal?.creditor?.config_prefix + "hide_payment_method"
    );
    this.setPhonesLength(this.cellphones);
  },
  methods: {
    ...mapActions("pushNotification", {
      registerPushNotification: "registerPushNotification",
      verifyPushPermission: "verifyPushPermission",
    }),
    ...mapMutations("pushNotification", {
      setNotification: "setNotification",
    }),
    ...mapActions("negotiation", ["ActionsMe"]),
    ...mapMutations("negotiation", ["setNegotiation"]),
    dealOpenInWhatsapp() {
      createTagManagerEvent({
        event: "dealOptedInWhatsApp",
      });
    },
    ...mapActions("pluralCheck", ["setPhonesLength"]),
    dealSelectedPhone() {
      createTagManagerEvent({
        event: "dealSelectedPhone",
      });
    },
    checkIfEmailsIsNotVald(value) {
      let isInvalidEmail = this.me?.contacts?.some((contact) => {
        let isInvalidStatus = !["CONFIRMED", "CREATED"].includes(
          contact.status
        );
        return contact.value === value && isInvalidStatus;
      });
      return !isInvalidEmail || "Por favor, informe um e-mail válido";
    },
    doShowBankSlip(bankSliptext, otherText) {
      let isBankSlip = this.proposal.payment_method == "BOLETO";
      let dealId = this.proposal.deal_id;
      if (isBankSlip && util.doShowBankSlip(dealId) && !this.hidePaymentType)
        return bankSliptext;
      return otherText;
    },
    doShowWhatsBankSlip(bankSliptext, otherText) {
      let isBankSlip = this.proposal.payment_method == "BOLETO";
      let dealId = this.proposal.deal_id;

      if (
        isBankSlip &&
        util.doShowWhatsBankSlip(dealId) &&
        !this.hidePaymentType
      )
        return bankSliptext;
      return otherText;
    },

    prepareContactParams() {
      const phoneNumber = this.contactForm.phone?.value ?? this.contactForm.phone;
      this.setNegotiation({
        email: this.contactForm.email,
        phone: {
          id: this.contactForm.phone?.id ?? 0,
          value: phoneNumber,
        },
        whatsapp: this.contactForm.whatsapp,
        confirm_infos: this.contactForm.confirm_infos,
      });

      const prepareForm = {
        ...this.contactParams,
        confirm_infos: this.contactForm.confirm_infos,
        whatsapp: this.contactForm.whatsapp,
        email: this.contactForm.email,
        phone: this.contactForm.phone,
        addresse: null,
      };

      if (util.isEmpty(this.contactForm.zip_code)) {
        prepareForm.addresse = null;
      } else {
        prepareForm.addresse = {
          zip_code: this.contactForm.zip_code,
          state: this.contactForm.state,
          city: this.contactForm.city,
          neighborhood: this.contactForm.neighborhood,
          number: this.contactForm.number,
          street_address: this.contactForm.street_address,
          complement: this.contactForm.complement,
        };
      }

      return prepareForm
    },

    async next() {
      if (!this.$refs.form.validate()) return;

      createTagManagerEvent({
        event: "dealSubmittedContacts",
      });
      this.loading = true;
      console.debug("Validando o e-mail", this.contactForm.email);

      try {
        const validatorResponse = await serviceUtil.validatorEmail(
          this.contactForm.email
        );

        if (validatorResponse.data.suggestion) {
          console.debug("Sugestão:", validatorResponse.data.suggestion);
          this.contactForm.email = validatorResponse.data.suggestion;
        }

        if (typeof this.contactForm.phone !== "object") {
          try {
            await serviceCustomer.contacts({
              contacts: [util.justNumbers(this.contactForm.phone)],
              confirm: true,
            });

            await this.ActionsMe().finally(() => {
              this.client = store.getters["negotiation/getMe"];
              this.getCellphones();
            });
          } catch (err) {
            console.error("Não foi possível adicionar o telefone.", err);
          }
        }
      } catch (err) {
        console.error("Não foi possível validar o e-mail.", err);
      } finally {
        this.contactParams = this.prepareContactParams()
        this.loading = false;
        this.dialogNext = true;
      }
    },

    getCellphones() {
      let contacts = [];
      if (!util.isEmpty(this.client.contacts)) {
        let validStatus = ["CONFIRMED", "CREATED"];
        contacts = this.client.contacts?.filter(
          (i) => validStatus.includes(i.status) && i.type == "CELLPHONE"
        );
        this.contactForm.phone = contacts?.[0] ?? null;
      }

      return contacts;
    },
    setAddress() {
      if (!util.isEmpty(this.client.addresses)) {
        let res = [];
        let confirmed = this.client.addresses.filter(
          (i) => i.status == "CONFIRMED" && i.status != "DISCARDED"
        );

        if (confirmed.length) res = confirmed;
        else
          res = this.client.addresses.filter(
            (i) => i.status != "CONFIRMED" && i.status != "DISCARDED"
          );

        this.beforeCep = res?.[0] ? util.justNumbers(res?.[0]?.zip_code) : "";
        this.contactForm.zip_code = res?.[0]
          ? util.justNumbers(res?.[0]?.zip_code)
          : "";
        this.contactForm.state = res?.[0] ? res?.[0]?.state : "";
        this.contactForm.city = res?.[0] ? res?.[0]?.city : "";
        this.contactForm.neighborhood = res?.[0] ? res?.[0]?.neighborhood : "";
        this.contactForm.number = res?.[0] ? res?.[0]?.number : "";
        this.contactForm.street_address = res?.[0] ? res?.[0]?.street_address : "";
        this.contactForm.complement = res?.[0] ? res?.[0]?.complement : "";
      }
    },
    setEmail() {
      let emails = Contact.getContacts(this.client.contacts, "EMAIL");
      if (emails.length > 0) {
        this.contactForm.email = emails?.[0]?.value;
      } else {
        this.contactForm.email = "";
      }
    },
    openEmail() {
      createTagManagerEvent({
        event: "dealClickedChangeEmail",
      });
      this.disabledEmail = false;
      setTimeout(() => this.$refs.email?.focus(), 10);
    },
    stap() {
      let proposalId = util.base64Decode(this.$route.query.id);
      let proposal = util.getProposal(proposalId);

      if (proposal.debit_suggestion_id) return 5;
      else return 4;
    },
    typeOfTerms() {
      let proposalId = util.base64Decode(this.$route.query.id);
      let prefix = util.getPrefixByProposal(proposalId);
      switch (prefix) {
        case "itau.":
          return "itau";
        default:
          return "default";
      }
    },
    validateCep: debounce(async function (cep) {
      const trimmedCep = cep.replace(/[-.]/g, "");
      const { data } = await serviceUtil.cep(trimmedCep);
      this.disabledFields = false;
      if (data.erro) {
        this.cepError = ["CEP Invalido"];
        return;
      }
      this.autoCompleteAdressFields(data);
      this.cepError = [];
      this.$refs.numberInput.focus();
    }, 300),
    autoCompleteAdressFields(adress) {
      Object.assign(this.contactForm, {
        state: adress.uf,
        city: adress.localidade,
        neighborhood: adress.bairro,
        street_address: adress.logradouro,
      });
    },
    hasPhones() {
      if (this.cellphones.length >= 1) return true;
      return false;
    },
    doShowConfirmInfoCheck() {
      let configPrefix = util.getPrefixByProposal(this.proposal?.id);

      if (!configPrefix) return false;

      let showConfirmCheck = configPrefix
        ? util.getConfig()[`${configPrefix}show_confirm_check`]
        : 0;

      if (showConfirmCheck == 1) return true;

      return false;
    },
    async changeCheckbox(e) {
      if (e) {
        this.notification = true;
      } else {
        this.notification = false;
      }

      await this.registerPushNotification(this.notification);
      await this.ActionsMe();
      this.verifyPushPermission();
    },
    fillAddressFields() {
      let validAddress =
        this.client?.addresses?.find(
          (address) => address.status == "CONFIRMED"
        ) ||
        this.client?.addresses?.find((address) => address.status != "INVALID");

      if (!validAddress) return;

      this.contactForm.zip_code = validAddress?.zip_code;
      this.contactForm.state = validAddress?.state;
      this.contactForm.city = validAddress?.city;
      this.contactForm.neighborhood = validAddress?.neighborhood;
      this.contactForm.street_address = validAddress?.street_address;
      this.contactForm.number = validAddress?.number;
      this.contactForm.complement = validAddress?.complement;
      return;
    },
    changeZipCode(cep) {
      let cepValidatorRegex = /^[0-9]{2}\.[0-9]{3}-[0-9]{3}$/;
      if (cep?.length == 10 && cepValidatorRegex.test(cep)) {
        this.disabledFields = true;
        this.validateCep(cep);
      }
    },
  },
  computed: {
    ...mapGetters("pushNotification", {
      notificationFromStore: "notification",
      savingDeviceToken: "loading",
      isNotificationSupported: "supported",
    }),
    ...mapGetters("negotiation", {
      me: "getMe",
      getDeal: "getDeal",
    }),
    ...mapGetters("pluralCheck", {
      hasManyPhones: "hasManyPhones",
    }),
    notification: {
      get() {
        return this.notificationFromStore;
      },
      set(notification) {
        this.setNotification(notification);
      },
    },
    doShowAdressesFields() {
      let configPrefix = util.getPrefixByProposal(this.proposal.id);
      if (!configPrefix) return false;

      let config = util.getConfig()[configPrefix + "ask_address"] ?? [];
      if (config.includes(this.proposal?.payment_type)) {
        this.fillAddressFields();
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.remember-this {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 115.9%;
  color: #616161 !important;
}

.first-check {
  margin-top: 3px;
}

.qj-checkbox-confirm,
.qj-checkbox-whatsapp,
.qj-checkbox-notification {
  margin-left: 8px;
  margin-right: 8px;
}

.phones {
  margin-bottom: 3px;
  width: 100%;

  .v-radio,
  .v-item--active,
  .only-one {
    border-radius: 7px;
    padding: 8px 9px;
    margin: 5px 0px 0px;
    width: 100%;
  }

  .v-radio {
    em {
      display: none;
    }
  }

  .v-item--active,
  .only-one {
    background: #f6f6f6;

    em {
      display: inline;
      position: absolute;
      right: 0;
      width: 100%;
      text-align: right;
      font-style: normal;
    }
  }
}
</style>
