<template>
  <div>
    <v-row v-if="!loading">
      <div
        v-if="!campaigns[proposal.incentive] && proposal.incentive && showAlias"
        class="incentive-text"
        :class="returnProposalBoxClass(proposal)"
        :style="generateProposalBoxStyle(proposal)"
      >
        <span>{{ proposal.incentive }}</span>
      </div>
      <div
        v-else-if="isBestDiscount(proposal) && showAlias"
        class="incentive-best-discount"
      >
        <span>Melhor desconto</span>
      </div>

      <v-col
        v-if="proposalStatus"
        cols="12"
        class="pt-3 pb-0 proposal-status-chip"
      >
        <span>
          {{ proposalStatus }}
        </span>
      </v-col>

      <v-col
        v-if="proposal.alias && showAlias"
        cols="12"
        class="qj-proposal-alias pt-3 pb-0"
      >
        <span>{{ proposal.alias }}</span>
      </v-col>

      <v-col
        v-if="proposal?.discount_rate > 0.01"
        cols="12"
        class="align-center d-flex pt-2 pb-0 justify-center"
      >
        <div class="qj-tag-discount">
          <img src="@/assets/img/icons/sale.svg" alt="sale icon" />
          <span>
            <b>{{ calculateDiscountRate(proposal) }}%</b> de desconto
          </span>
        </div>
      </v-col>
      <v-col
        cols="12"
        class="text-center qj-price pt-3"
        v-if="proposal.payment_method == 'PARCELE_JA'"
      >
        <span>
          <h2>{{ getTotalAmount(proposal) | money }}</h2>
          em
        </span>
        <span>
          <h2>{{ proposal.installments_count_1 }}x</h2>
          de
          <h2>
            {{ proposal.installment_1 | money }}
          </h2>
        </span>
        <span class="text-body-2"
          >Juros de {{ (proposal.interest_rate * 100).toFixed(2) }}%</span
        >
        <div v-if="!dontShowPaymentMethodTags">
          <div class="d-flex flex-column pb-0">
            <div class="d-flex flex-column align-start pb-0">
              <span
                :class="
                  (proposal.payment_type != 'IN_CASH' ? 'parceled ' : 'cash ') +
                  'qj-tag'
                "
              >
                <b>{{ proposal.payment_type | proposalPaymentType }}</b>
                <span
                  v-if="isPaymentMethodVisible"
                  class="mb-0 qj-proposal-payment-method"
                >
                  no {{ proposal.payment_method | proposalPaymentMethod }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </v-col>
      <!-- Price parcelado -->
      <v-col
        cols="12"
        class="text-center qj-price pt-3 pb-4"
        v-else-if="proposal.payment_type == 'INSTALLMENT'"
      >
        <span v-if="proposal.down_payment > 0" class="black--text">
          <span class="qj-proposal-down-payment">Entrada</span> de
          <span class="qj-proposal-down-payment">
            {{ proposal.down_payment | money }}
          </span>
          <div class="qj-proposal-installments">
            <h2>+ {{ proposal.installments_count_1 }}x</h2>
            de
            <h2>{{ (proposal.installment_1 + partialIof) | money }}</h2>
          </div>
        </span>
        <span v-else>
          <h2>{{ proposal.installments_count_1 }}x</h2>
          de
          <h2>
            {{ (proposal.installment_1 + partialIof) | money }}
          </h2>
        </span>

        <!-- Fluxo irregular 2 -->
        <div
          v-if="proposal.installments_count_2"
          class="qj-proposal-irregular-flow mb-2"
        >
          <span class="plus-sign">+</span>

          <span class="installment">
            {{ proposal.installments_count_2 }}x
          </span>

          <span class="separator">de</span>

          <span class="installment">
            {{ proposal.installment_2 | money }}
          </span>
        </div>

        <!-- Fluxo irregular 3 -->
        <div
          v-if="proposal.installments_count_3"
          class="qj-proposal-irregular-flow"
        >
          <span class="plus-sign">+</span>

          <span class="installment">
            {{ proposal.installments_count_3 }}x
          </span>

          <span class="separator">de</span>

          <span class="installment">
            {{ proposal.installment_3 | money }}
          </span>
        </div>

        <!-- Fluxo irregular 4 -->
        <div
          v-if="proposal.installments_count_4"
          class="qj-proposal-irregular-flow"
        >
          <span class="plus-sign">+</span>

          <span class="installment">
            {{ proposal.installments_count_4 }}x
          </span>

          <span class="separator">de</span>

          <span class="installment">
            {{ proposal.installment_4 | money }}
          </span>
        </div>

        <!-- Fluxo irregular 5 -->
        <div
          v-if="proposal.installments_count_5"
          class="qj-proposal-irregular-flow"
        >
          <span class="plus-sign">+</span>

          <span class="installment">
            {{ proposal.installments_count_5 }}x
          </span>

          <span class="separator">de</span>

          <span class="installment">
            {{ proposal.installment_5 | money }}
          </span>
        </div>

        <div v-if="!dontShowPaymentMethodTags">
          <div class="d-flex flex-column 1">
            <div class="d-flex flex-column align-start">
              <span
                v-if="proposal.payment_method == 'FIRST_BOLETO_OTHERS_CREDIT'"
                class="parceled qj-tag"
              >
                <span class="mb-0"> Entrada </span>
                <b>no boleto</b>
                <span class="mb-0"> + parcelas </span>
                <b>nas faturas</b>
              </span>
              <span
                v-else
                :class="
                  (proposal.payment_type != 'IN_CASH' ? 'parceled ' : 'cash ') +
                  'qj-tag'
                "
              >
                <b>{{ proposal.payment_type | proposalPaymentType }}</b>
                <span
                  v-if="isPaymentMethodVisible"
                  class="mb-0 qj-proposal-payment-method"
                >
                  no {{ proposal.payment_method | proposalPaymentMethod }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </v-col>

      <!-- Price in cash -->
      <v-col cols="12" class="text-center qj-price py-2" v-else>
        <span>
          <h2>{{ proposal.down_payment | money }}</h2></span
        >
        <span
          :class="
            (proposal.payment_type != 'IN_CASH' ? 'parceled ' : 'cash ') +
            'qj-tag'
          "
        >
          <b>{{ proposal.payment_type | proposalPaymentType }}</b>
          <span
            v-if="isPaymentMethodVisible"
            class="mb-0 qj-proposal-payment-method"
          >
            no
            {{ proposal.payment_method | proposalPaymentMethod }}
          </span>
        </span>
      </v-col>

      <!-- Btn -->
      <v-col
        v-if="
          isParceleJa(proposal) &&
          proposal.payment_type == 'IN_CASH' &&
          doShowNextButton
        "
        class="py-0 flex-grow-0"
        cols="12"
      >
        <div class="d-flex flex-column align-center mb-2">
          <span class="qj-p">ou</span>
          <div class="qj-tag mt-0 parceled text-center">
            Em até 12X parcelado no <b>Cartão de Crédito</b>
          </div>
        </div>
        <div class="parcele-ja__btns">
          <div class="mb-2">
            <v-btn
              block
              class="rounded-lg qj-btn qj-bg-primary btn__accept__proposal mx-auto"
              style=""
              @mousedown.stop=""
              @touchend.stop=""
              @click.stop="$emit('next', proposal)"
            >
              <div style="">Pagar no <b>Boleto</b></div>
            </v-btn>
          </div>
          <div class="mb-2">
            <v-btn
              block
              class="rounded-lg qj-btn btn__accept__proposal mx-auto"
              style=""
              color="#CAFCE9"
              @mousedown.stop=""
              @touchend.stop=""
              @click.stop="
                $emit('nextToParceleJa', {
                  proposal: proposal,
                  type: 'PARCELE_JA',
                })
              "
            >
              <div style="">Parcelar no <b>Cartão</b></div>
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col v-else-if="doShowNextButton" class="py-0 mx-auto flex-grow-0">
        <div class="mx-auto mb-2">
          <v-btn
            block
            class="rounded-lg qj-btn qj-bg-primary btn__accept__proposal mx-auto"
            style=""
            @mousedown.stop=""
            @touchend.stop=""
            @click.stop="$emit('next', proposal)"
          >
            <div style="">Prosseguir</div>
          </v-btn>
        </div>
      </v-col>
      <v-col v-else-if="doShowDetailsButton" class="details-button">
        <div class="mx-auto mb-3">
          <a
            href="javascript:void(0);"
            @click="openNegotiationDetails()"
            class="qj-link qj-color-secondary"
            >Ver detalhes da negociação</a
          >
        </div>
      </v-col>

      <div
        v-if="
          proposal.expires_in &&
          countDown(proposal.expires_in) &&
          showExpiresIn &&
          proposal.status != 'AGREED'
        "
        class="mx-auto renegocia-chip px-2"
      >
        <v-icon
          v-if="expiresIn24hours(proposal.expires_in)"
          style="margin-right: 5px"
          size="15"
          color="#404040"
          >mdi-alarm</v-icon
        >
        <span>{{ textCountDown }} </span>
      </div>
    </v-row>
    <v-row v-if="loading" justify="center" align="center">
      <div class="d-flex flex-column justify-center align-center">
        <v-skeleton-loader type="chip" class="mb-1"></v-skeleton-loader>
        <v-skeleton-loader
          type="image"
          width="120px"
          height="20px"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="image"
          width="140px"
          height="30px"
          class="mt-1"
        ></v-skeleton-loader>
        <v-skeleton-loader type="chip" class="mt-1"></v-skeleton-loader>
        <v-skeleton-loader
          type="image"
          width="110px"
          height="40px"
          class="mt-1"
        ></v-skeleton-loader>
      </div>
    </v-row>
  </div>
</template>

<script>
import campaigns from "@/assets/js/campaigns";
import util from "@/assets/js/util";
import Proposal from "@/modules/proposal";
import Deal from "@/modules/deal";
import moment from "moment";
import { mapGetters } from "vuex";
import environment from "@/assets/js/environment";
export default {
  props: {
    proposal: {
      required: true,
    },
    proposals: {
      type: Array,
    },
    showInterestRate: {
      type: Boolean,
      default: false,
    },
    proposalStatus: {
      required: false,
      default: false,
    },
    deal: {
      type: Object,
      default: null,
    },
    doShowNextButton: {
      type: Boolean,
      default: true,
    },
    doShowDetailsButton: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showAlias: {
      type: Boolean,
      default: false,
    },
    showExpiresIn: {
      type: Boolean,
      default: true,
    },
    openNegotiationDetails: {
      type: Function,
      required: false,
    },
    partialIof: {
      default: 0,
    },
  },
  created() {
    setInterval(() => {
      this.now = moment();
    }, 1000);
  },
  mounted() {
    this.dontShowPaymentMethodTags = util.getConfigValue(
      this.deal?.creditor?.config_prefix + "dont_show_payment_method_tag"
    );
  },
  data() {
    return {
      campaigns: campaigns,
      now: moment(),
      proposalCardStyle: null,
      dontShowPaymentMethodTags: false,
      textCountDown: null,
    };
  },
  methods: {
    isParceleJa(proposal) {
      const config = this.deal?.creditor?.config_prefix + "parcele-ja.active";

      if (util.getConfigValue(config) == 0) {
        return false;
      }

      let temTagDoParceleJa = this.deal?.tags_v2?.some(
        (tag) => tag.key == "parcele-ja" && tag.value == "true"
      );
      if (!temTagDoParceleJa) {
        temTagDoParceleJa = this.deal?.tags?.split(",").includes("PARCELE_JA");
      }
      if (temTagDoParceleJa) return true;

      const parceleJaActiveConfig = util.getConfigValue(config) == 1;
      return (
        parceleJaActiveConfig &&
        (proposal.source === "CREDITOR" ||
          environment.showParceleJaForQuitejaProposals)
      );
    },
    isCampaign({ name = null, proposal = false, deal = false }) {
      if (proposal) return proposal?.campaign == name;

      if (deal)
        return (
          deal?.tags_v2?.some((tag) => tag.key == name) ||
          deal?.tags?.includes(name)
        );
      return false;
    },
    countDown(time) {
      let expires = moment(time);
      let expiresIn = moment.duration(expires.diff(this.now));
      if (
        expiresIn / (1000 * 60 * 60 * 24) >= 1 &&
        expiresIn / (1000 * 60 * 60 * 24) < 30
      ) {
        this.textCountDown = "Por tempo limitado";
        return true;
      }
      function zero(x) {
        if (x < 10) {
          x = "0" + x;
        }
        return x;
      }
      let hours = zero(expiresIn.hours());
      let minutes = zero(expiresIn.minutes());
      let seconds = zero(expiresIn.seconds());
      if (expiresIn / (1000 * 60 * 60 * 24) < 1) {
        this.textCountDown = `Oferta acaba em ${hours}:${minutes}:${seconds}`;
        return true;
      }
      return false;
    },
    expiresIn24hours(expireIn) {
      let expires = moment(expireIn);
      let expiresIn = moment.duration(expires.diff(this.now));
      if (expiresIn.days() >= 1) return false;
      return true;
    },
    isBestDiscount(proposal) {
      if (this.proposals?.length == 0) return false;

      const isBestDiscount = this.proposals?.reduce(function (prev, curr) {
        return curr.discount_rate > prev.discount_rate ? curr : prev;
      }, 0);
      if (!this.showAlias || !proposal) return false;
      // if (proposal.payment_type !== "IN_CASH") return false;
      if (
        this.proposals?.some((proposal) => {
          return campaigns[proposal.incentive];
        })
      )
        return false;

      if (!proposal?.discount_rate) return false;
      if (proposal?.discount_rate <= 0.1) return false;

      // Não exibe o incentivo quando existir proposta destacada para o Tipo da Oferta
      if (this.proposalOfferType) return false;

      return proposal.id == isBestDiscount?.id;
    },
    calculateDiscountRate(proposal) {
      let discount = proposal?.discount_rate * 100;
      return discount > 99 ? Math.floor(discount) : Math.round(discount);
    },
    getBadge() {
      return require("@/assets/img/svg/melhor_desconto.svg");
    },
    isOperationsValueVisible(proposal) {
      return Proposal.isOperationsValueVisible(proposal);
    },
    typeOfTerms() {
      let prefix = util.getPrefix(this.deal?.id);
      switch (prefix) {
        case "itau.":
          return "itau";
        default:
          return "default";
      }
    },
    returnProposalBoxClass(proposal) {
      if (!proposal || !proposal.incentive) return "";
      if (proposal?.incentive == "Máximo parcelamento") {
        return "incentive-color-purple";
      }
      if (proposal?.incentive == "Parcelamento máximo") {
        return "incentive-color-purple";
      }
      if (proposal?.incentive == "Oferta sem juros") {
        return "incentive-color-gray";
      } else {
        return null;
      }
    },
    getTotalAmount(proposal) {
      if (this.deal?.active_proposal?.parcele_ja_proposal) {
        return this.deal?.active_proposal?.down_payment;
      }
      if (proposal?.payment_method == "PARCELE_JA") {
        const parentProposal = this.deal.proposals?.find((item) => {
          return item.id == proposal.parent_id;
        });
        return (
          this.deal?.active_proposal?.down_payment ||
          parentProposal?.down_payment
        );
      }
    },
    generateProposalBoxStyle(proposal) {
      if (this.returnProposalBoxClass(proposal) || !proposal.incentive)
        return {};
      const str = JSON.parse(JSON.stringify(proposal)).incentive;
      return {
        borderColor: util.stringToColor(str) ?? "#853500",
        backgroundColor: util.stringToColor(str) ?? "#853500",
        color: "#FFFFFF",
      };
    },
  },
  computed: {
    ...mapGetters("negotiation", {
      selectedDeal: "getSelectedDeal",
    }),
    isPaymentMethodVisible(deal = this.deal) {
      if (!deal) {
        deal = this.selectedDeal;
      }
      const hidePaymentType = util.getConfigValue(
        this.deal?.creditor?.config_prefix + "hide_payment_method"
      );
      if (hidePaymentType) return false;
      const isVisible = Deal.isPaymentMethodVisible(deal);
      return isVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap");
.parcele-ja {
  &__btns {
    > div {
      width: 170px;
    }
    display: flex;
    justify-content: center;
    gap: 16px;
    @media (max-width: 410px) {
      flex-direction: column;
      gap: 0px;
      align-items: center;
    }
  }
}
.qj-price {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 12px 8px !important;
}
.incentive-text,
.incentive-best-discount {
  width: 100%;
  height: 20px;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: rgb(240, 240, 240) 1px solid;

  span {
    font-size: 12px;
    font-weight: 800;
    vertical-align: text-top;
  }
}
.incentive-text {
  color: #000000;
}
.incentive-color-purple {
  color: #ffffff;
  background-color: #8741a9;
}
.incentive-color-gray {
  color: #ffffff;
  background-color: #9d9d9d;
}
.incentive-best-discount {
  color: #ffffff;
  background-color: #ff0099;
}

.renegocia-chip {
  width: 100%;
  height: 20px;
  text-align: center;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  color: #404040;
  background-color: #f0f0f0;
  span {
    font-weight: 700;
    font-size: 12px;
  }
  .v-icon,
  span {
    vertical-align: text-top;
  }
}

.qj-tag-discount {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  margin-left: auto;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background: #ffc1e6;
  height: 25px;
  line-height: 20px;
  padding: 5px 8px 5px 6px;
  margin: 0;
  img {
    width: unset;
    height: unset;
  }
  span {
    padding: 0;
    @media (max-width: 320px) {
      font-size: 10px;
    }
  }
}
.qj-tag {
  padding: 5px 8px;
  margin-top: 6px;
}
.qj-proposal-down-payment {
  font-weight: 600;
}

.qj-proposal-installments {
  font-weight: 400;
  font-size: 14px;
}

.qj-proposal-irregular-flow {
  line-height: 15px;
  margin-bottom: 5px !important;

  .installment {
    font-weight: 800;
  }
}

.qj-proposal-alias {
  font-size: 14px;
  font-weight: 800;
  text-align: center;
}

.qj-proposal-badge {
  z-index: 100;
  height: 82px;
  margin-bottom: -26px;
  margin-right: -20px;
  margin-top: -32px;
  @media (max-width: 320px) {
    transform: scale(0.8);
    margin-right: -30px;
    margin-top: -52px;
  }
  @media (max-width: 307px) {
    display: none;
  }
}

.qj-proposal-payment-method {
  font-weight: 400;
}

.cash {
  margin-top: 6px !important;
}

.details-button {
  padding: 0px;
  text-align: center;
  flex: unset;
  font-size: 12px;
  font-weight: 800;
}

.proposal-status-chip {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: 10px;
    font-weight: 800;
    text-align: center;
    color: #000000;
    background-color: #e5e5e5;
    border-radius: 100px;
    padding: 5px 10px;
    line-height: normal;
  }
}
</style>
