<template>
  <div class="dialog-date-picker">
    <v-dialog v-model="dialog" width="290" attach=".dialog-date-picker">
      <template v-slot:activator="{ on }">
        <slot name="activator" v-bind="{ on }"> </slot>
      </template>
      <v-date-picker
        ref="calendar"
        color="#00e58d"
        :value="date"
        @input="updateDate"
        class="qj-date-picker"
        locale="pt-BR"
        :show-adjacent-months="false"
        :allowed-dates="checkAllowedDates"
        @change="dialog = false"
        active-picker="DATE"
        :min="this.downPaymentDates?.[0]"
        :max="this.downPaymentDates[this.downPaymentDates.length - 1]"
        v-touch="touchOptions"
      ></v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { createTagManagerEvent } from "@/utils";
export default {
  props: ["date", "downPaymentDates"],
  model: {
    prop: "date",
    event: "updateDate",
  },
  data() {
    return {
      dialog: false,
      oldHistory: null,
      touchOptions: {
        move: null,
        start: null,
        end: null,
        right: null,
        up: null,
        down: null,
      },
    };
  },
  methods: {
    updateDate(date) {
      this.$emit("updateDate", date);
    },
    checkAllowedDates(date) {
      return this.downPaymentDates.includes(date);
    },
    blockBackButton() {
      this.dialog = false;
      (e) => {
        e.preventDefault();
      };
    },
    addBlockBackButton() {
      this.oldHistory = JSON.parse(JSON.stringify(history.state));
      history.pushState(null, null);
      window.addEventListener("popstate", this.blockBackButton);
    },
    removeBackButtonBlock() {
      window.addEventListener("popstate", () => {});
    },
  },
  computed: {
    sortedDates() {
      const dates = this.downPaymentDates;
      return dates.sort();
    },
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.addBlockBackButton();
        createTagManagerEvent({
          event: "calendarOpened",
        });
      } else {
        this.removeBackButtonBlock();
        createTagManagerEvent({
          event: "calendarClosed",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.qj-date-picker {
  touch-action: none;
  .v-date-picker-header {
    .v-btn--disabled {
      display: none !important;
    }
  }
  .v-date-picker-table {
    touch-action: none;
    .v-btn {
      &--active {
        font-weight: 600;
        font-size: 14px;
      }
      &--disabled {
        text-decoration: line-through;
        font-weight: 400;
      }
      font-weight: 600;
    }
  }
}
.dialog-date-picker {
  .v-dialog {
    margin: 0px;
  }
}
</style>
