<template>
  <div>
    <v-fade-transition>
      <div v-show="dialog" class="qj-bg-dialog" @click="close"></div>
    </v-fade-transition>
    <v-dialog
      fullscreen
      scrollable
      persistent
      transition="dialog-bottom-transition"
      v-model="dialog"
    >
      <v-card>
        <v-card-title>
          <v-col cols="12" class="pt-y text-center" style="position: relative">
            <img
              class="attention-icon"
              src="@/assets/img/svg/aviso.svg"
              alt="image_attention"
              width="70"
              height="70"
            />
            <h3 class="qj-h3">Detalhes do contrato com pendências</h3>
            <v-btn icon class="qj-btn-close-dialog" @click="close">
              <v-icon>far fa-times-circle</v-icon>
            </v-btn>
          </v-col>
        </v-card-title>
        <v-card-text class="-4">
          <v-col cols="12" class="box-infos" v-if="contractDetails.documents">
            <v-data-table mobile-breakpoint="1980" hide-default-footer :headers="makeHeaders" :items="contractDetails.documents"></v-data-table>

            <!-- <div v-for="(contract, index) in contractDetails.documents" :key="index">
              {{ contract }}
            </div> -->
          </v-col>
          <v-col cols="12" class="box-infos" v-else>
            <div>
              <h4>Valores, taxas e juros</h4>
              <ul class="grey-border">
                <li>
                  <span>Saldo atual</span>
                  <span>{{ contractDetails.original_debt | money }}</span>
                </li>
                <li>
                  <span>Valor em Atraso</span>
                  <span>{{ contractDetails.due_debt | money }}</span>
                </li>
                <li>
                  <span>Valor da parcela</span>
                  <span>{{ contractDetails.installment_value | money }}</span>
                </li>
                <li>
                  <span>Taxa do contrato a.m</span>
                  <span>{{ contractDetails.contract_rate | percent }}</span>
                </li>
                <li>
                  <span>Juros por atraso</span>
                  <span>{{ contractDetails.interest_delay | money }}</span>
                </li>
              </ul>
            </div>
            <div>
              <h4>Parcelas e forma de pagamento</h4>
              <ul class="grey-border">
                <li>
                  <span>Número de parcelas em atraso</span>
                  <span>{{ contractDetails.overdue_intallments }}</span>
                </li>
                <li>
                  <span>Número de parcelas a vencer</span>
                  <span>{{ contractDetails.due_intallments }}</span>
                </li>
                <li>
                  <span>Forma de pagamento</span>
                  <span>{{
                    contractDetails.payment_method | proposalPaymentMethod
                  }}</span>
                </li>
              </ul>
            </div>
            <div>
              <h4>Datas</h4>
              <ul>
                <li>
                  <span>Data de vencimento das parcelas</span>
                  <span>{{ contractDetails.due_date | brazilianDate }}</span>
                </li>
                <li>
                  <span>Data de emissão do contrato</span>
                  <span>{{
                    contractDetails.contract_start_date | brazilianDate
                  }}</span>
                </li>
                <li>
                  <span>Data de término do contrato</span>
                  <span>{{
                    contractDetails.contract_final_date | brazilianDate
                  }}</span>
                </li>
              </ul>
            </div>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["dialog"],
  methods: {
    close() {
      this.$emit("close");
    },
  },
  computed: {
    ...mapGetters("negotiation", { contractDetails: "getContractDetails" }),
    makeHeaders(){
      function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
      if(this.contractDetails.documents[0]){
        return Object.keys(this.contractDetails.documents[0]).map(columnName => {
          return {
            text: capitalizeFirstLetter(columnName.replace('_', ' ')),
            value: columnName,
            sortable: false
          }
        })
      } else return {}
    }
  }
};
</script>

<style lang="scss" scoped>
.qj-h3 {
  word-break: break-word;
}

.box-infos {
  color: #000000;
  border-radius: 7px;
  padding: 8px 8px 0px;
  border: 1px solid #e5e5e5;

  ul {
    padding-left: 0px;
    list-style-type: none;
    margin-top: 15px;
    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      span:nth-child(2) {
        text-align: end;
        margin-left: 20px;
        font-weight: 700;
      }
    }
  }
  .grey-border {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 15px;
  }
}
</style>
