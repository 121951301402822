<template>
  <div>
    <v-fade-transition>
      <div v-show="dialog" class="qj-bg-dialog" @click="$emit('close')"></div>
    </v-fade-transition>
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="dialog"
      persistent
    >
      <v-card class="text-center">
        <v-card-title class="justify-center">
          <img src="@/assets/img/svg/aviso.svg" alt="image aguarde" />
        </v-card-title>
        <v-card-text>
          <div class="" v-html="msg"></div>
        </v-card-text>
        <v-card-actions class="">
          <v-btn
            block
            class="rounded-lg qj-btn qj-bg-primary qj-p-btn mb-4"
            @click="reloadApp()"
            >Entendi</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["title", "dialog"],
  created() {
    this.msg = this.getAlert("pin-no-attempts-remaining")?.content || "";
  },
  data() {
    return {
      msg: null,
    };
  },
  methods: {
    reloadApp() {
      window.location.href = window.location.origin;
    },
  },
  computed: {
    ...mapGetters("alert", {
      getAlert: "getAlert",
    }),
  },
};
</script>

<style scoped>
</style>
