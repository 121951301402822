<template>
    <v-dialog v-model="dialog" persistent max-width="310">
      <v-card class="text-center">
        <v-card-title class="justify-center">
          <h3 class="qj-h3">Baixar contrato</h3>
          <v-btn
            icon
            class="qj-btn-close-dialog"
            style="top: 9px; right: 2px"
            @click="close"
          >
            <v-icon>far fa-times-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-3">
          <img src="@/assets/img/svg/download.svg" alt="Baixar" class="qj-download" />
          <p class="qj-card-text">
            Para abrir o contrato,
            <b>digite os números do seu {{ me.type == "PERSONAL" ? "CPF" : "CNPJ" }}</b>
            sem ponto e traço.
          </p>
          <v-btn
            block
            class="rounded-lg qj-btn qj-bg-primary qj-p-btn"
            :loading="loading"
            :disabled="loading"
            :href="contractUrl"
            target="_blank"
            id="btn__download__contract"
          >
            <div style="margin-right: auto">Baixar contrato</div>
            <v-icon style="font-size: 20px">fas fa-file-download</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
import store from "@/store";
import serviceDeal from "@/services/deal";

export default {
  props: ["dialog", "id"],
  data: () => ({
    me: store.getters["negotiation/getMe"],
    loading: false,
    contractUrl: ""
  }),
  mounted(){
    this.getContractUrl();
  },   

  methods: {
    close() {
      this.$emit("close");
    },
    getContractUrl() {
      this.loading = true;
      serviceDeal
        .contract(this.id)
        .then((res) => {
          this.loading = false;
          if (res.data) this.contractUrl = res.data.url;
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.qj-download {
  height: 100px;
  width: 100px;
}
</style>
