<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="310">
      <v-card class="text-center">
        <v-card-title class="justify-center pt-3 pb-4">
          <h3 class="qj-h3">C.E.T</h3>
          <v-btn
            icon
            class="qj-btn-close-dialog"
            style="top: 9px; right: 2px"
            @click="close"
          >
            <v-icon>far fa-times-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pl-3 pr-3 pb-3">
          <p class="qj-p">
            O Custo Efetivo Total é calculado considerando os fluxos referentes às
            liberações e aos pagamentos previstos, incluindo todos os encargos, as
            amortizações, juros, prêmio de seguro e tarifas, quando for o caso, bem como
            qualquer outro custo ou encargo cobrado em decorrência da operação.
          </p>
          <p class="qj-p">
            O CET somente será aplicável para as confissões de dívidas firmadas com
            microempresas e empresas de pequeno porte, de que trata a Lei Complementar nº
            123, de 14 de dezembro de 2006.
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["dialog"],
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__actions {
  box-shadow: 0em 1em 1em 0.5em black;
  margin-bottom: 1em;
  padding-bottom: 2em;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  button {
    width: 25em;
  }
}
.v-card.v-sheet {
  border-radius: 10px !important;
}
</style>
