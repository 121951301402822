<template>
  <v-card-text>
    <h2 class="qj-h4 text-center">
      Qual o motivo da sua nota?
    </h2>
    <div class="qj-p ma-auto content-container">
      <p class="qj-card-text mt-2 text-center">Nos ajude a melhorar ainda mais!</p>
      <v-col cols="12" class="pt-2 flex-content">
        <div class="document-container">
          <v-textarea v-model="comment" dense outlined required rows="3" class="qj-textarea" placeholder="Comente aqui"
            data-hj-allow></v-textarea>
          <v-btn block :disabled="!Number.isInteger(npsValue)" class="rounded-lg qj-btn qj-bg-primary qj-p-btn mt-3"
            @click="submitNps()">
            Enviar
          </v-btn>
        </div>
      </v-col>
    </div>
  </v-card-text>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { createTagManagerEvent } from '@/utils'
export default {
  data: () => ({
    comment: "",
  }),
  computed: {
    ...mapGetters("nps", ["npsValue"])
  },
  methods: {
    ...mapActions("nps", ["sendNps"]),
    submitNps() {
      let event = this.comment ? 'npsSubmittedWithComment' : 'npsSubmittedWithoutComment';

      createTagManagerEvent({ event });

      this.sendNps(this.comment);
    }
  },
};
</script>

<style lang="scss" scoped>
.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.qj-textarea {
  height: 91px;
  font-size: 14px;
  font-style: italic;
}
</style>
