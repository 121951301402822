<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="310">
      <v-card class="text-center">
        <v-card-title class="justify-center align-cnter">
          <div class="svg-wrapper" v-if="icon">
            <img class="qj-svg" :src="icon" alt="Encaminhando" width="70" />
          </div>
          <div class="svg-wrapper" v-else>
            <img
              class="qj-svg"
              src="@/assets/img/svg/proposal.svg"
              alt="Encaminhando"
              width="70"
            />
            <img
              class="qj-svg"
              src="@/assets/img/svg/progresso.svg"
              alt="proposta"
              style="width: 60px; margin: 0 6px"
            />
            <img
              src="@/assets/img/svg/creditor.svg"
              alt="credor"
              width="70"
              class="qj-svg"
            />
          </div>
        </v-card-title>
        <v-card-text>
          <div class="mb-4">
            <span v-html="title" class="qj-card-text"> </span>
          </div>
          <p class="qj-card-text">
            <span v-if="msg" v-html="msg"></span>
            <span v-else-if="creditorProfile">
              Estamos encaminhando a sua proposta para
              {{ creditorProfile.gender == "MALE" ? "o" : "a" }}
              <b>{{ creditorProfile.long_name }}</b
              >.
            </span>
            <span v-else> Estamos encaminhando a sua proposta. </span>
          </p>
          <v-progress-circular
            v-if="!errorConfirm && !msg"
            indeterminate
            class="qj-color-primary"
            :size="50"
          ></v-progress-circular>

          <v-progress-circular
            v-else-if="timer"
            indeterminate
            class="qj-color-primary"
            :size="50"
            ><h3 class="text-weight-bold">{{ timer }}</h3></v-progress-circular
          >

          <v-btn
            v-else
            width="100%"
            class="qj-btn qj-bg-primary qj-p-btn"
            @click="understood = true"
            >Entendi</v-btn
          >

          <v-alert v-if="errorConfirm" type="error" text :icon="false">
            <div style="font-size: 14px">
              Não foi possível confirmar a proposta
            </div>

            <v-btn @click="confirmProposal" color="primary" class="mt-4">
              Tentar novamente
            </v-btn>
          </v-alert>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import * as Sentry from "@sentry/browser";
import store from "@/store";
import { mapActions, mapGetters } from "vuex";
import util from "@/assets/js/util";
import serviceUtil from "@/services/util";
import serviceCustomer from "@/services/customer";
import serviceProposal from "@/services/proposal";
import {
  ConfirmProposalError,
  SaveAddressError,
  SaveContactError,
} from "@/errors";

/** Tempo de espera para verificar se a negociação foi aceita. */
const WAITING_TIME = 16 * 1000;

export default {
  props: ["dialog", "contacts", "form", "addresse", "proposal"],
  data: () => ({
    understood: false,
    msg: null,
    icon: false,
    timer: false,
    title: `<b>Aguarde um instante...</b><br />`,
    creditorProfile: null,
    errorConfirm: false,
  }),
  watch: {
    dialog() {
      if (this.dialog) {
        store.getters["negotiation/getDeals"].forEach((deal) => {
          let tempDeal = deal.proposals?.find((p) => p.id == this.form.id);
          if (typeof tempDeal !== "undefined") this.deal = deal;
        });

        if (this.deal) {
          let creditor = this.deal.creditor || {};
          this.creditorProfile = this.deal.creditor_profile || creditor.profile;
        }

        if (!util.isEmpty(this.addresse)) {
          this.saveAddresses();
        } else {
          this.saveContacts();
        }
      }
    },
  },
  mounted() {},
  methods: {
    ...mapActions("negotiation", ["ActionsDeals", "ActionsMe", "getAgreement", "getPrePayments"]),
    ...mapActions("contact", ["saveWhatsapp"]),
    changeDialogToWaiting() {
      this.msg = `Sua proposta <b>foi encaminhada</b> e precisará de um tempo maior de análise. Assim que tivermos um retorno ${
        this.creditorProfile
          ? ` ${this.creditorProfile?.gender == "MALE" ? "do" : "da"}`
          : ""
      } <b>${this.creditorProfile?.long_name ?? ""} avisaremos você </b>`;
      this.icon = require("@/assets/img/svg/proposalWaiting.svg");
    },
    changeDialogToConfirmed() {
      this.title = "<h2> Parabéns </h2>";
      let byCreditor = this.creditorProfile
        ? this.creditorProfile?.gender == "MALE"
          ? "pelo"
          : "pela"
        : "";

      let messageEnd = 'quitação';
      let proposal = this.proposal;

      if (proposal?.agreement_type && proposal?.agreement_type == "REGULARIZE") {
        messageEnd = "regularização";
      } else if (proposal?.alias && proposal?.alias.includes("Atualização")) {
        messageEnd = "atualização";
      }

      if (proposal?.alias && proposal?.alias.includes("arcelas")) {
        messageEnd += " de suas parcelas";
      } else if (proposal?.alias && proposal?.alias.includes("arcela")) {
        messageEnd += " de sua parcela";
      }

      this.msg = `Sua proposta foi <b>aprovada</b> ${byCreditor} 
        <b>${this.creditorProfile?.long_name ?? this.creditorProfile?.short_name}</b> 
        e agora você pode começar sua jornada rumo à ${messageEnd}`;

      this.icon = require("@/assets/img/svg/proposalAgreed.svg");
    },
    async changeDialogToError() {
      this.icon = require("@/assets/img/svg/proposalError.svg");
      this.title = "";
      this.msg = `<b> Sua proposta não pode ser aprovada no momento!</b> <br> <br>
        Estamos redirecionando você para que escolha outra opção...
      `;
      await this.countdown();
      this.$router.push({ name: "Deals" });
    },
    countdown() {
      return new Promise((res) => {
        this.timer = 5;
        const interval = setInterval(() => {
          this.timer -= 1;
          if (this.timer == 0) {
            clearInterval(interval);
            res();
          }
        }, 1000);
      });
    },
    confirmProposal() {
      this.errorConfirm = false;

      if (!util.isEmpty(this.addresse)) {
        this.saveAddresses();
      } else {
        this.saveContacts();
      }
    },

    /**
     * Salva os parâmetros do Google Analytics para a proposta confirmada.
     */
    saveProposalMarketing() {
      let analytics = store.getters["authentication/getAnalytics"] ?? this.$cookies.get('last_analytics')

      if (!analytics) {
        return;
      }

      analytics.proposal_id = this.form.id;

      console.info("Salvando as informações do Google Analytics.");

      serviceUtil
        .proposalMarketing(analytics)
        .then(() => {
          console.info("Informações do Google Analytics salvas.");
        })
        .catch((error) => {
          console.error(
            "Não foi possível salvar as informações do Google Analytics.",
            error
          );
        });
    },
    saveAddresses() {
      console.debug("Adicionado os endereços");
      this.addresse.status = "CONFIRMED";

      serviceCustomer
        .addresses({ addresses: [this.addresse] })
        .then((res) => {
          console.debug("Endereço adicionado:", res.data);
          this.saveContacts();
        })
        .catch((error) => {
          console.error("Não foi possível adicionar os endereços.", error);
          this.errorConfirm = true;
          Sentry.captureException(new SaveAddressError());
        });
    },
    saveContacts() {
      console.debug("Adicionado os contatos");
      serviceCustomer
        .contacts(this.contacts)
        .then((res) => {
          console.debug("Contato adicionado:", res.data);
          this.next();
        })
        .catch((error) => {
          console.error("Não foi possível adicionar os contatos.", error);
          this.errorConfirm = true;
          Sentry.captureException(
            new SaveContactError({
              axiosError: error,
              message: "Não foi possível adicionar os contatos.",
            })
          );
        });
    },
    waitForDecision() {
      return new Promise((res) => {
        const interval = setInterval(() => {
          if (this.understood) {
            res();
            clearInterval(interval);
          }
        }, 500);
      });
    },
    next() {
      if (
        !store.getters["negotiation/getMe"].contacts.filter(
          (i) =>
            (i.status == "CREATED" || i.status == "CONFIRMED") &&
            i.type == "EMAIL"
        ).length
      ) {
        this.ActionsMe();
      }

      try {
        this.saveWhatsapp('tela_confirmacao_proposta');
      } catch (error) {
        console.error("Não foi possível salvar o WhatsApp.", error);
        Sentry.captureException(
          new SaveContactError({
            axiosError: error,
            message: "Não foi possível salvar o WhatsApp",
          })
        );
      }

      console.debug("Atualizando a proposta", this.form.id);

      serviceProposal
        .update(this.form, true)
        .then((res) => {
          console.debug("Proposta atualizada.");

          this.saveProposalMarketing();

          setTimeout(() => {
            console.debug("Buscando as negociações.");
            const dealId = this.proposal?.deal_id
            this.getAgreement(dealId)
              .then(async (res) => {
                console.debug("Negociações carregadas.");
                this.deal = res

                let has_nps_answer = true;
                let nps_answers =
                  store.getters["negotiation/getMe"].nps_answers;
                if (
                  typeof nps_answers === "undefined" ||
                  !(nps_answers.filter((i) => i.nps_id == 1).length > 0)
                ) {
                  has_nps_answer = false;
                }

                if (!this.deal) {
                  console.debug("Negociação não encontrada.");

                  this.$router.push({ name: "Deals" }).catch((failure) => {
                    this.skipIfNavigationFailureIsGeneric(failure);
                  });

                  return;
                }    
                console.debug(
                  "Status da negociação",
                  this.deal.id,
                  ":",
                  this.deal.status
                );
                if(this.deal.active_proposal?.parcele_ja_proposal_id){
                  this.changeDialogToWaiting();
                  await this.waitForDecision();
                  this.$router
                    .push({
                      name: "Home",
                      query: {
                        view: 'acordos',
                        deal_id: window.btoa(this.deal.id),
                      },
                    })
                    .catch((failure) => {
                      this.skipIfNavigationFailureIsGeneric(failure);
                    });
                    return
                }
                else if (["STARTED", "WAITING"].indexOf(this.deal.status) != -1) {
                  this.changeDialogToWaiting();
                  await this.waitForDecision();
                  this.$router
                    .push({
                      name: "ProposalStatus",
                      query: {
                        id: window.btoa(this.form.id),
                        new: !has_nps_answer,
                      },
                      params: {
                        prePayment: this.prePayment,
                      },
                    })
                    .catch((failure) => {
                      this.skipIfNavigationFailureIsGeneric(failure);
                    });
                } else if (["AGREED"].indexOf(this.deal.status) != -1) {
                  this.changeDialogToConfirmed();
                  await this.waitForDecision();
                  this.$router
                    .push({
                      name: "HomeActiveAgreement",
                      query: {
                        id: window.btoa(this.form.id),
                        new: !has_nps_answer,
                      },
                      params: {
                        prePayment: this.prePayment,
                      },
                    })
                    .catch((failure) => {
                      this.skipIfNavigationFailureIsGeneric(failure);
                    });
                }
              })
              .catch((error) => {
                console.error("Não foi possível consultar as dívidas.", error);

                window.location.reload();
              });
          }, WAITING_TIME);
        })
        .catch((error) => {
          const confirmProposalError = new ConfirmProposalError({
            axiosError: error,
          });
          console.error(
            "Não foi possivel atualizar a proposta.",
            confirmProposalError
          );
          this.changeDialogToError();
          Sentry.captureException(confirmProposalError);
        });
    },
  },
  computed: {
    ...mapGetters("parceleJa", {
      prePayment: "getPrePayment",
    }),
  },
};
</script>

<style lang="scss" scoped>
.qj-svg {
  @media (max-width: 320px) {
    height: 55px;
    width: 55px;
  }
}
.svg-wrapper {
  display: flex;
}
</style>
