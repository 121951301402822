<template>
  <v-dialog v-model="dialog" persistent max-width="310">
    <v-card class="text-center">
      <v-card-title class="justify-center">
        <img src="@/assets/img/svg/aviso.svg" alt="image aguarde" />
      </v-card-title>
      <v-card-text>
        <h3 class="qj-h3 mb-3">Aviso importante</h3>
        <p class="mb-5 qj-p" v-html="msg"></p>
        <v-btn
          block
          class="rounded-lg qj-btn qj-bg-primary qj-p-btn"
          @click="next"
          >Entendi</v-btn
        >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import util from "@/assets/js/util";
import { mapGetters } from "vuex";

export default {
  props: ["dialog", "query", "contactParams"],
  data: () => ({
    msg: "",
  }),
  async created() {
    if (!this.query || !this.query.id) {
      return;
    }

    let dealId = util.base64Decode(this.query.id);
    let deal = util.getDealByProposalId(dealId);

    if (!deal) {
      console.debug("Negociação não encontrada.");
      return;
    }

    this.msg =
      this.getAlert("credit-card-info", deal.creditor.id)?.content || "";
  },
  watch: {
    dialog() {
      if (this.dialog && !this.msg) this.next();
    },
  },
  methods: {
    next() {
      this.$emit("step");
      this.$router
        .push({
          name: "NegotiationConfirmDeal",
          query: this.query,
          params: this.contactParams,
        })
        .catch((failure) => {
         this.skipIfNavigationFailureIsGeneric(failure)
        });
    },
  },
  computed: {
    ...mapGetters("alert", {
      getAlert: "getAlert",
    }),
  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__text {
  padding: 0 19px 20px;
}

.v-dialog__content .v-dialog:not(.v-dialog--fullscreen) {
  max-height: initial !important;
}
</style>
