<template>
  <div>
    <v-row class="qj-row-main-social">
      <v-col cols="12">
        <div class="qj-card">
          <h1 class="qj-h2 mb-2">Não há débitos</h1>
          <p class="mb-0 qj-p">
            <span v-if="client && client.type == 'PERSONAL'">
              <b>{{client.name.split(" ")?.[0]}}</b>, no
            </span>
            <span v-else>No</span>
            momento você não possui nenhum
            débito para negociação com a QuiteJá. Mas não se preocupe, assim que for
            possível lhe ajudar, entraremos em contato, combinado?
          </p>
        </div>
      </v-col>
      <v-col cols="12" class="text-center">
        <p class="mb-2 qj-p">Ficou com alguma dúvida?</p>
        <a href="https://quiteja.com.br/contato" style="text-decoration-line: none">
          <v-btn class="rounded-lg qj-btn qj-bg-secondary-light" style="padding: 0 50px">
            Entre em contato
          </v-btn>
        </a>
      </v-col>
      <!-- Social mídia -->
      <v-col cols="12" class="text-center pb-4" style="padding-top: 56px">
        <p class="qj-p" style="max-width: 250px; margin: auto; padding-bottom: 5px">
          Aproveite para nos conhecer!<br />Nos acompanhe nas redes sociais.
        </p>
        <div class="text-center pt-1 medias" style="line-height: initial">
          <a href="https://www.facebook.com/QuiteJa/" target="_blank">
            <img src="@/assets/img/midias/facebook.svg" alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/quite.ja/" target="_blank">
            <img src="@/assets/img/midias/instagram.svg" alt="Instagram" />
          </a>
          <a href="https://br.linkedin.com/company/quitej%C3%A1" target="_blank">
            <img src="@/assets/img/midias/linkedin.svg" alt="Linkedin" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCAqELLEaB1rF5pV1oNrL2HA"
            target="_blank"
          >
            <img src="@/assets/img/midias/youtube.svg" alt="Youtube" />
          </a>
          <a href="https://quiteja.com.br/blog" target="_blank">
            <img src="@/assets/img/midias/blog.svg" alt="Blog" />
          </a>
        </div>
      </v-col>
    </v-row>
    <qj-footer type="exit" view="sem-dividas" />
  </div>
</template>

<script>
import store from "@/store";
import util from "@/assets/js/util";

export default {
  data: () => ({
    client: util.isEmpty(store.getters["negotiation/getMe"])
      ? store.getters["opportunity/getClient"]
      : store.getters["negotiation/getMe"],
    dialog: false,
  }),
  created() {
    if (!this.client.document) {
      return;
    }

    if (!this.client.type && this.client.document.length == 11) {
      this.client.type = "PERSONAL";
    }
  },
};
</script>

<style lang="scss" scoped>
.medias img {
  margin-left: 12px;
  width: 22px;
  height: 22px;
}
</style>
