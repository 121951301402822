<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent max-width="310">
      <v-card>
        <v-card-title class="justify-center pt-3">
          <h3 class="qj-h3">{{title}}</h3>
        </v-card-title>
        <v-card-text>
          <span class="qj-p">
            {{ text }}
          </span>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="rounded-lg qj-btn qj-bg-primary"
            @click="understood = true">
            Entendi
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      understood: false,
      title: '',
      text: '',
      dialog: false
    }
  },
  computed: {
  },
  methods: {
    async openAndWait({text = '', title = ''} = {}) {
      this.text = text
      this.title = title
      this.dialog = true
      return new Promise(resolve => {
        const interval = setInterval(() => {
          if(this.understood) {
            clearInterval(interval);
            resolve();
          }
        }, 100)
      })
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
