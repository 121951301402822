<template>
  <div class="w-100">
    <v-expansion-panels
      v-model="activePanel"
      v-if="validPayments.length"
      class="agreement-expansion"
    >
      <v-expansion-panel
        class="agreement-panel"
        v-for="payment in validPayments"
        :key="payment.id"
        :id="payment.id"
        :readonly="hasTicket(payment)"
        ref="payments"
      >
        <v-expansion-panel-header
          :hide-actions="hasTicket(payment)"
          class="agreement-expansion pa-0"
        >
          <div class="flex-column">
            <v-row class="payment_status">
              <div
                class="payment_status__chip d-flex align-center justify-center"
                :class="getPaymentColor(payment)"
              >
                <span class="payment_status__text">{{
                  getPaymentStatus(payment)
                }}</span>
              </div>
              <v-chip v-if="payment.pix_code"
                ><v-img
                  class="mr-2"
                  height="20"
                  width="20"
                  src="@/assets/img/svg/pix.svg"
                /><span class="payment_status__text">Pix</span></v-chip
              >
            </v-row>
            <v-row class="flex-row" justify="space-around">
              <v-col
                cols="auto"
                class="d-flex flex-column align-center justify-end"
              >
                <span
                  class="text-caption"
                  v-if="getInstallmentInfo(payment).isInstallment"
                  >Parcela</span
                >
                <span
                  class="text-caption"
                  v-html="getInstallmentInfo(payment).text"
                ></span>
              </v-col>
              <v-col cols="auto" class="d-flex flex-column align-center">
                <span
                  class="text-caption"
                  v-if="activePayment.method == 'DEBIT_CARD'"
                  >Debitado dia</span
                >
                <span class="text-caption" v-else>Vencimento</span>
                <span class="text-caption">
                  <b v-if="payment?.method == 'CREDIT'">Fatura</b>
                  <b v-else>{{ payment.due_date | brazilianDate }}</b>
                </span>
              </v-col>
              <v-col cols="auto" class="d-flex flex-column align-center">
                <span class="text-caption">Valor</span>
                <span class="text-caption"
                  ><b>{{
                    (payment.value + payment?.additional_value) | money
                  }}</b></span
                >
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="margin-expansion">
          <v-row v-if="payment.pix_code" class="">
            <v-col
              v-show="!$vuetify.breakpoint.smAndDown"
              cols="12"
              class="pb-0"
            >
              <canvas :id="`qrcode-${payment.id}`"></canvas>
            </v-col>
            <v-col cols="auto" class="pb-0 px-0">
              <div class="barcode-container px-4 py-4">
                <span
                  class="text-body-2 font-weight-bold flex-shrink"
                  :class="[showFullPixCode ? 'pix_code' : 'pix_code__hidden']"
                >
                  {{ payment.pix_code }}
                </span>
                <span
                  @click="showFullPixCode = !showFullPixCode"
                  class="pix_code__hidden text-caption cursor-pointer font-weight-bold grow blue--text darken-3"
                >
                  {{ showFullPixCode ? "Mostrar menos" : "Mostrar mais" }}
                </span>
              </div>
            </v-col>
            <v-col class="mb-4">
              <div class="d-flex flex-column align-center justify-center">
                <span class="text-body-2 mb-2" v-if="payment.pix_code">Copie e cole seu código e pague no banco de sua preferência</span>
                <div class="d-flex flex-column">
                  <v-btn
                    v-if="pixCopied"
                    @click="copyPix(activePayment)"
                    class="copy-btn rounded-lg qj-btn qj-bg-primary qj-p-btn"
                  >
                    <v-icon small>mdi-check</v-icon>
                    <div>Código copiado</div>
                  </v-btn>
                  <v-btn
                    v-else
                    @click="copyPix(activePayment)"
                    class="copy-btn rounded-lg qj-btn qj-bg-primary qj-p-btn"
                  >
                    <v-icon small>mdi-content-copy</v-icon>
                    <div>Copiar código</div>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row
            v-else-if="payment.barcode || payment.pix_code"
            class="mb-6 justify-center"
          >
            <div
              class="pt-4 d-flex flex-column align-center justify-center text-center barcode-container"
            >
              <span class="text-body-2 font-weight-bold">{{
                payment.barcode
                  | VMask(
                    "#####.##### #####.###### #####.###### # ##############"
                  )
              }}</span>
              <barcode
                v-if="$vuetify.breakpoint.mdAndUp"
                :value="formatBarCode(payment.barcode)"
                marginTop="1"
                displayValue="false"
                background="#d6fced"
                :class="{ 'w-100': $vuetify.breakpoint.mdAndUp }"
              >
                Não foi possível gerar o código de barras
              </barcode>

              <div>
                <div><span>Copie e cole seu código e pague no banco da sua preferência</span></div>
                <v-btn
                  v-if="copiedBarCode"
                  @click="lickCopy()"
                  class="copy-btn rounded-lg qj-btn qj-bg-primary qj-p-btn"
                >
                  <v-icon small>mdi-check</v-icon>
                  <div>Código copiado</div>
                </v-btn>
                <v-btn
                  v-else
                  @click="lickCopy()"
                  class="copy-btn rounded-lg qj-btn qj-bg-primary qj-p-btn"
                >
                  <v-icon small>mdi-content-copy</v-icon>
                  <div>Copiar código</div>
                </v-btn>
              </div>
            </div>
          </v-row>
          <v-row class="flex-column align-center">
            <a
              v-if="payment?.boleto?.url && showBillAlert(payment)"
              class="card-a text-decoration-underline"
              @click="$emit('openBillAlert', payment)"
              href="javascript:void(0);"
              id="btn__billet__alert"
              >Baixar boleto</a
            >
            <a
              v-if="payment?.boleto?.url && !showBillAlert(payment)"
              class="card-a"
              @click="$emit('downloadBoleto', payment.id)"
              :href="payment?.boleto?.url"
              target="_blank"
              id="btn__download__billet"
              >Baixar boleto</a
            >
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import util from "@/assets/js/util";
import serviceEvent from "@/services/event";
import QRCode from "qrcode";
import { createTagManagerEvent } from "@/utils";

export default {
  props: {
    deal: {
      type: Object,
      default: null,
    },
    isPaymentMethodVisible: {
      type: Function,
    },
    isOperationsValueVisible: {
      type: Function,
    },
    maxDebit: {
      type: Function,
    },
  },
  data() {
    return {
      showFullPixCode: false,
      copiedBarCode: false,
      activePayment: null,
      pixCopied: false,
      downPayment: null,
      activePanel: 0,
      dealPaymentStatus: {
        OK: {
          text: "ACORDO EM DIA",
          color: "#A9E4EB",
          icon: "mdi-check-circle-outline",
        },
        DUE_TODAY: {
          text: "ACORDO VENCE HOJE",
          color: "#FF8533",
          icon: "mdi-alert-circle-outline",
        },
        IN_DEBIT: {
          text: "ACORDO EM ATRASO",
          color: "#FF5050",
          icon: "mdi-clock-time-three-outline",
        },
      },
      tolerances: {},
    };
  },
  created() {
    if (this.validPayments) {
      this.activePayment = this.validPayments.find(
        (payment) => payment.status == "PENDING"
      );
    }

    let configPrefix = util.getPrefix(this.deal.id);
    if (!configPrefix) return false;
    this.tolerances = {
      DOWN_PAYMENT:
        util.getConfig()[configPrefix + "down_payment_tolerance_days"] ?? null,
      INSTALLMENT:
        util.getConfig()[configPrefix + "installment_tolerance_days"] ?? null,
    };
  },
  async mounted() {
    if (this.activePayment) {
      let actual = this.validPayments.findIndex(
        (payment) => payment.id == this.activePayment.id
      );
      this.activePanel = actual;
      if (actual != -1) {
        setTimeout(() => {
          document.getElementById(this.activePayment.id)?.scrollIntoView({
            block: "center",
            inline: "nearest",
            behavior: "smooth",
          });
        }, 1500);
      }
    }
  },
  updated() {
    this.validPayments.forEach((payment) => {
      if (payment.pix_code) {
        this.generrateQrCode(payment);
      }
    });
  },
  methods: {
    getInstallmentInfo(payment) {
      let downPaymentOnPayments = this.validPayments.find(
        (payment) => payment.type == "DOWN_PAYMENT"
      );
      let dealHasDownPayment = this.deal.down_payment > 0;
      let fakeDownPayment = downPaymentOnPayments && !dealHasDownPayment;
      if (this.deal.active_proposal.payment_type === "IN_CASH")
        return { text: "<br><b>À vista </b>", isInstallment: false };
      if (
        dealHasDownPayment &&
        !fakeDownPayment &&
        payment == this.validPayments?.[0]
      ) {
        return {
          text: `<br><b style="color: unset">Entrada</b>`,
          isInstallment: false,
        };
      }
      let installmentNumber = fakeDownPayment
        ? payment?.installment_number + 1
        : payment?.installment_number;
      let totalInstallments =
        this.deal.installments_count_1 +
        (this.deal.installments_count_2 ?? 0) +
        (this.deal.installments_count_3 ?? 0) +
        (this.deal.installments_count_4 ?? 0) +
        (this.deal.installments_count_5 ?? 0);
      return {
        text: `<b style="color: unset">${installmentNumber}</b> de <b style="color: unset">${totalInstallments}</b>`,
        isInstallment: true,
      };
    },

    generrateQrCode(payment) {
      if (!payment.pix_code) return;
      QRCode.toCanvas(
        document.getElementById(`qrcode-${payment.id}`),
        payment.pix_code,
        { scale: 3, margin: 3 },
        function (err, canvas) {
          if (err) throw err;
        }
      );
    },
    getExpireDay(date) {
      let dueDate = new Date(date);
      let difference = dueDate.getTime() - new Date().getTime();
      return Math.ceil(difference / (1000 * 3600 * 24));
    },
    getPaymentStatus(payment) {
      let { boleto, boleto_id, id, method, status, type } = payment;

      if (status === "PAYED" && (method === "DEBIT_CARD" || type === "CREDIT"))
        return `Pagamento efetuado`;
      if (method === "CREDIT") return `Parcelado no cartão`;
      if (status === "PAYED") return `Boleto Pago`;
      if (method === "DEBIT_CARD") return `Débito automático`;
      if ((!boleto || !boleto_id) && method == "BOLETO")
        return `Aguardando boleto`;

      let days = this.getExpireDay(payment?.due_date);

      if (payment && days > 0 && this.activePayment.id === id) {
        return `Aguardando o pagamento`;
      } else if (payment && days > 0) {
        return `Aguardando o pagamento`;
      } else if (payment && days == 0) {
        return `Vence hoje`;
      } else {
        return `Pagamento atrasado`;
      }
    },
    getPaymentColor(payment) {
      if (payment.status === "PAYED") return "tag-payed";
      let { boleto, boleto_id } = payment;
      if (!boleto || !boleto_id) {
        return "tag-grey";
      }

      let days = this.getExpireDay(payment.due_date);
      if (payment && days > 0 && this.activePayment.id === payment.id) {
        return "tag-awaiting";
      } else if (payment && days > 0) {
        return "tag-awaiting";
      } else if (payment && days == 0) {
        return "tag-yellow";
      } else {
        return "tag-red";
      }
    },
    lickCopy() {
      createTagManagerEvent({
        event: "dealCopiedBoletoBarcode",
      });
      let input = this.activePayment.barcode;
      navigator.clipboard
        .writeText(input)
        .catch((error) => console.error(error));
      this.copiedBarCode = true;
      setTimeout(() => {
        this.copiedBarCode = false;
      }, 5000);
      serviceEvent
        .create({
          deal_id: this.deal.id,
          detail: "Pagamento: " + this.activePayment.id,
          message: "Código de barras copiado",
          type: "BARCODE_COPIED",
        })
        .catch((error) => {
          console.error("Não foi possível criar o evento.", error);
        });
    },
    copyPix(payment) {
      createTagManagerEvent({
        event: "dealCopiedBoletoBarcode",
      });
      let input = payment.pix_code;
      this.pixCopied = true;
      navigator.clipboard
        .writeText(input)
        .catch((error) => console.error(error));
      setTimeout(() => (this.pixCopied = false), 3000);
    },
    formatBarCode(barCode) {
      let barCodeString = barCode.replace(/[^\d]/g, "");
      return barCodeString;
    },
    hasTicket(payment) {
      return payment.status === "PAYED" || !payment.boleto_id;
    },
    showBillAlert(payment) {
      let configPrefix = util.getPrefix(this.deal.id);
      if (!configPrefix) return false;

      let doShowAlert =
        util.getConfig()[configPrefix + "show_billet_alert"] ?? false;

      return doShowAlert && payment?.boleto?.encrypted;
    },
  },
  computed: {
    activePaymentIndex() {
      return this.deal.payments.findIndex(
        (payment) => payment.id == this.activePayment.id
      );
    },
    validPayments() {
      return this.deal.payments.filter(
        (payment) => payment.status !== "DISCARTED"
      );
    },
  },
};
</script>

<style scoped lang="scss">
.w-100 {
  width: 100%;
}
.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.agreement-panel {
  width: 100%;
  border: 1px solid #f0f0f0;
  border-radius: 7px;
  margin-bottom: 12px;
}
.agreement-expansion {
  margin-top: 10px;
  .v-expansion-panel--active
    > .v-expansion-panel-header--active
    .v-expansion-panel-header__icon:not(
      .v-expansion-panel-header__icon--disable-rotate
    )
    .v-icon {
    transform: rotate(-90deg);
  }
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(
    .v-expansion-panels--tile
  )
  > .v-expansion-panel--active {
  border-radius: 7px !important;
}
.theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
  border: none !important;
}
.v-expansion-panel::before {
  box-shadow: none !important;
}

.acordo {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.copy-btn {
  margin-bottom: -15px;
}
.barcode-container {
  background: #d6fced;
  width: 100%;
}
.deal-status {
  font-size: 10px;
  text-align: center;
}
.payment_status {
  display: inline-flex;
  display: -webkit-inline-box;
  margin-top: 12px !important;
  margin-left: 12px !important;
  text-align: start !important;
  &__chip {
    padding: 5px 8px;
    border-radius: 100px;
    text-transform: math-auto;
    margin-right: 12px;
    line-height: 15px;
  }
  &__text {
    font-weight: 800;
    font-size: 12px;
    text-rendering: geometricPrecision;
  }
}
.agreement__logo {
  justify-content: space-between;
}

.card-logo {
  padding: 12px 12px 7px 12px !important;
  justify-content: center;
}
.card-a {
  color: #00606a;
  font-size: 14px;
  font-weight: 800;
  line-height: 17px;
  margin-bottom: -4px !important;
}

.assignor-band {
  background-color: #f6f6f6;
  position: absolute;
  width: 94%;
  border-radius: 7px;
  padding: 7px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span:last-child {
    display: flex;
    align-items: center;
  }
  img {
    min-height: 16px;
    max-height: 20px;
  }
  @media (max-width: 320px) {
    width: 90%;
    line-height: 1.2;
    align-items: center;
    span:last-child {
      max-width: 75%;
      text-align: end;
      justify-content: end;
    }
  }
}

.no-bg {
  &::before {
    background-color: transparent;
  }
}
.qj-tag-discount {
  margin-top: 2.5px;
}
.tab {
  overflow: hidden;
  background-color: #e5e5e5;
  border-radius: 100px;
  margin-top: 7px;
}

.tab button {
  width: 50%;
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 13px 0;
  /* transition: 0.3s; */
  font-size: 14px;
  line-height: 17px;
}

.tab button.active {
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  margin: 5px;
  padding: 8px 0;
  width: calc(50% - 10px);
  font-weight: bold;
}

.tabcontent {
  margin-top: 10px;
  display: none;
}

.card-logo {
  padding: 12px 12px 7px 12px !important;
}

.card-title-deal {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  @media (max-width: 320px) {
    font-size: 11px;
  }
}

.card-p {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
}

.card-a {
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  color: #00606a;
}

.card-details {
  text-align: right;
}

.card-value-deal {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: right;
  color: #212121;
  @media (max-width: 320px) {
    font-size: 12px;
  }
}

.card-value-deal-more-discount {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;
  text-align: right;
  color: #212121;
  @media (max-width: 320px) {
    font-size: 14px;
  }
}

.card-description-discount {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: right;
  color: #212121;
}

.card-btn div {
  margin-right: auto;
}

.tag-grey {
  background: #e5e5e5;
  color: #000000;
}
.tag-awaiting {
  background: #a9e4eb;
  color: #000000;
}
.tag-green {
  background: #a1e5d5;
  color: #083b2f;
}
.tag-yellow {
  background: #fbd1a2;
  color: #000000;
}
.tag-red {
  background: #ff9494;
  color: #000000;
}
.tag-payed {
  background: #cafce9;
  color: #000000;
}

.qj-card-msg {
  padding: 10px;
  background: #f6f6f6;
  border-radius: 7px;
}

.qj-operation-assignor {
  font-size: 16px;
  font-weight: 500;
}

.assignor-band {
  background-color: #f6f6f6;
  position: absolute;
  width: 94%;
  border-radius: 7px;
  padding: 7px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span:last-child {
    display: flex;
    align-items: center;
  }
  img {
    min-height: 16px;
    max-height: 20px;
  }
  @media (max-width: 320px) {
    width: 90%;
    line-height: 1.2;
    align-items: center;
    span:last-child {
      max-width: 75%;
      text-align: end;
      justify-content: end;
    }
  }
}

.no-bg {
  &::before {
    background-color: transparent;
  }
}
.qj-tab {
  font-size: 14px;
  font-weight: 600 !important;
}
.qj-tabs {
  @media (min-width: 600px) {
    width: 520px !important;
    margin-left: -32px;
  }
  @media (max-width: 600px) {
    width: 100vw !important;
    margin-left: -16px;
  }
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}
.qj-tag-discount {
  margin-top: 2.5px;
}
.margin-expansion {
  margin: 0px -24px 10px !important;
}

.pix_code {
  word-break: break-all;
  &__hidden {
    word-break: keep-all;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }
}

span,
b {
  color: unset;
}
</style>