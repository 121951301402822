<template>
  <v-row class="shrink" justify="center" v-if="dialog">
    <v-dialog v-model="dialog" persistent max-width="310">
      <v-card class="text-center">
        <v-card-title class="justify-center">
          <img src="@/assets/img/svg/aviso.svg" />
        </v-card-title>
        <v-card-text>
          <h2 class="black--text font-weight-bold mt-1">Atenção</h2>
          <p class="qj-card-text">
            <br />Sua proposta precisou ser
            <br />atualizada. Aguarde um momento
            <br />enquanto preparamos uma nova
            <br />para você.
          </p>
          <v-btn 
            width="100%"
            class="rounded-lg qj-btn qj-bg-primary btn__accept__proposal"
            @click="close">Entendi</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      dialog2: true
    }
  },
 methods: {
   close() {
     this.$emit('closeReloadProposalDialog')
   }
 },
}
</script>


<style>

</style>