<template>
  <div>
    <!-- <v-snackbar
      :timeout="2000"
      absolute
      top
      right
      tile
      color="red accent-2"
      v-model="error"
    >
      {{ error }}
    </v-snackbar> -->
    <span class="qj-p mb-4"
      >Para prosseguir com o pagamento no cartão de crédito, preencha os
      seguintes dados.
    </span>
    <v-row class="shrink" justify="center" align="center">
      <v-col>
        <div class="credit-card elevation-10 mx-auto">
          <div class="credit-card__front">
            <v-row class="credit-card__front__name pl-6 pt-4">
              <v-col
                ><h4>{{ name?.length > 1 ? name : "NOME COMPLETO" }}</h4></v-col
              >
            </v-row>
            <v-row class="credit-card__front__numbers pl-6">
              <v-col
                ><h3>
                  {{ number?.length > 1 ? number : "#### #### #### ####" }}
                </h3></v-col
              >
            </v-row>
            <v-row class="pr-4">
              <v-col
                class="credit-card__front__due d-flex flex-column align-end"
              >
                <span class="text-caption">validade</span>
                <span class="font-weight-bold ml-6">{{
                  due?.length > 1 ? due : "AAAA/MM"
                }}</span>
              </v-col>
              <v-col
                class="credit-card__front__due d-flex flex-column align-end"
              >
                <span class="text-caption">CCV</span>
                <span class="font-weight-bold">{{
                  ccv?.length > 1 ? ccv : "###"
                }}</span>
              </v-col>
              <v-col class="align-end d-flex justify-end">
                <div class="credit-card__front__type">
                  <v-img
                    max-height="64px"
                    max-width="64px"
                    :src="selectedBrandLogo"
                  ></v-img>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="credit-card__back"></div>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center" align="start">
      <v-col cols="12">
        <v-form ref="form" v-model="valid">
          <v-row
            ><v-col class="py-0" cols="12"
              ><v-text-field
                :rules="rules.email"
                outlined
                dense
                color="#00E58D"
                v-model="mail"
                name="mail"
                placeholder="Email"
                :label="mail?.length > 0 ? 'Email' : null"
              ></v-text-field></v-col
          ></v-row>
          <v-row
            ><v-col class="py-0" cols="12"
              ><v-text-field
                :rules="rules.required"
                outlined
                dense
                color="#00E58D"
                @keydown="removeNumber($event)"
                @input="uppercase(name)"
                v-model="name"
                maxlength="24"
                name="cc-name"
                placeholder="Nome completo"
                :label="name?.length > 0 ? 'Nome completo' : null"
              ></v-text-field></v-col
          ></v-row>
          <v-row
            ><v-col class="py-0" cols="12"
              ><v-text-field
                outlined
                :rules="[...rules.required, ...rules.creditCard]"
                required
                dense
                color="#00E58D"
                v-model="number"
                name="cc-number"
                placeholder="Numero do cartão"
                :label="number?.length > 0 ? 'Numero do cartão' : null"
                validate-on-blur
                v-mask="'#### #### #### ####'"
              ></v-text-field></v-col
          ></v-row>
          <v-row
            ><v-col class="py-0" cols="6">
              <v-text-field
                v-model="due"
                placeholder="Validade"
                :label="due?.length > 0 ? 'Validade' : null"
                :rules="rules.cardExpirationDate"
                prepend-inner-icon="mdi-calendar"
                validate-on-blur
                name="cc-exp"
                v-mask="'####/##'"
                color="#00E58D"
                hint="EX: 2031/01"
                @input="formatDue($event)"
                :error-messages="dueErrorMessage"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="6"
              ><v-text-field
                :rules="[...rules.required, ...rules.ccv]"
                name="cc-ccv"
                placeholder="CCV"
                :label="ccv?.length > 0 ? 'CCV' : null"
                color="#00E58D"
                outlined
                validate-on-blur
                dense
                v-model="ccv"
                v-mask="'###'"
              ></v-text-field
            ></v-col>
            <v-col class="pt-0"
              ><span class="qj-p"
                >Não se procupe, nós não armazenamos os dados do cartão de
                crédito em nossa plataforma.</span
              ></v-col
            >
          </v-row>
        </v-form>
      </v-col>
    </v-row>

    <v-spacer></v-spacer>

    <qj-footer
      :loading="loading"
      :disabled="!valid"
      :type="'next'"
      view="negociacao/credit-card"
      @send="next"
    />
    <div class="d-flex justify-center">
    <!-- <v-alert class="prepayment-error" :dismissible="true" type="error" v-model="prePaymentError.state">{{ prePaymentError.message }}</v-alert> -->

    </div>
  </div>
</template>

<script>
import rules from "@/assets/js/rules";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      rules: rules,
      name: null,
      number: null,
      due: null,
      ccv: null,
      mail: null,
      menu: false,
      modal: false,
      dateFormatted: null,
      valid: false,
      dueErrorMessage: null,
      selectedCreditCardType: null,
      selectedBrandLogo: null,
      selectedProposal: null,
      error: false,
      loading: false,
    };
  },
  async created() {},
  mounted() {
    const creditCardInfo = this.negotiation?.prePaymentCreditCard
    if (creditCardInfo){
      this.mail = creditCardInfo.email
      this.name = creditCardInfo.card_holder_name
    }
    this.$emit("step", 3);
    if (!this.$route.query.id) {
      this.$router.push({ name: "Deals" });
      return;
    }
    let proposalId = window.atob(this.$route.query.id);
    if (!proposalId) {
      this.$router.replace().catch((failure) => {
        this.skipIfNavigationFailureIsGeneric(failure);
      });
    }
    if (!this.deal) {
      this.setSelectedDeal(
        this.deals.find((deal) => {
          return deal.proposals.some((proposal) => proposal.id == proposalId);
        })
      );
    }
    this.selectedProposal =
      this.proposals?.find((proposal) => proposal.id == proposalId) ||
      this.deal.proposals.find((proposal) => proposal.id == proposalId);
  },
  methods: {
    ...mapActions("parceleJa", {
      fetchParceleJaProposals: "getProposals",
      sendPrePayment: "sendPrePayment",
    }),
    ...mapMutations("negotiation", [
      "setSelectedDeal",
      "setNegotiation",
      "setSelectedDealByProposalId",
    ]),
    formatDate(date) {
      if (!date) return null;
      const [year, month] = date.split("-");
      return `${month}/${year}`;
    },
    formatDue(event) {
      if (!event) return;
      const isCreditCardAutoFill =
        /^(0[1-9]|1[0-2])\/(20[2-9]\d|30[0-5]\d)$/.test(event);
      if (isCreditCardAutoFill) {
        this.$nextTick(() => {
          this.due = event.replace(
            /^(0[1-9]|1[0-2])\/(20[2-9]\d|30[0-5]\d)$/,
            "$2/$1"
          );
        });
      }
    },
    uppercase() {
      this.name = this.name.toUpperCase();
      this.name = this.name.replace(/[0-9]/g, "");
    },
    removeNumber(event) {
      if (isFinite(event.key) && event.key !== " ") {
        0;
        event.preventDefault();
      }
      return;
    },
    isValidCreditCardNumber(cardNumber) {
      const sanitizedCardNumber = cardNumber.replace(/[\s-]+/g, "");

      const brands = [
        { name: "Visa", pattern: /^4\d{12}(\d{3})?$/ },
        { name: "Mastercard", pattern: /^(5[1-5]|2[2-7])\d{14}$/ },
        { name: "Hipercard", pattern: /^(38\d{17}|60\d{14})$/ },
        {
          name: "elo",
          pattern:
            /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/,
        },
      ];
      const matchingBrand = brands.find((brand) =>
        brand.pattern.test(sanitizedCardNumber)
      );

      if (matchingBrand) {
        this.selectedBrandLogo = require(`@/assets/img/svg/${matchingBrand.name.toLowerCase()}-logo.png`);
        return matchingBrand.name;
      }
      this.selectedBrandLogo = undefined;
      return null;
    },
    async callPrePayment() {
      const payload = {
        proposal_id: this.selectedProposal.id,
        email: this.mail,
        card_holder_name: this.name,
        card_number: this.number,
        card_expiration_date: this.due,
        card_cvv: this.ccv,
      };
      this.setNegotiation({
        prePaymentCreditCard: payload
      })
      // await this.sendPrePayment(payload);
    },
    async next() {
      if (!this.due) {
        this.dueErrorMessage = ["Insira uma data"];
        return;
      }
      try {
        this.loading = true;
        this.setNegotiation({
          email: this.mail,
        });
        await this.callPrePayment();

        let nextRoute = null;

        if (this.deal.prePayment?.quiteja_status == "WAITING_PIN_VALIDATION") {
          nextRoute = "PinChecker";
        } else {
          nextRoute = "NegotiationConfirmDeal";
        }

        this.$router["push"]({
          name: nextRoute,
          query: {
            id: window.btoa(this.selectedProposal.id),
          },
          params: {
            date: this.$route.params.date,
            proposal: this.selectedProposal,
          },
        });
        this.$emit("step", 4);
      } catch (failure) {
        if (this.isNavigationFailure(failure)) {
          console.error(failure);
        } else {
          this.error = failure.response;
        }
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters("parceleJa", {
      prePaymentError: "prePaymentError",
      proposals: "proposals",
    }),
    ...mapGetters("negotiation", {
      deal: "getSelectedDeal",
      deals: "getDeals",
      negotiation: "getNegotiation",
    }),
  },
  watch: {
    number(val) {
      this.isValidCreditCardNumber(val);
    },
    due(val) {
      if (this.dueErrorMessage) {
        this.dueErrorMessage = null;
      }
      this.dateFormatted = this.formatDate(val);
    },
    ccv(val) {
      if (val?.length === 3) this.$refs.form.validate();
    },
  },
};
</script>

<style lang="scss">
.prepayment-error{
  position: absolute;
  bottom: 20px;
  width: 95%;
}
.credit-card {
  font-family: "ocr-a-regular", sans-serif !important;
  max-width: 350px;
  height: 200px;
  color: white;
  border-radius: 12px;
  background-image: linear-gradient(
    to right,
    rgb(193, 194, 239),
    rgb(112, 112, 214)
  );
  &__front {
    &__numbers h3 {
      font-family: "OCR A Extended", sans-serif !important;
      font-weight: 1000;
      font-size: 20px;
    }
  }
}
</style>