<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="310">
      <v-card class="text-center">
        <v-card-title class="justify-center pt-3 pb-4">
          <h3 class="qj-h3">Alterar conta</h3>
          <v-btn
            icon
            class="qj-btn-close-dialog"
            style="top: 9px; right: 2px"
            @click="close"
          >
            <v-icon>far fa-times-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pl-3 pr-3 pb-3">
          <ul class="qj-list">
            <li
              v-for="item in accounts"
              :key="item.id"
              :class="'qj-iten-list' + (item.id == account.id ? ' active' : '')"
              @click="setAccount(item)"
            >
              <span class="qj-span"> <b>Agência:</b> {{ item.branch_code }}</span
              ><br />
              <span class="qj-span"> <b>Conta:</b> {{ item.account_number }}</span>
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["dialog", "account", "accounts"],
  methods: {
    close() {
      this.$emit("close");
    },
    setAccount(item) {
      this.$emit("setAccount", item);
    },
  },
};
</script>

<style scoped>
.qj-list {
  list-style-type: none;
  padding: 0 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  text-align: left;
}
.qj-iten-list {
  line-height: 14px;
  border-radius: 7px;
  padding: 7px;
}
.qj-iten-list.active {
  background: #f6f6f6;
}
</style>
