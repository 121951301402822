<template>
  <div>
    <DialogLoadingDebts
      :dialogLoadingDebts="displayLoadingDebts"
      @closeDialogLoadingDebts="closeDialogLoadingDebts"
    />
  </div>
</template>

<script>
import DialogLoadingDebts from "@/views/authentication/components/modals/DialogLoadingDebts";

export default {
  components: {
    DialogLoadingDebts,
  },
  data: () => ({
    displayLoadingDebts: false,
  }),
  created() {
    this.displayLoadingDebts = true;
  },
  methods: {
    closeDialogLoadingDebts() {},
  },
};
</script>
