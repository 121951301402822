<template>
  <v-slide-y-transition>
    <div v-show="alert" class="qj-alert">
      <div
        class="wrapper">
        <p class="qj-p mb-0 alert-title">
          Prosseguir com essa proposta?
        </p>

        <div class="mt-4 text-center">
          <v-btn class="rounded-lg qj-btn option-1" @click="close">
            Sim
          </v-btn>

          <v-btn class="rounded-lg qj-btn option-2" @click="simulate">
            Simular outra
          </v-btn>
        </div>
      </div>
    </div>
  </v-slide-y-transition>
</template>

<script>

export default {
  props: {
    alert: {
      type: Boolean,
      default: false,
    },
    dealId: {
      type: Number,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    simulate() {
      this.$router.push({
        name: "NegotiationDeal",
        query: { id: window.btoa(this.dealId) },
      }).catch((failure) => {
        this.skipIfNavigationFailureIsGeneric(failure)
      });
    },
  },
};
</script>

<style scoped lang="scss">
.qj-alert {
  left: 0;
  position: fixed;
  right: 0;
  top: 16px;
  z-index: 50;

  .wrapper {
    background: #e5e5e5;
    border-radius: 10px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.3);
    margin: auto;
    max-width: 330px;
    padding: 15px;
    position: relative;
  }

  .alert-title {
    margin-right: 43px;
  }

  .option-1 {
    background-color: var(--primary) !important;
    margin-right: 7px;
    width: 131px;
  }

  .option-2 {
    background-color: #f5f5f5;
    margin-left: 7px;
    width: 131px;
  }
}
</style>
