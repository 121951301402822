<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xs"
    scrollable
    @click:outside="$emit('close')"
    max-width="360"
    width="360"
    :transition="
      $vuetify.breakpoint.xs ? 'dialog-bottom-transition' : 'scale-transition'
    "
    v-model="dialog"
  >
    <v-card class="pt-0">
      <v-card-title class="card-title text-center px-0 d-flex justify-center">
        <h3 class="qj-h3">Como achar o seu código PIN</h3>
      </v-card-title>
      <v-card-text>
        <p>
          <span class="qj-p mb-4"
            >Você recebeu um <b>link para validação do seu código PIN</b> através do
            Whatsapp ou E-mail. Verifique o passo a passo e
            <b>valide para concluir seu pagamento.</b>
          </span>
        </p>
        <p>
          <span class="qj-p mb-4"
            >Para evitar fraudes e manter você seguro, precisamos que você olhe
            a fatura do seu cartão de crédito, e procure o código PIN como no exemplo a
            baixo.
          </span>
        </p>

        <v-row
          background="black"
          class="shrink bank-notification white--text mx-auto mt-4"
          width="385"
        >
          <v-col cols="2" background="black">
            <img src="@/assets/img/svg/creditor.svg" />
          </v-col>
          <v-col cols="10">
            <h5 class="mb-2">
              Seu Banco <span class="text-caption">09:57</span>
            </h5>
            <h4>Compra no crédito aprovada</h4>
            <span class="qj-p white--text">
              <span>Compra de R$ 11,12 APROVADA em PJA*</span>
              <span class="highlight">277</span>QUITEJA
              <span>para o cartão com final ****.</span>
            </span>
          </v-col>
          <v-col>
            
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <span class="qj-span">
              *Lembre-se, você deverá usar o número que aparece na sua fatura, não use o número 277 pois ele é apenas um exemplo!
            </span>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col class="pa-0 pt-2" cols="auto">
            <v-btn class="qj-btn primary rounded-lg " @click="$emit('close')">
            <span class="black--text">Entendi</span>
          </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
<style lang="scss">

.bank-notification {
  background-color: rgb(86, 84, 84);
  border-radius: 5px;
  max-width: 385px;
  .highlight {
    border: 2px solid red;
  }
}

.verification {
  width: 50px;
  height: 55px;
  background: #f0f0f0;
  border-radius: 7px;
  margin: 0 10px;
  text-align: center;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 30px;
  appearance: textfield;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.verification::-webkit-outer-spin-button,
.verification::-webkit-inner-spin-button {
  margin: 0;
  appearance: textfield;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.verification:focus {
  background: #cafce9;
  box-shadow: 0 0 0 0 !important;
  border: 0 none !important;
  outline: 0 !important;
}

.verification:focus::placeholder {
  color: transparent;
}

.passcodeNumber {
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  max-width: 100%;
  min-width: 0px;
  width: 100%;
  padding: 8px 0 8px 25px;
  border-radius: 7px;
  text-align: center;
  letter-spacing: 26px;
  margin: 0 auto;
  outline: none;
  border-color: #212121;
  border-style: solid;
  border-width: thin;

  background: url("../../../assets/img/icons/shield-check-outline.svg")
    no-repeat left;
  background-size: 20px;
  background-position-x: 12px;

  &:focus,
  &:active,
  &:focus-visible,
  &:target,
  &::selection {
    border-color: #00e58d;
  }
}
</style>