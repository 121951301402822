<template>
  <v-dialog v-model="dialog" max-width="310">
    <v-card class="text-center">
      <v-card-title class="justify-center d-flex flex-column">
        <h3 class="qj-h3 mb-2">Desculpe...</h3>
        <img src="@/assets/img/svg/proposalError.svg" />
      </v-card-title>
      <v-card-text>
        <p class="px-4">
          <span class="text-subtitle-2 mt-2">
            No momento, nossos parceiros não retornaram nenhuma negociação,
            tente novamente {{ whentext }}.
          </span>
        </p>
        <v-btn
          color="#00e58d"
          class="rounded-lg qj-btn v-btn mx-auto px-10"
          @click="closeDialog()"
          >Entendi</v-btn
        >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations("negotiation", ["setDialogCreditorsOff"]),
    open() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
  computed: {
    ...mapState("negotiation", {
      dialogFromStore: "dialogCreditorsOff",
    }),
    whentext() {
      const actualHour = this.$moment().hour();
      if (actualHour > 8 && actualHour < 18) {
        return "em alguns instantes";
      } else {
        return "em horario comercial";
      }
    },
    dialog: {
      get() {
        return this.dialogFromStore;
      },
      set(value) {
        this.setDialogCreditorsOff(value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.qj-h3 {
  word-break: break-word;
}
</style>
