import { render, staticRenderFns } from "./operationsBox.vue?vue&type=template&id=0bf9be6e&scoped=true"
import script from "./operationsBox.vue?vue&type=script&lang=js"
export * from "./operationsBox.vue?vue&type=script&lang=js"
import style0 from "./operationsBox.vue?vue&type=style&index=0&id=0bf9be6e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bf9be6e",
  null
  
)

export default component.exports