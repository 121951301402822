<template>
  <div justify="center" v-if="dialog">
    <v-fade-transition>
      <div v-show="dialog" class="qj-bg-dialog" @click="close"></div>
    </v-fade-transition>
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.xs"
      persistent
      scrollable
      max-width="360"
      width="360"
      :transition="
        $vuetify.breakpoint.xs ? 'dialog-bottom-transition' : 'scale-transition'
      "
    >
      <v-card class="details-card" style="padding: 0px 16px !important">
        <v-card-title class="card-title text-center px-0">
          <img
            src="@/assets/img/svg/aviso.svg"
            width="50"
            height="50"
            alt="image aguarde"
          />

          <h3 class="qj-h3 mt-4">Detalhes da negociação</h3>
          <v-btn
            icon
            class="qj-btn-close-dialog"
            style="top: 9px; right: 2px"
            @click="close"
          >
            <v-icon>far fa-times-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-0" style="padding-bottom: 10px">
          <div class="contracts-row">
            <operationsBox
              class="operations-box"
              :activeOperations="activeOperations"
              :showDetails="showDetails"
            />
            <v-divider></v-divider>
            <ul v-if="deal.status == 'AGREED'" class="qj-list qj-list-details">
              <li v-if="deal.payment_method">
                <span class="description">Pagamento</span>
                <span class="value">
                  {{ parceleJaProposal.payment_method | proposalPaymentMethod }}
                </span>
              </li>

              <li v-if="deal.prePayment.card_number">
                <span class="description">Cartão</span>
                <span class="value d-flex align-center">
                  {{ deal.prePayment.card_number }}
                </span>
              </li>
              <li v-if="deal.prePayment.card_brand">
                <span class="description">Bandeira</span>
                <span class="value d-flex align-center text-capitalize">
                  {{ deal.prePayment.card_brand }}
                  <img
                    class="card-brand ml-1"
                    :src="
                      require(`@/assets/img/svg/${deal.prePayment?.card_brand?.toLowerCase()}-logo.png`)
                    "
                    alt=""
                  />
                </span>
              </li>

              <li v-if="parceleJaProposal.iof">
                <span class="description">I.O.F.</span>
                <span class="value">{{ parceleJaProposal.iof | money }}</span>
              </li>

              <li v-if="parceleJaProposal.cet_month">
                <span class="description">C.E.T. a.m.</span>
                <span class="value">
                  {{ (parceleJaProposal.cet_month * 100).toFixed(2) }}%
                </span>
              </li>

              <li v-if="parceleJaProposal.cet_year">
                <span class="description">C.E.T. a.a.</span>
                <span class="value">
                  {{ (parceleJaProposal.cet_year * 100).toFixed(2) }}%
                </span>
              </li>
            </ul>
          </div>

          <div class="proposal-row pt-4">
            <div
              class="proposal-card pb-0 mb-5"
              :style="generateProposalBoxStyle(proposal)"
              :class="returnProposalBoxClass(proposal)"
            >
              <ProposalCard
                :deal="deal"
                :proposal="proposal"
                :partialIof="partialIof"
                :proposals="proposals"
                :showAlias="true"
                :dontShowPaymentMethodTags="dontShowPaymentMethodTags"
                :doShowNextButton="false"
              />
            </div>
          </div>
          <div class="proposal-row expire-row mt-4">
            <v-col cols="12" class="pa-0 mb-3">
              <ul class="qj-expire-list" v-if="!deal.prePayment">
                <li
                  class="qj-iten-expire expire-date-text"
                  :class="isInCash ? '' : 'qj-bb'"
                >
                  <p class="mb-1">
                    Data de vencimento
                    {{
                      isInCash ? "do boleto" : "da entrada"
                    }}
                  </p>
                  <span
                    v-if="proposal?.down_payment_due_date"
                    class="expire-date"
                    >Dia {{ proposal.down_payment_due_date | data }}</span
                  >
                </li>
                <li v-if="!isInCash" class="qj-iten-expire expire-date-text">
                  <p class="mb-1">Data de vencimento das demais parcelas</p>
                  <span class="expire-date"
                    >Todo dia {{ formatExpireDayNumber(proposal) }}</span
                  >
                </li>
              </ul>
            </v-col>
            <v-col
              cols="12"
              class="text-center d-flex flex-column justify-center align-center"
              v-if="canCancel()"
            >
              <a
                class="cancel-button"
                href="javascript:void(0);"
                id="btn__cancel__deal"
                @click="openDialogRequestCancellation()"
                >Solicitar cancelamento</a
              >
            </v-col>
          </div>

          <div
            elevation="6"
            class="d-flex align-center justify-center div-align-bottom"
          >
            <div class="div-btn-entendi">
              <v-btn
                class="btn-entendi qj-btn qj-bg-primary mx-auto mb-3"
                @click="close"
                >Entendi</v-btn
              >
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <DialogRequestCancellation
      :dialog="dialogRequestCancellation"
      :loading="cancelLoading"
      :cancellationText="cancellationText"
      @close="() => (dialogRequestCancellation = false)"
      @send="cancel()"
    />
    <DialogCancellationSuccess :dialog="dialogCancellationSuccess" />
  </div>
</template>

<script>
import store from "@/store";
import { mapActions } from "vuex";
import Deal from "@/modules/deal";
import util from "@/assets/js/util";
import serviceDeal from "@/services/deal";
import ProposalCard from "@/views/negotiation/components/proposalCard.vue";
import OperationsBox from "@/views/negotiation/components/operationsBox.vue";
import DialogRequestCancellation from "./DialogRequestCancellation";
import DialogCancellationSuccess from "./DialogCancellationSuccess";
import { createTagManagerEvent } from "@/utils";

export default {
  props: ["dialog", "deal"],
  components: {
    ProposalCard,
    OperationsBox,
    DialogRequestCancellation,
    DialogCancellationSuccess,
  },
  data: () => ({
    // Valor do I.O.F. para cada parcela
    partialIof: 0,
    proposal: {},
    proposals: [],
    activeOperations: [],
    dontShowPaymentMethodTags: false,
    showDetails: false,
    isInCash: true,
    cancelLoading: false,
    cancelStatus: "",
    cancellationText: null,
    dialogRequestCancellation: false,
    dialogCancellationSuccess: false,
    checkCancellationCount: 0,
  }),
  created() {
    let configurations = store.getters["negotiation/getConfiguration"].objects;
    this.partialIof = Deal.getPartialIof(this.deal, configurations);
    this.dontShowPaymentMethodTags = util.getConfigValue(
      this.deal.creditor.config_prefix + "dont_show_payment_method_tag"
    );
    this.proposals = this.deal?.proposals;
    this.proposal =
      this.deal.active_proposal?.parcele_ja_proposal ||
      this.deal?.active_proposal ||
      this.proposals?.find((proposal) => {
        if (this.deal.prePayment)
          return proposal.id == this.deal.prePayment.proposal_id;
        return proposal.status === this.deal.status;
      });
    if (!this.proposal) {
      let proposalId = util.base64Decode(this.$route.query.id);
      this.proposal = util.getProposal(proposalId);
    }
    this.showDetails = util.getConfigValue(
      this.deal.creditor.config_prefix + "show_details_on_deal"
    );
    this.activeOperations = this.deal.operations.filter((i) => i.active);
    this.isInCash = this.proposal?.payment_type === "IN_CASH";
    this.$router.replace({
          query: { ...this.$route.query, deal_id: window.btoa(this.deal.id) },
        });
  },
  methods: {
    ...mapActions("negotiation", ["ActionsDeal"]),
    close() {
      let query = { ...this.$route.query };
      delete query.deal_id;
      this.$router.replace({ query: query });
      this.$emit("close");
    },
    totalDebit(operations) {
      return Deal.calculateDebts(operations);
    },
    showPaymentType() {
      let installments = this.proposal.installments_count_1;

      if (installments) return "pagamento das demais parcelas";
      if (!installments && util.doShowBankSlip(this.proposal.deal_id))
        return "pagamento do boleto";
      return "pagamento";
    },
    pendingPayments() {
      return this.deal.payments.filter((i) => i.status == "PENDING");
    },
    formatExpireDayNumber(proposal) {
      let dueDate = proposal?.installment_due_date;
      if (!dueDate) {
        dueDate = proposal?.down_payment_due_date;
      }
      if (typeof dueDate == "number") return dueDate;
      return dueDate?.split("-")[2];
    },
    openDialogRequestCancellation() {
      createTagManagerEvent({
        event: "dealClickedCancelProposal",
      });
      this.dialogRequestCancellation = true;
    },
    canCancel() {
      if (!this.deal) return false;
      if (this.deal.status != "AGREED") return false;
      if (this.deal.payments.filter((i) => i.status == "PAYED").length)
        return false;
      let configPrefix = util.getPrefix(this.deal.id);
      if (
        util.getConfig()[configPrefix + "allows_customer_cancellation"] !== "1"
      )
        return false;
      let confirmedAt = new Date(this.deal.active_proposal.confirmed_at);
      let maxCancelationDate = new Date(
        confirmedAt.getTime() + 7 * 24 * 60 * 60 * 1000
      );
      if (new Date() > maxCancelationDate) return false;
      this.cancellationText =
        util.getConfig()[configPrefix + "cancellationText"] ?? null;
      return true;
    },
    cancel() {
      if (!this.deal) return;
      this.cancelLoading = true;
      serviceDeal.cancel(this.deal.id).then((res) => {
        this.cancelStatus = "PENDING";
        if (res.data) {
          this.interval = setInterval(this.checkCancellation, 2 * 1000);
          this.checkCancellationCount = 1;
        }
      });
    },
    returnProposalBoxClass(proposal) {
      if (!proposal) return "";
      if (proposal?.incentive == "Máximo parcelamento") {
        return "incentive-color-purple";
      } else if (proposal?.incentive == "Parcelamento máximo") {
        return "incentive-color-purple";
      } else if (proposal?.incentive == "Oferta sem juros") {
        return "incentive-color-gray";
      } else if (
        proposal.payment_type == "IN_CASH" &&
        proposal?.discount_rate > 0.1 &&
        !this.proposalOfferType &&
        proposal.id == this.proposals?.[0]?.id &&
        !proposal.incentive
      ) {
        return "incentive-color-pink";
      } else {
        return null;
      }
    },
    generateProposalBoxStyle(proposal) {
      if (this.returnProposalBoxClass(proposal) || !proposal?.incentive)
        return null;
      return {
        borderColor: util.stringToColor(proposal.incentive) ?? "#853500",
      };
    },
    checkCancellation() {
      this.ActionsDeal({ deal_id: this.deal.id }).then((response) => {
        if (
          (response.status === "STARTED" || response.status === "WAITING") &&
          response.active_proposal === null
        ) {
          this.cancelStatus = "DONE";
        }
      });
      this.checkCancellationCount++;
      if (this.checkCancellationCount > 4 || this.cancelStatus === "DONE") {
        this.dialogRequestCancellation = false;
        this.dialogCancellationSuccess = true;
        clearInterval(this.interval);
        if (this.cancelStatus === "PENDING") {
          this.cancelStatus = "WAITING";
        }
      }
    },
  },
  computed: {
    parceleJaProposal() {
      return this.deal.active_proposal.parcele_ja_proposal;
    },
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.$router.replace({
          query: { ...this.$route.query, deal_id: window.btoa(this.deal.id) },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.card-brand {
  width: 24px;
}
.operations-box {
  border-radius: 7px 7px 0px 0px !important;
}

.qj-list-details {
  border-radius: 0px 0px 7px 7px !important;
}

.v-card__text {
  color: #000000 !important;
}

.card-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.status-row {
  p {
    margin-bottom: 0;
  }

  .status-chip {
    background-color: #c0c0c0 !important;
    color: #212121 !important;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 800;
    padding: 5px 10px;
    border-radius: 100px;
    letter-spacing: 0;
    line-height: 15px;
    margin-bottom: 20px;
  }
}

.qj-list {
  padding: 0 !important;
}

.qj-list,
.qj-expire-list {
  list-style-type: none;
  background: #f6f6f6;
  border-radius: 7px;
}

.qj-expire-list {
  padding: 3px 8px;
}

.qj-iten-list,
.qj-list-details li {
  padding: 10px;
  line-height: initial;
}

.qj-iten-list.border-b,
.qj-list-details li:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}

.qj-iten-list .value,
.qj-list-details .value {
  float: right;
  margin: 0;
}

.qj-iten-list div b,
.qj-list-details .description {
  display: inline;
  font-style: normal;
  font-size: 14px;
  font-weight: 800;
  line-height: 17px;
  color: #212121 !important;
}

.qj-iten-list div span {
  font-style: normal;
  font-size: 10px;
  line-height: 12px;
  color: #212121 !important;
}

.qj-proposal-down-payment {
  font-weight: 700;
}

.qj-operation-details {
  font-size: 12px;
}

.qj-operation-assignor {
  display: block;
  font-size: 12px !important;
  margin-bottom: 4px;
}

.proposal-card {
  border: 1px solid #f0f0f0;
  padding: 0px 11px;
  border-radius: 7px;
  padding-bottom: 8px;

  .qj-proposal-installments {
    margin-bottom: 8px;
    margin-top: 4px;
  }
}

.expire-date,
.expire-date-text {
  font-size: 12px;
  line-height: initial;
}

.expire-date-text {
  font-size: 12px;
  font-weight: 400;
  padding-top: 8px;
  padding-bottom: 11px;
}

.expire-date {
  font-weight: 800;
}

.expire-row {
  margin-bottom: 90px;

  @media (min-width: 375px) {
    margin-bottom: 65px;
  }
}

.div-align-bottom {
  width: 100%;
  bottom: 0;
  position: fixed;
  background-color: #ffffff;
  margin: 0px -16px !important;
  padding: 15px 16px 2px !important;
  box-shadow: 0px -1px 10px 0px #e5e5e5;

  @media (min-width: 375px) {
    position: absolute;
  }

  .div-btn-entendi {
    width: 100%;
  }
}

.btn-entendi {
  border-radius: 10px;
  width: 100%;
}

.incentive-best-discount span {
  vertical-align: unset !important;
}

.incentive-color-purple {
  border-color: #8741a9;
}

.incentive-color-gray {
  border-color: #9d9d9d;
}

.incentive-color-pink {
  border-color: #ff0099;
}

.cancel-button {
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  color: #00606a;
}
</style>
