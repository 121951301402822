<template>
  <div class="qj-card-outline" :key="deal.id">
    <div v-if="deal.status == 'AGREED'">
      <v-row justify="center">
        <v-col cols="auto" class="pb-0">
          <div v-if="deal.prePayment">
            <v-chip
              :color="getParceleJaStatus(deal)?.chipColor"
              class="status-chip"
            >
              <span
                :class="getParceleJaStatus(deal)?.textColor"
                class="deal-status"
              >
                <b
                  v-html="getParceleJaStatus(deal)?.text"
                  :class="getParceleJaStatus(deal)?.textColor"
                >
                </b
              ></span>
            </v-chip>
            <v-icon
              v-if="getParceleJaStatus(deal)?.moreInfo"
              @click="openParceleJaMoreInfo()"
              :class="dealPaymentStatus[getDealPaymentStatus()]?.textColor"
              style="margin-top: 3px"
              class="ml-2 more-info-btn"
              size="22"
              >{{ getParceleJaStatus(deal)?.icon }}</v-icon
            >
          </div>
          <v-chip
            v-else-if="dealPaymentStatus[getDealPaymentStatus()]?.text"
            :ripple="false"
            :color="dealPaymentStatus[getDealPaymentStatus()]?.color"
            class="status-chip"
          >
            <v-icon
              :class="dealPaymentStatus[getDealPaymentStatus()]?.textColor"
              style="margin-right: 5px"
              small
              size="14"
              >{{ dealPaymentStatus[getDealPaymentStatus()]?.icon }}</v-icon
            >
            <span
              v-if="!deal.prePayment"
              :class="dealPaymentStatus[getDealPaymentStatus()]?.textColor"
              class="deal-status"
              >{{
                deal.active_proposal.agreement_type == "REGULARIZE"
                  ? "Negociação"
                  : "Acordo"
              }}
              <b
                v-html="dealPaymentStatus[getDealPaymentStatus()]?.text"
                :class="dealPaymentStatus[getDealPaymentStatus()]?.textColor"
              >
              </b
            ></span>
          </v-chip>
        </v-col>
      </v-row>
      <v-row class="agreement__logo flex-nowrap">
        <v-col cols="6" class="pt-0 pr-0 pb-0 pl-1 card-logo">
          <img
            class="qj-img"
            v-if="deal.creditor.profile"
            :src="deal.creditor.profile.logo_url"
            :alt="`Logo ${deal.creditor.profile.long_name}`"
          />
        </v-col>
        <v-col
          v-if="
            deal.active_proposal.agreement_type != 'REGULARIZE' &&
            deal.external_ref
          "
          cols="6"
          class="d-flex flex-column align-end justify-center acordo pl-0"
        >
          <b class="acordo__number text-right">{{ deal.external_ref }}</b>
          <span class="acordo__label">Número do acordo</span>
        </v-col>
      </v-row>
      <v-row v-if="parceleJaProposal">
        <v-col
          cols="12"
          class="text-center qj-price pt-3 d-flex flex-column"
          v-if="parceleJaProposal.payment_method == 'PARCELE_JA'"
        >
          <span>
            <h2>{{ getTotalAmount(parceleJaProposal) | money }}</h2>
            em
          </span>
          <span>
            <h2>{{ parceleJaProposal.installments_count_1 }}x</h2>
            de
            <h2>
              {{ parceleJaProposal.installment_1 | money }}
            </h2>
          </span>
          <span class="text-body-2 mb-1"
            >Juros de
            {{ (parceleJaProposal.interest_rate * 100).toFixed(2) }}%</span
          >

          <div v-if="!dontShowPaymentMethodTags">
            <div class="d-flex flex-column pb-0">
              <div class="d-flex flex-column align-start pb-0">
                <span
                  class="mx-auto"
                  :class="
                    (parceleJaProposal.payment_type != 'IN_CASH'
                      ? 'parceled '
                      : 'cash ') + 'qj-tag'
                  "
                >
                  <b>{{
                    parceleJaProposal.payment_type | proposalPaymentType
                  }}</b>
                  <span
                    v-if="isPaymentMethodVisible"
                    class="mb-0 qj-proposal-payment-method"
                  >
                    no
                    {{
                      parceleJaProposal.payment_method | proposalPaymentMethod
                    }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <template
        v-if="
          activePayment?.status &&
          activePayment?.value &&
          (!deal.prePayment ||
            [2, 5].includes(deal.prePayment?.prepayment_status_code))
        "
      >
        <v-row
          justify="center"
          class="payment_status"
          :class="getPaymentColor(deal)"
        >
          <div>
            <span class="payment_status__text">{{
              getPaymentStatus(deal)
            }}</span>
          </div>
        </v-row>
        <v-row justify="space-around">
          <v-col cols="auto" class="d-flex flex-column align-center">
            <span
              class="text-caption"
              v-if="
                !(
                  activePayment.type == 'DOWN_PAYMENT' &&
                  activePayment?.status == 'PENDING'
                )
              "
              >Parcela</span
            >
            <span class="text-caption" v-html="getInstallmentText()"></span>
          </v-col>
          <v-col cols="auto" class="d-flex flex-column align-center">
            <span
              class="text-caption"
              v-if="activePayment.method == 'DEBIT_CARD'"
              >Debitado dia</span
            >
            <span class="text-caption" v-else>Vencimento</span>
            <span class="text-caption" v-if="activePayment.method == 'CREDIT'"
              ><b>Fatura</b></span
            >
            <span class="text-caption" v-else
              ><b>{{ activePayment.due_date | brazilianDate }}</b></span
            >
          </v-col>
          <v-col cols="auto" class="d-flex flex-column align-center">
            <span class="text-caption">Valor</span>
            <span class="text-caption"
              ><b
                >R$
                {{
                  (activePayment.value + activePayment?.additional_value)
                    | value
                }}</b
              ></span
            >
          </v-col>
        </v-row>
        <v-row v-if="activePayment.pix_code" class="">
          <v-col
            v-show="!$vuetify.breakpoint.smAndDown"
            cols="12"
            class="d-flex justify-center pb-0"
          >
            <canvas
              v-show="!$vuetify.breakpoint.smAndDown"
              :id="`canvas-${activePayment.id}`"
            ></canvas>
          </v-col>

          <v-col cols="12" class="pb-0 px-0">
            <div class="pix-chip_container">
              <v-chip
                color="grey lighten-3"
                class="pix-chip_chip"
                v-if="activePayment.pix_code"
                ><v-img
                  class="mr-2"
                  height="20"
                  width="20"
                  src="@/assets/img/svg/pix.svg"
                /><span class="payment_status__text">Pix</span></v-chip
              >
            </div>
            <div class="barcode-container align-center px-4 py-4">
              <span
                :class="[showFullPixCode ? 'pix_code' : 'pix_code__hidden']"
                class="text-caption text-body-2 font-weight-bold text-center flex-shrink"
              >
                {{ activePayment.pix_code }}
              </span>
              <span
                @click="showFullPixCode = !showFullPixCode"
                class="pix_code__hidden cursor-pointer text-center text-caption font-weight-bold grow blue--text darken-3"
              >
                {{ showFullPixCode ? "Mostrar menos" : "Mostrar mais" }}
              </span>
            </div>
          </v-col>
          <v-col class="">
            <div class="d-flex flex-column align-center justify-center">
              <span class="text-center text-body-2 mb-2" v-if="activePayment.pix_code">Copie e cole seu código e pague no banco de sua preferência</span>
              <div>
                <v-btn
                  v-if="pixCopied"
                  @click="copyPix(activePayment)"
                  class="copy-btn rounded-lg qj-btn qj-bg-primary qj-p-btn"
                >
                  <v-icon small>mdi-check</v-icon>
                  <div>Código copiada</div>
                </v-btn>
                <v-btn
                  v-else
                  @click="copyPix(activePayment)"
                  class="copy-btn rounded-lg qj-btn qj-bg-primary qj-p-btn"
                >
                  <v-icon small>mdi-content-copy</v-icon>
                  <div>Copiar código</div>
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row
          v-else-if="activePayment.barcode"
          class="mb-6 barcode-container justify-center"
        >
          <v-col class="pa-0 pt-4">
            <div
              class="d-flex flex-column align-center justify-center text-center barcode-container"
            >
              <span class="text-body-2 font-weight-bold">{{
                this.activePayment.barcode
                  | VMask(
                    "#####.##### #####.###### #####.###### # ##############"
                  )
              }}</span>
              <div>
                <v-btn
                  v-if="copiedBarCode"
                  @click="lickCopy()"
                  class="copy-btn rounded-lg qj-btn qj-bg-primary qj-p-btn"
                >
                  <v-icon small>mdi-check</v-icon>
                  <div>Código copiado</div>
                </v-btn>
                <v-btn
                  v-else
                  @click="lickCopy()"
                  class="copy-btn rounded-lg qj-btn qj-bg-primary qj-p-btn"
                >
                  <v-icon small>mdi-content-copy</v-icon>
                  <div>Copiar código</div>
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </template>

      <v-row class="flex-column align-center my-4">
        <a
          v-if="
            activePayment?.boleto?.url &&
            showBillAlert(activePayment) &&
            (!deal.prePayment ||
              [2, 5].includes(deal.prePayment.prepayment_status_code))
          "
          class="card-a mb-1 text-decoration-underline"
          @click="$emit('openBillAlert', activePayment)"
          href="javascript:void(0);"
          id="btn__billet__alert"
          >Baixar boleto</a
        >
        <a
          v-if="
            activePayment?.boleto?.url &&
            !showBillAlert(activePayment) &&
            (!deal.prePayment ||
              [2, 5].includes(deal.prePayment.prepayment_status_code))
          "
          class="card-a mb-1"
          @click="
            $emit('downloadBoleto', {
              paymentId: activePayment.id,
              dealId: activePayment.deal_id,
            })
          "
          :href="activePayment?.boleto?.url"
          target="_blank"
          id="btn__download__billet"
          >Baixar boleto</a
        >
        <a
          class="card-a"
          href="javascript:void(0);"
          @click="agreementDetails(deal)"
          >Detalhes da negociação
          {{ parceleJaProposal ? "do cartão" : null }}</a
        >
      </v-row>
    </div>
    <div v-else>
      <div>
        <v-row class="justify-center">
          <v-col cols="auto" class="pb-0">
            <div
              :class="
                'text-center card-tag-state-wake-up tag-' + typeTega(deal)
              "
            >
              <span v-html="msgBold(deal)"></span>
              <span v-html="msg(deal)"></span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pt-0 pb-0 pl-1 card-logo d-flex">
            <img
              class="qj-img"
              v-if="deal.creditor.profile"
              :src="deal.creditor.profile.logo_url"
              :alt="`Logo ${deal.creditor.profile.long_name}`"
            />
          </v-col>
        </v-row>
        <v-row>
          <div
            v-if="activeProposal?.discount_rate > 0.01"
            class="qj-tag-discount mx-auto"
          >
            <img src="@/assets/img/icons/sale.svg" alt="sale icon" /><span>
              <b>{{ calculateDiscountRate(deal) }}%</b> de desconto</span
            >
          </div>
        </v-row>
        <v-row v-if="parceleJaProposal" class="pt-0">
          <v-col
            cols="12"
            class="text-center qj-price d-flex flex-column"
            v-if="parceleJaProposal.payment_method == 'PARCELE_JA'"
          >
            <span>
              <h2>{{ getTotalAmount(parceleJaProposal) | money }}</h2>
              em
            </span>
            <span>
              <h2>{{ parceleJaProposal.installments_count_1 }}x</h2>
              de
              <h2>
                {{ parceleJaProposal.installment_1 | money }}
              </h2>
            </span>
          </v-col>
        </v-row>
        <v-row v-else justify="center">
          <v-col
            cols="auto"
            class="qj-price pr-0"
            v-if="activeProposal?.payment_type == 'INSTALLMENT'"
          >
            <h6 class="text-center" v-if="activeProposal">
              <span v-if="activeProposal?.down_payment > 0">
                <span>Entrada de</span>
                <span class="qj-proposal-down-payment">
                  {{ activeProposal.down_payment | money }}
                </span>
              </span>
              <div>
                {{ activeProposal.down_payment ? "+" : "" }}
                <span style="font-size: 16px"
                  ><b>{{ activeProposal.installments_count_1 }}X </b></span
                >de
                <h2>
                  {{ activeProposal.installment_1 | money }}
                </h2>
              </div>
            </h6>
            <div
              v-if="activeProposal.installments_count_2"
              class="qj-proposal-irregular-flow"
            >
              <span class="plus-sign">+</span>

              <span class="installments-count">
                <b>{{ activeProposal.installments_count_2 }}X </b>
              </span>

              de

              <span class="installment">
                <h2>{{ activeProposal.installment_2 | money }}</h2>
              </span>
            </div>
            <div
              v-if="activeProposal.installments_count_3"
              class="qj-proposal-irregular-flow"
            >
              <span class="plus-sign">+</span>

              <span class="installments-count">
                {{ activeProposal.installments_count_3 }}x
              </span>

              <span class="separator">de</span>

              <span class="installment">
                {{ activeProposal.installment_3 | money }}
              </span>
            </div>
            <div
              v-if="activeProposal.installments_count_4"
              class="qj-proposal-irregular-flow"
            >
              <span class="plus-sign">+</span>

              <span class="installments-count">
                {{ activeProposal.installments_count_4 }}x
              </span>

              <span class="separator">de</span>

              <span class="installment">
                {{ activeProposal.installment_4 | money }}
              </span>
            </div>
            <div
              v-if="activeProposal.installments_count_5"
              class="qj-proposal-irregular-flow"
            >
              <span class="plus-sign">+</span>

              <span class="installments-count">
                {{ activeProposal.installments_count_5 }}x
              </span>

              <span class="separator">de</span>

              <span class="installment">
                {{ activeProposal.installment_5 | money }}
              </span>
            </div>
            <p
              v-if="isPaymentMethodVisible(activeProposal)"
              class="ml-1 mb-0 qj-proposal-payment-method"
            >
              no
              {{ activeProposal.payment_method | proposalPaymentMethod }}
            </p>
          </v-col>
          <v-col class="qj-price" cols="auto" v-else>
            <h3>
              <span class="qj-proposal-down-payment">
                <b>{{ activeProposal?.down_payment | money }}</b>
              </span>
            </h3>
          </v-col>
        </v-row>
        <v-row justify="center" v-if="activeProposal">
          <div class="d-flex flex-column align-center">
            <div
              :class="{
                'd-flex flex-column align-start': $vuetify.breakpoint.xs,
              }"
            >
              <span
                :class="
                  (parceleJaProposal?.payment_type ??
                  activeProposal?.payment_type != 'IN_CASH'
                    ? 'parceled '
                    : 'cash ') + 'qj-tag'
                "
                ><b
                  >{{
                    parceleJaProposal?.payment_type ??
                    activeProposal?.payment_type | proposalPaymentType
                  }}
                </b>
                <span v-if="parceleJaProposal">no cartão de crédito</span>
                <span v-else>{{
                  activeProposal.payment_method == "BOLETO"
                    ? "no boleto"
                    : "no Débito"
                }}</span>
              </span>
            </div>
          </div>
        </v-row>
        <v-row justify="center">
          <v-col cols="auto">
            <a
              class="card-a"
              href="javascript:void(0);"
              @click="agreementDetails(deal)"
              >Detalhes da negociação
              {{ parceleJaProposal ? "do cartão" : null }}</a
            >
          </v-col>
        </v-row>
      </div>
    </div>
    <component
      :is="getParceleJaStatus(deal)?.moreInfoComponent"
      @close="dialogParceleJaMoreInfo = false"
      :dialog="dialogParceleJaMoreInfo"
    />
  </div>
</template>

<script>
import serviceEvent from "@/services/event";
import servicePayment from "@/services/payment";
import { createTagManagerEvent } from "@/utils";
import util from "@/assets/js/util";
import { parceleJaStatus } from "@/utils";
import AwaitingPayerValidationDialog from "./AwaitingPayerValidationDialog.vue";
const QRCode = require("qrcode");
export default {
  components: {
    AwaitingPayerValidationDialog,
  },
  props: {
    deal: {
      type: Object,
      default: null,
    },
    isPaymentMethodVisible: {
      type: Function,
    },
    dontShowPaymentMethodTags: {
      default: false,
    },
    isOperationsValueVisible: {
      type: Function,
    },
    agreementDetails: {
      type: Function,
    },
    maxDebit: {
      type: Function,
    },
    typeTega: {
      type: Function,
    },
    msgBold: {
      type: Function,
    },
    msg: {
      type: Function,
    },
  },
  data() {
    return {
      showFullPixCode: false,
      dialogParceleJaMoreInfo: false,
      copiedBarCode: false,
      activePayment: null,
      downPayment: null,
      pixCopied: false,
      dealPaymentStatus: {
        OK: {
          text: "<strong>em dia</strong>",
          color: "#0A79EB",
          textColor: "white--text",
          icon: "mdi-check-circle-outline",
          title: "Seu acordo está com os pagamentos em dia, parabéns!",
          subtitle: "Continue assim para quitar sua dívida.",
        },
        OK_REGULARIZE: {
          text: "em dia",
          color: "#0A79EB",
          textColor: "white--text",
          icon: "mdi-check-circle-outline",
          title: "Seu acordo está com os pagamentos em dia, parabéns!",
          subtitle: "Continue assim para quitar sua dívida.",
        },
        DUE_TODAY: {
          text: "vence hoje",
          color: "#FF8400",
          textColor: "white--text",
          icon: "mdi-alert-circle-outline",
        },
        IN_DEBIT: {
          text: "em atraso",
          color: "#C10903",
          textColor: "white--text",
          icon: "mdi-clock-time-three-outline",
        },
        WAITING_BOLETO_PAYMENT: {
          text: "em análise",
          color: "#E5E5E5",
          textColor: "black--text",
          icon: "mdi-clock-time-three-outline",
          title: "Seu pagamento está sendo processado no cartão de crédito.",
          subtitle:
            "Em até 5 dias úteis seu nome será retirado dos órgãos de proteção ao crédito.",
        },
        BOLETO_PAYED: {
          text: "em análise",
          color: "#E5E5E5",
          textColor: "black--text",
          icon: "mdi-clock-time-three-outline",
          title: "Seu pagamento está sendo processado no cartão de crédito.",
          subtitle:
            "Em até 5 dias úteis seu nome será retirado dos órgãos de proteção ao crédito.",
        },
        BOLETO_PAYMENT_ERROR: {
          text: "falha no pagamento",
          color: "orange darken-1",
          textColor: "black--text",
          icon: "mdi-alert-circle-outline",
          title: "Seu pagamento no cartão de crédito não foi aprovado.",
          subtitle:
            "mas fique calmo(a) que você ainda pode quitar sua dívida pagando o boleto a baixo",
        },
      },
      tolerances: {},
    };
  },
  async created() {
    this.activePayment = this.deal.payments?.find(
      (payment) => payment.status == "PENDING"
    );
    let configPrefix = util.getPrefix(this.deal.id);
    if (!configPrefix) return false;
    this.tolerances = {
      DOWN_PAYMENT:
        util.getConfig()[configPrefix + "down_payment_tolerance_days"] ?? null,
      INSTALLMENT:
        util.getConfig()[configPrefix + "installment_tolerance_days"] ?? null,
    };
  },
  mounted() {
    if (this.activePayment) this.generrateQrCode(this.activePayment);
  },
  methods: {
    copyPix(payment) {
      createTagManagerEvent({
        event: "dealCopiedBoletoBarcode",
      });
      let input = payment.pix_code;
      this.pixCopied = true;
      navigator.clipboard
        .writeText(input)
        .catch((error) => console.error(error));
      setTimeout(() => (this.pixCopied = false), 3000);
    },
    openParceleJaMoreInfo() {
      this.dialogParceleJaMoreInfo = true;
    },
    getParceleJaStatus(deal) {
      return parceleJaStatus.getStatus(deal.prePayment?.prepayment_status_code);
    },
    getInstallmentText() {
      let dealHasDownPayment = this.deal.down_payment > 0;
      if (this.deal.active_proposal.payment_type === "IN_CASH")
        return "<br><b>À vista </b>";
      if (
        this.activePayment.type == "DOWN_PAYMENT" &&
        this.activePayment?.status == "PENDING" &&
        dealHasDownPayment
      ) {
        return `<br><b>Entrada</b>`;
      }
      let downPaymentOnPayments = this.deal.payments.find(
        (payment) => payment.type == "DOWN_PAYMENT"
      );
      let fakeDownPayment = downPaymentOnPayments && !dealHasDownPayment;
      let installmentNumber = fakeDownPayment
      ? this.activePayment?.installment_number + 1
      : this.activePayment?.installment_number;

      let totalInstallments =
        this.deal.installments_count_1 +
        (this.deal.installments_count_2 ?? 0) +
        (this.deal.installments_count_3 ?? 0) +
        (this.deal.installments_count_4 ?? 0) +
        (this.deal.installments_count_5 ?? 0);
      return `<b>${installmentNumber}</b> de <b>${totalInstallments}</b>`;
    },
    getTotalAmount(proposal) {
      if (this.deal.active_proposal.parcele_ja_proposal) {
        return this.deal.active_proposal.down_payment;
      }
      return (
        proposal.total_amount ??
        proposal.installments_count_1 * proposal.installment_1
      );
    },
    getPaymentStatus(deal) {
      let pendingPayment = deal.payments.find((payment) => {
        return payment.status == "PENDING";
      });

      let dueDate = new Date(pendingPayment.due_date);
      let difference = dueDate.getTime() - new Date().getTime();
      let days = Math.ceil(difference / (1000 * 3600 * 24));

      if (pendingPayment.method == "DEBIT_CARD") {
        return "Débito automático em conta";
      }
      if (pendingPayment.method == "CREDIT") {
        return "Parcelado na sua fatura";
      }
      if (!pendingPayment.barcode && !pendingPayment.pix_code) {
        return "Em breve seu boleto estará aqui";
      }

      if (pendingPayment && days > 0) {
        return `Aguardando pagamento`;
      } else if (pendingPayment && days == 0) {
        return `Vence hoje`;
      } else {
        return `Pagamento atrasado`;
      }
    },
    getExpireDay(date) {
      let dueDate = new Date(date);
      let difference = dueDate.getTime() - new Date().getTime();
      return Math.ceil(difference / (1000 * 3600 * 24));
    },
    getPaymentColor(deal) {
      let payment = deal.payments.find((payment) => {
        return payment.status == "PENDING";
      });
      if (payment.status === "PAYED" || !payment) return "tag-payed";
      let { boleto, boleto_id } = payment;
      if (!boleto || !boleto_id) {
        return "tag-grey";
      }

      let days = this.getExpireDay(payment.due_date);
      if (payment && days > 0 && this.activePayment.id === payment.id) {
        return "tag-awaiting";
      } else if (payment && days > 0) {
        return "tag-awaiting";
      } else if (payment && days == 0) {
        return "tag-yellow";
      } else {
        return "tag-red";
      }
    },
    calculateDiscountRate(deal) {
      let discount = deal.active_proposal?.discount_rate * 100;
      return discount > 99 ? Math.floor(discount) : Math.round(discount);
    },
    lickCopy() {
      createTagManagerEvent({
        event: "dealCopiedBoletoBarcode",
      });
      let input = this.activePayment.barcode;
      navigator.clipboard.writeText(input);
      this.copiedBarCode = true;
      setTimeout(() => {
        this.copiedBarCode = false;
      }, 5000);
      serviceEvent
        .create({
          deal_id: this.deal.id,
          detail: "Pagamento: " + this.activePayment.id,
          message: "Código de barras copiado",
          type: "BARCODE_COPIED",
        })
        .catch((error) => {
          console.error("Não foi possível criar o evento.", error);
        });
    },
    downloadBoleto() {
      createTagManagerEvent({
        event: "dealDownloadedBoleto",
      });
      serviceEvent
        .create({
          deal_id: this.deal.id,
          detail: "Pagamento: " + this.activePayment.id,
          message: "Boleto baixado",
          type: "BOLETO_DOWNLOADED",
        })
        .catch((error) => {
          console.error("Não foi possível criar o evento.", error);
        });
      servicePayment
        .sendBoleto(this.activePayment.id)
        .catch((error) => console.error(error));
    },
    getDealPaymentStatus() {
      if (this.deal?.prePayment?.quiteja_status) {
        return this.deal?.prePayment?.quiteja_status;
      }
      const activeProposal = this.deal.active_proposal;
      if (!this.activePayment) return false;
      let dueDate = new Date(this.activePayment.due_date);
      let difference = dueDate.getTime() - new Date().getTime();
      let days = Math.ceil(difference / (1000 * 3600 * 24));
      if (days < 0 && days * -1 == this.tolerances[this.activePayment.type])
        return "DUE_TODAY";
      if (days >= 0)
        return activeProposal.agreement_type == "REGULARIZE"
          ? "OK_REGULARIZE"
          : "OK";
      if (days < 0) return "IN_DEBIT";
    },
    getDealStatus() {
      let status = {
        ABORTED: "Acordo cancelado",
      };
      return status[this.deal.status];
    },
    getCause() {
      if (this.deal?.status == "ABORTED") {
        return this.deal.aborted_cause;
      } else if (this.deal.status == "WAITING") {
        return this.deal.waiting_cause;
      }
    },
    showBillAlert(payment) {
      let configPrefix = util.getPrefix(this.deal.id);
      if (!configPrefix) return false;

      let doShowAlert =
        util.getConfig()[configPrefix + "show_billet_alert"] ?? false;

      return doShowAlert && payment?.boleto?.encrypted;
    },
    generrateQrCode(payment) {
      if (!payment.pix_code) return;
      QRCode.toCanvas(
        document.getElementById(`canvas-${payment.id}`),
        payment.pix_code,
        {
          scale: 3,
          margin: 3,
        },
        function (err, canvas) {
          if (err) throw err;
        }
      );
    },
  },
  computed: {
    parceleJaProposal() {
      const prePaymentStatusCode =
        this.deal?.prePayment?.prepayment_status_code;
      const isValidParceleJaStatus = ![2, 5].includes(prePaymentStatusCode);
      const parceleJaProposal = this.deal.active_proposal?.parcele_ja_proposal;
      if (this.deal.status == "RESOLVED") {
        if (prePaymentStatusCode == 9) return parceleJaProposal;
        return null;
      }
      if (isValidParceleJaStatus)
        return this.deal.active_proposal?.parcele_ja_proposal;
      return null;
    },
    activeProposal() {
      return this.deal.active_proposal;
    },
  },
};
</script>

<style scoped lang="scss">
.copy-btn {
  margin-bottom: -15px;
}
.barcode-container {
  background: #d6fced;
}
.deal-status {
  font-size: 12px;
  text-align: center;
  font-weight: 500;
}
.payment_status {
  text-transform: math-auto;
  &__text {
    font-weight: 800;
    font-size: 12px;
  }
}
.agreement__logo {
  justify-content: space-between;
}

.card-logo {
  padding: 12px 12px 7px 12px !important;
  justify-content: center;
}

.assignor-band {
  background-color: #f6f6f6;
  position: absolute;
  width: 94%;
  border-radius: 7px;
  padding: 7px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span:last-child {
    display: flex;
    align-items: center;
  }
  img {
    min-height: 16px;
    max-height: 20px;
  }
  @media (max-width: 320px) {
    width: 90%;
    line-height: 1.2;
    align-items: center;
    span:last-child {
      max-width: 75%;
      text-align: end;
      justify-content: end;
    }
  }
}

.no-bg {
  &::before {
    background-color: transparent;
  }
}
.qj-tag-discount {
  margin-top: 2.5px;
}
.tab {
  overflow: hidden;
  background-color: #e5e5e5;
  border-radius: 100px;
  margin-top: 7px;
}

.tab button {
  width: 50%;
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 13px 0;
  /* transition: 0.3s; */
  font-size: 14px;
  line-height: 17px;
}

.tab button.active {
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  margin: 5px;
  padding: 8px 0;
  width: calc(50% - 10px);
  font-weight: bold;
}

.tabcontent {
  margin-top: 10px;
  display: none;
}

.card-logo {
  padding: 12px 12px 7px 12px !important;
}

.card-title-deal {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  @media (max-width: 320px) {
    font-size: 11px;
  }
}

.card-p {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
}

.card-a {
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  color: #00606a;
}

.card-details {
  text-align: right;
}
.more-info-btn {
  position: absolute;
}
.card-value-deal {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  text-align: right;
  color: #212121;
  @media (max-width: 320px) {
    font-size: 12px;
  }
}
.qj-price {
  padding: 9px 0px;
}

.card-value-deal-more-discount {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;
  text-align: right;
  color: #212121;
  @media (max-width: 320px) {
    font-size: 14px;
  }
}

.card-description-discount {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: right;
  color: #212121;
}

.card-btn div {
  margin-right: auto;
}

.card-tag-state-wake-up {
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  padding: 5px 8px;
  border-radius: 100px;
  width: initial;
  margin-left: auto;
}
.card-tag-state-wake-up b {
  color: inherit;
}
.card-tag-state-wake-up.tag-green {
  background: #cafce9;
  color: #000000;
}
.card-tag-state-wake-up.tag-yellow {
  background: #f9fcca;
  color: #b87f0e;
}
.card-tag-state-wake-up.tag-blue {
  background: #d1e6fc;
  color: #3876b5;
}
.card-tag-state-wake-up.tag-orange {
  background: #ffe7d8;
  color: #ff8533;
}
.card-tag-state-wake-up.tag-red {
  background: #ffeaea;
  color: #b83636;
}
.card-tag-state-wake-up.tag-grey {
  background: #e5e5e5;
  color: #000000;
}
.card-tag-state-wake-up.tag-brown {
  background: #efebe9;
  color: #3e2723;
}

.qj-card-msg {
  padding: 10px;
  background: #f6f6f6;
  border-radius: 7px;
}

.qj-operation-assignor {
  font-size: 16px;
  font-weight: 500;
}
.pix_code {
  text-align: center;
  word-break: break-all;
  display: inline-block;
}

.assignor-band {
  background-color: #f6f6f6;
  position: absolute;
  width: 94%;
  border-radius: 7px;
  padding: 7px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span:last-child {
    display: flex;
    align-items: center;
  }
  img {
    min-height: 16px;
    max-height: 20px;
  }
  @media (max-width: 320px) {
    width: 90%;
    line-height: 1.2;
    align-items: center;
    span:last-child {
      max-width: 75%;
      text-align: end;
      justify-content: end;
    }
  }
}

.no-bg {
  &::before {
    background-color: transparent;
  }
}
.qj-tab {
  font-size: 14px;
  font-weight: 600 !important;
}
.qj-tabs {
  @media (min-width: 600px) {
    width: 520px !important;
    margin-left: -32px;
  }
  @media (max-width: 600px) {
    width: 100vw !important;
    margin-left: -16px;
  }
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}
.qj-tag-discount {
  margin-top: 2.5px;
}
.acordo {
  &__number {
    line-height: initial;
    word-break: break-word;
  }
  &__label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: rgb(0, 0, 0);
    line-height: 10px;
    white-space: nowrap;
  }
}

.tag-grey {
  background: #e5e5e5;
  color: #000000;
}
.tag-awaiting {
  background: #a9e4eb;
  color: #000000;
}
.tag-green {
  background: #a1e5d5;
  color: #083b2f;
}
.tag-yellow {
  background: #fbd1a2;
  color: #000000;
}
.tag-red {
  background: #ff9494;
  color: #000000;
}
.tag-payed {
  background: #cafce9;
  color: #000000;
}
.status-chip {
  height: unset;
  line-height: 15px;
}
.pix-chip {
  &_container {
    position: absolute;
  }
  &_chip {
    position: relative;
    top: -16px;
    left: 12px;
  }
}
.pix_code {
  word-break: break-all;
  &__hidden {
    word-break: keep-all;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }
}
</style>