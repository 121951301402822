<template>
  <v-dialog v-model="dialog" persistent max-width="310">
    <v-card class="text-center">
      <v-card-title class="attention-title justify-center">
        <h3 class="qj-h3">Informação</h3>
      </v-card-title>
      <v-card-text class="attention-content">
        <img
          class="attention-icon"
          src="@/assets/img/svg/aviso.svg"
          alt="image_attention"
          width="70"
          height="70"
        />

        <p class="qj-p">
          Esse crédito foi adquirido pel{{creditorPrefix}} <b>{{ creditorName }}</b> que é o novo
          credor desse débito. <span class="text-uppercase">{{ creditorPrefix }}</span> <b>{{ creditorName }}</b> é um
          <b>fundo de investimento em direito creditório (FIDC)</b>, o que
          também podemos chamar de securitizadora. Ela gerencia e compra dívidas
          de instituições financeiras e empresas.
        </p>
        <v-btn
          width="98px"
          class="rounded-lg qj-btn qj-bg-primary qj-p-btn"
          @click="close"
          >Entendi</v-btn
        >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script scoped>
export default {
  props: ["dialog", "deal"],
  methods: {
    close() {
      this.$emit("close");
    },
  },
  computed: {
    creditorName() {
      var name =
        this.deal?.creditor?.name ||
        this.deal?.creditor?.profile?.name;
      return name;
    },
    creditorPrefix() {
      var profile = this.deal?.creditor_profile || this.deal?.creditor?.profile;
      return profile?.gender === "MALE" ? "o" : "a";
    },
  },
};
</script>

<style lang="scss" scoped>
.attention-title {
  padding-top: 21px !important;
}
.attention-content {
  padding: 5px 12.5px 21px !important;
}

.attention-icon {
  margin-bottom: 15px;
}
</style>
