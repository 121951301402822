<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="310">
      <v-card class="text-center">
        <v-card-title class="justify-center pt-3 pb-4">
          <h3 class="qj-h3">I.O.F</h3>
          <v-btn
            icon
            class="qj-btn-close-dialog"
            style="top: 9px; right: 2px"
            @click="close"
          >
            <v-icon>far fa-times-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pl-3 pr-3 pb-3">
          <p class="qj-p">
            Imposto sobre Operações Financeiras é um tributo federal pago por pessoas
            físicas ou empresas que efetuarem operações de crédito. A porcentagem do
            tributo depende do tipo de operação.
          </p>
          <p class="qj-p">
            Para acordos à vista, o valor do I.O.F. será cobrado simultaneamente na
            parcela.<br />
            Para acordos parcelados, o valor do I.O.F. será diluído nas parcelas.
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["dialog"],
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__actions {
  box-shadow: 0em 1em 1em 0.5em black;
  margin-bottom: 1em;
  padding-bottom: 2em;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  button {
    width: 25em;
  }
}

.v-card.v-sheet {
  border-radius: 10px !important;
}
</style>
