<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="310">
      <v-card class="text-center">
        <v-card-title class="justify-center">
          <img src="@/assets/img/svg/success.svg" alt="image sucesso" />
        </v-card-title>
        <v-card-text>
          <p
            v-if="showCancellationAlert()"
            v-html="alertText"
            class="qj-card-text mt-2"
          ></p>
          <p v-else class="qj-card-text mt-2">
            Sua negociação foi cancelada com sucesso!
          </p>
          <v-btn
            block
            class="rounded-lg qj-btn qj-bg-primary qj-p-btn"
            :loading="loading"
            :disabled="loading"
            @click="okPressed()"
          >
            Ok
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["dialog"],
  data: () => ({
    loading: false,
    alertText: null,
  }),
  watch: {
    dialog() {
      if (this.dialog) {
        this.createTagManagerEvent({ event: "proposalCanceled" });
      }
    },
  },
  computed: {
    ...mapGetters("alert", ["getAlert"]),
    ...mapGetters("brokenDeal", {
      exclusiveCreditor: "getExclusiveCreditor",
    }),
    ...mapGetters("negotiation", {
      deals: "getDeals",
      deal: "getSelectedDeal",
    }),
  },
  methods: {
    ...mapActions("negotiation", ["ActionsDeals"]),
    showCancellationAlert() {
      let alert = this.getAlert(
        "show-cancellation-alert",
        this.deal?.creditor_id
      );

      if (alert && alert?.content) {
        this.alertText = alert.content;
        return true;
      }
      return false;
    },
    verifyExclusiveCreditor(deal) {
      if (!deal || !this.exclusiveCreditor) return true;
      return deal?.creditor_id == this.exclusiveCreditor;
    },
    backToHome() {
      this.$router
        .push({
          name: "Deals",
        }).then(() => { this.$router.go() })
        .catch((failure) => {
          this.skipIfNavigationFailureIsGeneric(failure)
        });
    },
    okPressed() {
      this.loading = true;
      this.ActionsDeals(true)
        .then(() => {
          setTimeout(() => {
            this.backToHome();
          }, 1000);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped></style>
