<template>
  <v-dialog v-model="dialog" persistent max-width="310">
    <v-card class="text-center">
      <v-card-title class="justify-center">
        <h3 class="qj-h3">Baixar Boleto</h3>
        <v-btn icon class="qj-btn-close-dialog" style="top: 9px; right: 2px" @click="closeDialog()">
          <v-icon>far fa-times-circle</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-3">
        <img src="@/assets/img/svg/download.svg" alt="Baixar" class="qj-download" />

        <p class="qj-card-text">
          <span v-if="alertContent" v-html="alertContent"></span>
        </p>

        <v-btn block @click="$emit('downloadBoleto', { paymentId, dealId })" class="rounded-lg qj-btn qj-bg-primary qj-p-btn" :href="boletoUrl" target="_blank"
          id="btn__download__boleto">
          <div style="margin-right: auto">Baixar Boleto</div>
          <v-icon style="font-size: 20px">fas fa-file-download</v-icon>
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    dialog: false,
    boletoUrl: "",
    creditorId: 0,
    paymentId: 0
  }),
  computed: {
    ...mapGetters("alert", ["getAlert"]),
    alertContent() {
      return this.getAlert("download-billet-alert", this.creditorId)
        ?.content || false;
    },
  },
  methods: {
    openDialog(payment) {
      this.boletoUrl = payment?.boleto?.url;
      this.creditorId = payment?.creditor_id;
      this.paymentId = payment?.id;
      this.dealId = payment?.deal_id;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.boletoUrl = "";
      this.creditorId = 0;
      this.paymentId = 0;
    },
  }
}
</script>

<style lang="scss" scoped>
.qj-card-text {
  margin: 12px 0px !important;
}
.qj-download {
  height: 100px;
  width: 100px;
}
</style>