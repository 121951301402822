<template>
  <div v-if="proposal">
    <v-row class="qj-row-main">
      <v-col cols="12">
        <h3 class="qj-h3">Escolha a forma de pagamento</h3>
      </v-col>
      <v-col cols="12" class="pb-1">
        <p class="qj-p mb-0">
          <b>no {{ proposal.payment_method | proposalPaymentMethod }}</b>
        </p>
        <div class="qj-card-outline">
          <v-row>
            <!-- Tags -->
            <v-col
              cols="12"
              :class="
                'd-flex pb-0 justify-' +
                (proposal?.discount_rate > 0.01 ? 'space-between' : 'center')
              "
            >
              <span
                :class="
                  (proposal.payment_type == 'INSTALLMENT'
                    ? 'parceled '
                    : 'cash ') + 'qj-tag'
                "
              >
                <b>{{ proposal.payment_type | proposalPaymentType }}</b>
              </span>
              <div v-if="proposal?.discount_rate > 0.01" class="qj-tag-discount">
                <img src="@/assets/img/icons/sale.svg" alt="sale icon" /><span
                  >{{ (proposal?.discount_rate * 100).toFixed(0) }}% de
                  desconto</span
                >
              </div>
            </v-col>
            <!-- Price parcelado -->
            <v-col
              cols="12"
              class="text-center qj-price"
              v-if="proposal.payment_type == 'INSTALLMENT'"
            >
              <h6>
                <span v-if="proposal.down_payment > 0">
                  <span class="qj-proposal-down-payment">
                    {{ proposal.down_payment | money }}
                  </span>
                  +
                </span>
                <span v-else>Em</span>
                {{ proposal.installments_count_1 }}x de
              </h6>
              <h2>{{ proposal.installment_1 | money }}</h2>
              <p class="mb-0 qj-proposal-payment-method">
                no {{ proposal.payment_method | proposalPaymentMethod }}
              </p>
            </v-col>
            <!-- Price in cash -->
            <v-col cols="12" class="text-center qj-price" v-else>
              <h6>
                De
                <strike>{{ proposal.operations_value | money }}</strike>
              </h6>
              <h2>
                Por
                {{ proposal.down_payment | money }}
              </h2>
              <p class="mb-0 qj-proposal-payment-method">
                no {{ proposal.payment_method | proposalPaymentMethod }}
              </p>
            </v-col>
            <!-- Btn -->
            <v-col cols="12" class="pt-0">
              <v-btn
                block
                class="rounded-lg qj-btn qj-bg-primary"
                style="padding: 0 11px"
                @click="next('boleto')"
              >
                <div style="margin-right: auto">Quero esta opção</div>
                <v-icon style="font-size: 20px"
                  >fas fa-long-arrow-alt-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col v-if="proposal.debit_suggestion" cols="12" class="pt-0">
        <p class="qj-p mb-0"><b>No débito automático</b></p>
        <div class="qj-card-outline">
          <v-row>
            <!-- Tags -->
            <v-col
              cols="12"
              :class="
                'd-flex pb-0 justify-' +
                (proposal.debit_suggestion.discount_rate > 0.01
                  ? 'space-between'
                  : 'center')
              "
            >
              <span
                :class="
                  (proposal.debit_suggestion.payment_type == 'INSTALLMENT'
                    ? 'parceled '
                    : 'cash ') + 'qj-tag'
                "
              >
                <b>{{
                  proposal.debit_suggestion.payment_type | proposalPaymentType
                }}</b>
              </span>
              <div
                v-if="proposal.debit_suggestion.discount_rate > 0.01"
                class="qj-tag-discount"
              >
                <img src="@/assets/img/icons/sale.svg" alt="sale icon" /><span
                  ><b
                    >{{
                      (proposal.debit_suggestion.discount_rate * 100).toFixed(
                        0
                      )
                    }}%</b
                  >
                  de desconto</span
                >
              </div>
            </v-col>
            <v-col cols="12" class="qj-price pb-2 qj-bank-account">
              <div v-if="account" class="content">
                <span class="qj-span">
                  <b>Agência:</b> {{ account | bankAccountBranch }}</span
                ><br />
                <span class="qj-span">
                  <b>Conta:</b> {{ account | bankAccountNumber }}</span
                >
              </div>
              <div
                class="text-right qj-w-100"
                v-if="proposal.debit_suggestion.payment_type == 'INSTALLMENT'"
              >
                <h6>
                  <span v-if="proposal.debit_suggestion.down_payment > 0">
                    <span class="qj-proposal-down-payment">
                      {{ proposal.debit_suggestion.down_payment | money }}
                    </span>
                    +
                  </span>
                  <span v-else>Em</span>
                  {{ proposal.debit_suggestion.installments_count_1 }}x de
                </h6>
                <h2 style="display: block">
                  {{ proposal.debit_suggestion.installment_1 | money }}
                </h2>
                <p
                  class="mb-0 qj-proposal-payment-method"
                  style="display: block"
                >
                  &nbsp;no débito
                </p>
              </div>
              <div class="text-right qj-w-100" v-else>
                <h6>
                  De
                  <strike>{{
                    proposal.debit_suggestion.operations_value | money
                  }}</strike>
                </h6>
                <h2 style="display: block">
                  Por
                  {{ proposal.debit_suggestion.down_payment | money }}
                </h2>
                <p
                  class="mb-0 qj-proposal-payment-method"
                  style="display: block"
                >
                  no débito
                </p>
              </div>
            </v-col>
            <v-col
              cols="12"
              class="pt-0"
              v-if="client.bank_accounts.length > 1"
            >
              <a
                href="javascript:void(0);"
                class="qj-link qj-color-secondary"
                @click="dialog = true"
              >
                Alterar conta
              </a>
            </v-col>
            <!-- Btn -->
            <v-col cols="12" class="pt-0">
              <v-btn
                block
                class="rounded-lg qj-btn qj-bg-primary"
                style="padding: 0 11px"
                @click="next('débito')"
              >
                <div style="margin-right: auto">Quero esta opção</div>
                <v-icon style="font-size: 20px"
                  >fas fa-long-arrow-alt-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <!-- Footer -->
    <qj-footer view="negociacao/forma-de-pagamento" />
    <DialogChangeAccount
      :dialog="dialog"
      :account="account"
      :accounts="client.bank_accounts"
      @close="dialog = false"
      @setAccount="setAccount($event)"
    />
  </div>
</template>

<script>
import store from "@/store";
import { mapActions, mapMutations } from "vuex";
import util from "@/assets/js/util";
import DialogChangeAccount from "../components/DialogChangeAccount";

export default {
  components: {
    DialogChangeAccount,
  },
  data: () => ({
    client: store.getters["negotiation/getMe"],
    dialog: false,
    account: {},
    proposal: {},
  }),
  created() {
    let proposalId = util.base64Decode(this.$route.query.id);
    this.proposal = util.getProposal(proposalId);

    if (!this.proposal || !this.proposal.debit_suggestion) {
      this.$router.push({ name: "Deals" }).catch((failure) => {
        this.skipIfNavigationFailureIsGeneric(failure)
      });
      return;
    }

    this.account = this.client.bank_accounts?.[0];
  },
  methods: {
    ...mapMutations("negotiation", ["setNegotiation"]),
    ...mapActions("proposal", {
      getProposalConditions: "getConditions",
    }),
    next(type) {
      this.$emit("step", 3);
      if (type == "boleto") {
        this.setNegotiation({
          account: {},
        });

        // Verifica se existem condições para a proposta
        this.getProposalConditions(this.proposal.id);

        this.$router
          .push({
            name: "NegotiationPaymentInfo",
            query: { id: window.btoa(this.proposal.id) },
          })
          .catch((error) => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
      } else {
        this.setNegotiation({
          account: this.account,
        });

        // Verifica se existem condições para a proposta
        this.getProposalConditions(this.proposal.debit_suggestion_id);

        this.$router
          .push({
            name: "NegotiationPaymentInfo",
            query: { id: window.btoa(this.proposal.debit_suggestion_id) },
          })
          .catch((failure) => {
            this.skipIfNavigationFailureIsGeneric(failure)
          });
      }
    },
    setAccount(newAccount) {
      this.account = newAccount;
      this.dialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
.qj-bank-account {
  display: flex;

  .content {
    background: #f6f6f6;
    border-radius: 7px;
    height: max-content;
    line-height: 14px;
    min-width: max-content;
    padding: 7px;
  }
}
</style>
