<template>
  <div>
    <span class="qj-p mb-4"
      >Para evitar fraudes e manter você seguro, precisamos que você olhe a
      fatura do seu cartão de crédito, e procure o PIN como no exemplo a baixo.
    </span>
    <v-row
      background="black"
      class="shrink bank-notification white--text mx-auto"
      width="385"
    >
      <v-col cols="2" background="black">
        <img src="@/assets/img/svg/creditor.svg" />
      </v-col>
      <v-col cols="10">
        <h5 class="mb-2">Seu Banco <span class="text-caption">09:57</span></h5>
        <h4>Compra no crédito aprovada</h4>
        <span class="qj-p white--text">
          <span>Compra de R$ 11,12 APROVADA em PJA*</span>
          <span class="highlight">277</span>QUITEJA
          <span>para o cartão com final ****.</span>
        </span>
      </v-col>
    </v-row>
    <v-row class="shrink mt-8 px-4">
      <div class="d-flex grow justify-center" id="sms__code__inputs">
        <input
          :message="pinMessage"
          autofocus
          min="0"
          max="999"
          maxlength="3"
          ref="passcodeNumber"
          v-mask="'###'"
          placeholder="000"
          class="passcodeNumber data-hj-allow"
          inputmode="numeric"
          autocomplete="one-time-code"
          v-model="pin"
          data-hj-allow
          outlined
          hide-details="auto"
        />
      </div>
      <span
        class="text-caption"
        :class="remainingPinAttempts < 3 ? 'red--text font-weight-bold' : ''"
        >{{ pinMessage }}</span
      >
    </v-row>
    <v-spacer></v-spacer>
    <qj-footer
      :disabled="!valid"
      :type="'next'"
      :loading="loading"
      view="negociacao/credit-card"
      @send="next"
    />
    <DialogImportantWarning
      @close="warningDialog = false"
      :dialog="warningDialog"
    />
    <PinFailDialog @close="pinFailDialog = false" :dialog="pinFailDialog" />
  </div>
</template>

<script>
import DialogImportantWarning from "../components/DialogWarning.vue";
import PinFailDialog from "../components/PinFailDialog.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {
    DialogImportantWarning,
    PinFailDialog,
  },
  data() {
    return {
      pin: null,
      valid: false,
      params: null,
      proposals: null,
      loading: false,
      warningDialog: false,
      pinFailDialog: false,
    };
  },
  async created() {
    this.$emit("step", 3);
    if (!this.$route.query.id) {
      console.info("redirecionado para home por falta de id na string");
      this.$router.push({ name: "Deals" });
      return;
    }
    this.params = this.$route.params;
    this.proposal = this.params.proposal;
    if (!this.proposal) {
      let proposalId = window.atob(this.$route.query.id);
      if (!proposalId) {
        this.$router.replace({ name: "Deals" }).catch((failure) => {
          this.skipIfNavigationFailureIsGeneric(failure);
        });
      }
      if (!this.deal) {
        this.setSelectedDeal(
          this.deals.find((deal) => {
            return deal.proposals.some((proposal) => proposal.id == proposalId);
          })
        );
      }
      if (!this.proposals)
        this.proposal = this.deal.proposals.find(
          (proposal) => proposal.id == proposalId
        );
    }
  },
  mounted() {},
  methods: {
    ...mapActions("parceleJa", {
      fetchParceleJaProposals: "getProposals",
      sendPin: "sendPin",
    }),
    ...mapMutations("negotiation", [
      "setNegotiation",
      "setSelectedDealByProposalId",
      "setSelectedDeal",
    ]),
    async next() {
      if (!this.pin) {
        this.dueErrorMessage = ["Insira uma data"];
        return;
      }
      // chamada para api para validar
      try {
        this.loading = true;
        const payload = {
          proposalId: this.proposal.id,
          pin: this.pin || undefined,
        };
        await this.sendPin(payload);
        await this.$router["push"]({
          name: "NegotiationConfirmDeal",
          query: {
            id: window.btoa(this.proposal.id),
          },
          params: {
            date: this.$moment(this.date).date(),
          },
        });
        this.$emit("step", 3);
      } catch (failure) {
        this.skipIfNavigationFailureIsGeneric(failure);
        this.pin = null;
        throw new Error(failure);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters("negotiation", {
      deals: "getDeals",
      deal: "getSelectedDeal",
    }),
    ...mapGetters("parceleJa", ["remainingPinAttempts"]),
    pinMessage() {
      return `* Fique atento!!! você tem apenas ${this.deal.prePayment.pin_remaining_attempts ?? 3} tentativas para validar seu pin.`;
    },
    remainingPinAttempts(){
      return this.deal.prePayment?.pin_remaining_attempts
    }
  },
  watch: {
    remainingPinAttempts(value) {
      if (value === 1) this.warningDialog = true;
      else if (value === 0) this.pinFailDialog = true;
    },
    pin(val) {
      val?.length === 3 ? (this.valid = true) : false;
    },
  },
};
</script>

<style lang="scss">
.bank-notification {
  background-color: rgb(86, 84, 84);
  border-radius: 5px;
  max-width: 385px;
  .highlight {
    border: 2px solid red;
  }
}

.verification {
  width: 50px;
  height: 55px;
  background: #f0f0f0;
  border-radius: 7px;
  margin: 0 10px;
  text-align: center;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 30px;
  appearance: textfield;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.verification::-webkit-outer-spin-button,
.verification::-webkit-inner-spin-button {
  margin: 0;
  appearance: textfield;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.verification:focus {
  background: #cafce9;
  box-shadow: 0 0 0 0 !important;
  border: 0 none !important;
  outline: 0 !important;
}

.verification:focus::placeholder {
  color: transparent;
}

.passcodeNumber {
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  max-width: 100%;
  min-width: 0px;
  width: 100%;
  padding: 8px 0 8px 25px;
  border-radius: 7px;
  text-align: center;
  letter-spacing: 26px;
  margin: 0 auto;
  outline: none;
  border-color: #212121;
  border-style: solid;
  border-width: thin;

  background: url("../../../assets/img/icons/shield-check-outline.svg")
    no-repeat left;
  background-size: 20px;
  background-position-x: 12px;

  &:focus,
  &:active,
  &:focus-visible,
  &:target,
  &::selection {
    border-color: #00e58d;
  }
}
</style>