<template>
  <div v-if="deal">
    <!-- Body -->
    <v-row class="qj-row-main">
      <!-- Title -->
      <v-col cols="12" class="pt-0 pb-0">
        <p class="qj-p mb-0">
          A sua <b class="fw-700">proposta foi encaminhada</b> e está aguardando
          o retorno {{ creditorWithGender() }}
        </p>
        <p class="qj-p mb-0">Em breve você será avisado por email.</p>
      </v-col>
      <v-col cols="12" class="pb-0 pt-0">
        <div class="qj-card-outline">
          <ProposalCard
            :deal="deal"
            :proposal="proposal"
            :proposals="deal.proposals"
            :doShowNextButton="false"
            :showPaymentMethod="true"
            :doShowDetailsButton="true"
            :proposalStatus="proposalStatus()"
            :openNegotiationDetails="openNegotiationDetails"
          />
        </div>
      </v-col>

      <!-- C.E.T. e I.O.F. -->
      <div v-if="isTaxesVisibles" class="qj-taxes">
        <div v-if="deal.active_proposal.cet_month">
          <div class="heading">Custo Efetivo Total</div>

          <div class="value cet-month">
            {{ (deal.active_proposal.cet_month * 100).toFixed(2) }}% ao mês
          </div>
          <div class="value cet-year">
            {{ (deal.active_proposal.cet_year * 100).toFixed(2) }}% ao ano
          </div>
        </div>

        <div v-if="deal.active_proposal.iof">
          <div class="heading">I.O.F.</div>
          <div class="value">{{ deal.active_proposal.iof | money }}</div>
        </div>
      </div>

      <v-col cols="12" class="py-0 qj-status-alert-info">
        <qj-alert-info
          name="proposal-waiting-after-details"
          :creditorId="deal.creditor_id"
          :deal="deal"
        />
      </v-col>
    </v-row>
    <!-- Footer -->
    <qj-footer
      :type="hasOtherDebts.length > 1 ? 'other' : 'help'"
      @send="home()"
      :hasCustomerServiceBtn="true"
    />
    <!-- Dialog meus dados -->
    <DialogMyData
      :dialog="dialogMyData"
      :deal="deal"
      @close="dialogMyData = false"
    />
    <!-- Dialog help -->
    <DialogNegotiationDetails
      :deal="deal"
      :dialog="dialogNegotiationDetails"
      @close="dialogNegotiationDetails = false"
    />
    <DialogNPS :show="newDeal" @close="newDeal = false" />
  </div>
</template>

<script>
import store from "@/store";
import Deal from "@/modules/deal";
import util from "@/assets/js/util";
import serviceDeal from "@/services/deal";
import DialogNPS from "@/components/nps/DialogNPS";
import DialogMyData from "../components/DialogMyData";
import ProposalCard from "@/views/negotiation/components/proposalCard.vue";
import DialogNegotiationDetails from "../components/DialogNegotiationDetails";
import { createTagManagerEvent } from "@/utils";
import { mapActions, mapMutations } from "vuex";

export default {
  components: {
    DialogNPS,
    ProposalCard,
    DialogMyData,
    DialogNegotiationDetails,
  },
  data: () => ({
    isDestroyed: null,
    configureRequestsInterval: null,
    deal: null,
    creditorProfile: null,
    dialog: false,
    dialogMyData: false,
    dialogNegotiationDetails: false,
    newDeal: false,
    hasOtherDebts: [],
    proposalApproved: false,
    proposal: {},
  }),
  computed: {
    isPaymentMethodVisible() {
      return Deal.isPaymentMethodVisible(this.deal);
    },
    /** Indica se serão exibidas as informações de C.E.T e I.O.F. */
    isTaxesVisibles() {
      if (!this.deal || !this.deal.active_proposal) {
        return false;
      }

      return (
        this.deal.active_proposal.iof || this.deal.active_proposal.cet_month
      );
    },
  },
  beforeCreate() {
    this.$emit("loading", true);
    this.$emit("step", 4);
  },
  async created() {
    if (!this.$route.query.id) {
      this.$emit("loading", false);
      this.$router.push({ name: "Home" }).catch((failure) => {
        this.skipIfNavigationFailureIsGeneric(failure)
      });
      return;
    }

    let proposalId = util.base64Decode(this.$route.query.id);
    this.proposal = util.getProposal(proposalId);

    this.deals = store.getters["negotiation/getDeals"];
    this.deal = this.deals.find(
      (deal) =>
        deal.active_proposal_id == proposalId ||
        deal.active_proposal?.parcele_ja_proposal?.id == proposalId
    );
    if (!this.deal.prePayment){
      this.deal.prePayment = this.$route.params.prePayment
    }
    if (!this.deal) {
      this.$emit("loading", false);
      this.$router.push({ name: "Home" }).catch((failure) => {
        this.skipIfNavigationFailureIsGeneric(failure)
      });
      return;
    }

    if (
      util.isEmpty(
        this.deal.active_proposal
      )
    ) {
      await serviceDeal.deal(this.deal.id, false).then((res) => {
        this.deal = res.data;
      });
    }

    if (!util.isEmpty(this.deal.creditor_profile)) {
      this.creditorProfile = this.deal.creditor_profile;
    } else if (!util.isEmpty(this.deal.creditor.profile)) {
      this.creditorProfile = this.deal.creditor.profile;
    }

    if (this.$route.query.new != null) {
      this.getNpsText().then(() => {
        this.newDeal = this.$route.query.new == true ? true : false;
        let query = Object.assign({}, this.$route.query);
        delete query.new;
        this.$router.replace({ query }).catch((failure) => {
            this.skipIfNavigationFailureIsGeneric(failure)
          });
      });
    }

    let otherDebts = store.getters["negotiation/getDeals"].filter(
      (deal) => deal.status == "CREATED" || deal.status == "STARTED"
    );
    if (otherDebts.length == 1) {
      this.alertId = otherDebts?.[0]?.id;
      this.alertCreditor = otherDebts?.[0]?.creditor.profile.long_name;
      setTimeout(() => (this.alert = true), 2000);
    } else if (otherDebts.length > 1) {
      this.alertIndex = 1;
      setTimeout(() => (this.alert = true), 2000);
    }

    let othetDebits = store.getters["negotiation/getDeals"];
    if (othetDebits?.length > 0) {
      this.hasOtherDebts = othetDebits.filter(
        (i) => i.status != "ABORTED"
      );
    }

    // Verifica se deve ser exibida a modal "Meus Dados"
    this.dialogMyData = this.$route.query.meus_dados == "true";

    this.checkStatus(this.deal);
    setTimeout(() => this.$emit("loading", false), 300);
    this.configureRequests();
  },
  destroyed() {
    this.isDestroyed = true;
  },
  methods: {
    ...mapMutations("negotiation", {
      setSelectedDeal: "setSelectedDeal",
    }),
    ...mapActions("nps", ["getNpsText"]),
    ...mapActions("negotiation", ["ActionsDeals", "getAgreement"]),
    proposalStatus() {
      let deal = this.deal;
      if (deal.active_proposal && deal.active_proposal.status == "WAITING") {
        return "Em análise";
      } else if (["STARTED", "WAITING"].indexOf(deal.status) != -1) {
        if (deal.waiting_cause != "EXISTING_PROMISE") return "Em análise";
      }
      return false;
    },
    doShowBankSlip() {
      let isBankSlip = this.deal.active_proposal?.payment_method == "BOLETO";
      let dealId = this.deal.id;

      if (isBankSlip && util.doShowBankSlip(dealId)) return true;
      return false;
    },
    home() {
      this.$router
        .push({
          name: "Deals",
        })
        .catch((failure) => {
         this.skipIfNavigationFailureIsGeneric(failure)
        });
    },
    openNegotiationDetails() {
      this.dialogNegotiationDetails = true;
      createTagManagerEvent({
        event: "dealClickedDetails",
      });
    },
    openProfile() {
      this.dialogMyData = true;
      createTagManagerEvent({
        event: "dealClickedMyInfo",
      });
    },
    checkStatus(deal) {
      if (!deal) return;
      this.setSelectedDeal(deal);

      let { status, active_proposal_id } = deal;
      if (!status || !active_proposal_id) return;
      if (status === "AGREED") {
        this.proposalApproved = true;
        this.$router
          .push({
            name: "HomeActiveAgreement",
            query: {
              id: window.btoa(active_proposal_id),
            },
          })
          .catch((failure) => {
            this.skipIfNavigationFailureIsGeneric(failure)
          });
      }
    },
    getUpdatedDeal() {
      return new Promise((resolve) =>
        this.getAgreement(this.deal.id)
          .then((agreement) => {
            let updatedDeal = agreement
            this.checkStatus(updatedDeal);
          })
          .finally(() => resolve())
      );
    },
    async configureRequests() {
      await this.$sleep(10000);
      let requestTimer = 1000;
      while (!this.proposalApproved && !this.isDestroyed) {
        await this.getUpdatedDeal();
        await this.$sleep(requestTimer);
        requestTimer += 1000;
      }
    },
    creditorWithGender(){
      const gender = this.creditorProfile?.gender == "MALE" ? "do" : "da";
      const creditorName =  this.creditorProfile?.long_name
      return `${gender} ${creditorName}`
    }
  },
};
</script>

<style scoped lang="scss">
.fw-700 {
  font-weight: 700 !important;
}
.qj-h2 {
  font-size: 18px;
}
.qj-card-outline {
  margin: 12px 0px 8px;
}
.incoming {
  font-size: 12px;
}

// C.E.T. e I.O.F.
.qj-taxes {
  display: flex;
  flex-direction: row;
  margin: 32px 8px 0;
  width: 100%;

  > div {
    flex: 1;
    text-align: center;
  }

  .heading {
    font-weight: 400;
    font-size: 14px;
  }

  .value {
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;

    &.cet-year {
      font-size: 13px;
    }
  }
}
</style>
